define("m08-2020/lib/ThreeExtensions/RaycastModdedMesh", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RaycastModdedMesh = void 0;

  /**
   * Original authors:
   * mrdoob / http://mrdoob.com/
   * alteredq / http://alteredqualia.com/
   * mikael emtinger / http://gomo.se/
   * jonobr1 / http://jonobr1.com/
   */
  const THRESEHOLD = 1e-4;

  const _inverseMatrix = new THREE.Matrix4();

  const _ray = new THREE.Ray();

  const _sphere = new THREE.Sphere();

  const _vA = new THREE.Vector3();

  const _vB = new THREE.Vector3();

  const _vC = new THREE.Vector3();

  const _tempA = new THREE.Vector3();

  const _tempB = new THREE.Vector3();

  const _tempC = new THREE.Vector3();

  const _morphA = new THREE.Vector3();

  const _morphB = new THREE.Vector3();

  const _morphC = new THREE.Vector3();

  const _uvA = new THREE.Vector2();

  const _uvB = new THREE.Vector2();

  const _uvC = new THREE.Vector2();

  const _intersectionPoint = new THREE.Vector3();

  const _intersectionPointWorld = new THREE.Vector3();

  class RaycastModdedMesh extends THREE.Mesh {
    constructor(geometry, material) {
      super(geometry, material);
    }

    raycast(raycaster, intersects) {
      const geometry = this.geometry;
      const material = this.material;
      const matrixWorld = this.matrixWorld;
      if (material === undefined) return; // Checking boundingSphere distance to ray

      if (geometry.boundingSphere === null) geometry.computeBoundingSphere();

      _sphere.copy(geometry.boundingSphere);

      _sphere.applyMatrix4(matrixWorld);

      if (raycaster.ray.intersectsSphere(_sphere) === false) return; //

      _inverseMatrix.getInverse(matrixWorld);

      _ray.copy(raycaster.ray).applyMatrix4(_inverseMatrix); // Check boundingBox before continuing


      if (geometry.boundingBox !== null) {
        if (_ray.intersectsBox(geometry.boundingBox) === false) return;
      }

      let intersection;

      if (geometry.isBufferGeometry) {
        const index = geometry.index;
        const position = geometry.attributes.position;
        const morphPosition = geometry.morphAttributes.position;
        const morphTargetsRelative = geometry.morphTargetsRelative;
        const uv = geometry.attributes.uv;
        const uv2 = geometry.attributes.uv2;
        const groups = geometry.groups;
        const drawRange = geometry.drawRange;

        if (index !== null) {
          // indexed buffer geometry
          if (Array.isArray(material)) {
            for (let i = 0, il = groups.length; i < il; i++) {
              const group = groups[i];
              const groupMaterial = material[group.materialIndex];
              const start = Math.max(group.start, drawRange.start);
              const end = Math.min(group.start + group.count, drawRange.start + drawRange.count);

              for (let j = start, jl = end; j < jl; j += 3) {
                const a = index.getX(j);
                const b = index.getX(j + 1);
                const c = index.getX(j + 2);
                intersection = checkBufferGeometryIntersection(this, groupMaterial, raycaster, _ray, position, morphPosition, morphTargetsRelative, uv, uv2, a, b, c);

                if (intersection) {
                  intersection.faceIndex = Math.floor(j / 3); // triangle number in indexed buffer semantics

                  intersection.face.materialIndex = group.materialIndex;
                  intersects.push(intersection);
                }
              }
            }
          } else {
            const start = Math.max(0, drawRange.start);
            const end = Math.min(index.count, drawRange.start + drawRange.count);

            for (let i = start, il = end; i < il; i += 3) {
              const a = index.getX(i);
              const b = index.getX(i + 1);
              const c = index.getX(i + 2);
              intersection = checkBufferGeometryIntersection(this, material, raycaster, _ray, position, morphPosition, morphTargetsRelative, uv, uv2, a, b, c);

              if (intersection) {
                intersection.faceIndex = Math.floor(i / 3); // triangle number in indexed buffer semantics

                intersects.push(intersection);
              }
            }
          }
        } else if (position !== undefined) {
          // non-indexed buffer geometry
          if (Array.isArray(material)) {
            for (let i = 0, il = groups.length; i < il; i++) {
              const group = groups[i];
              const groupMaterial = material[group.materialIndex];
              const start = Math.max(group.start, drawRange.start);
              const end = Math.min(group.start + group.count, drawRange.start + drawRange.count);

              for (let j = start, jl = end; j < jl; j += 3) {
                const a = j;
                const b = j + 1;
                const c = j + 2;
                intersection = checkBufferGeometryIntersection(this, groupMaterial, raycaster, _ray, position, morphPosition, morphTargetsRelative, uv, uv2, a, b, c);

                if (intersection) {
                  intersection.faceIndex = Math.floor(j / 3); // triangle number in non-indexed buffer semantics

                  intersection.face.materialIndex = group.materialIndex;
                  intersects.push(intersection);
                }
              }
            }
          } else {
            const start = Math.max(0, drawRange.start);
            const end = Math.min(position.count, drawRange.start + drawRange.count);

            for (let i = start, il = end; i < il; i += 3) {
              const a = i;
              const b = i + 1;
              const c = i + 2;
              intersection = checkBufferGeometryIntersection(this, material, raycaster, _ray, position, morphPosition, morphTargetsRelative, uv, uv2, a, b, c);

              if (intersection) {
                intersection.faceIndex = Math.floor(i / 3); // triangle number in non-indexed buffer semantics

                intersects.push(intersection);
              }
            }
          }
        }
      } else if (geometry.isGeometry) {
        const isMultiMaterial = Array.isArray(material);
        const vertices = geometry.vertices;
        const faces = geometry.faces;
        let uvs;
        const faceVertexUvs = geometry.faceVertexUvs[0];
        if (faceVertexUvs.length > 0) uvs = faceVertexUvs;

        for (let f = 0, fl = faces.length; f < fl; f++) {
          const face = faces[f];
          const faceMaterial = isMultiMaterial ? material[face.materialIndex] : material;
          if (faceMaterial === undefined) continue;
          const fvA = vertices[face.a].clone();
          const fvB = vertices[face.b].clone();
          const fvC = vertices[face.c].clone();
          let centerVec = new THREE.Vector3((fvA.x + fvB.x + fvC.x) / 3, (fvA.y + fvB.y + fvC.y) / 3, (fvA.z + fvB.z + fvC.z) / 3);
          let dirA = new THREE.Vector3().subVectors(fvA, centerVec).normalize().multiplyScalar(THRESEHOLD);
          let dirB = new THREE.Vector3().subVectors(fvB, centerVec).normalize().multiplyScalar(THRESEHOLD);
          let dirC = new THREE.Vector3().subVectors(fvC, centerVec).normalize().multiplyScalar(THRESEHOLD);
          fvA.add(dirA);
          fvB.add(dirB);
          fvC.add(dirC);
          intersection = checkIntersection(this, faceMaterial, raycaster, _ray, fvA, fvB, fvC, _intersectionPoint);

          if (intersection) {
            if (uvs && uvs[f]) {
              const uvs_f = uvs[f];

              _uvA.copy(uvs_f[0]);

              _uvB.copy(uvs_f[1]);

              _uvC.copy(uvs_f[2]);

              intersection.uv = THREE.Triangle.getUV(_intersectionPoint, fvA, fvB, fvC, _uvA, _uvB, _uvC, new THREE.Vector2());
            }

            intersection.face = face;
            intersection.faceIndex = f;
            intersects.push(intersection);
          }
        }
      }
    }

  }

  _exports.RaycastModdedMesh = RaycastModdedMesh;

  function checkIntersection(object, material, raycaster, ray, pA, pB, pC, point) {
    let intersect;

    if (material.side === THREE.BackSide) {
      intersect = ray.intersectTriangle(pC, pB, pA, true, point);
    } else {
      intersect = ray.intersectTriangle(pA, pB, pC, material.side !== THREE.DoubleSide, point);
    }

    if (intersect === null) return null;

    _intersectionPointWorld.copy(point);

    _intersectionPointWorld.applyMatrix4(object.matrixWorld);

    const distance = raycaster.ray.origin.distanceTo(_intersectionPointWorld);
    if (distance < raycaster.near || distance > raycaster.far) return null;
    return {
      distance: distance,
      point: _intersectionPointWorld.clone(),
      object: object
    };
  }

  function checkBufferGeometryIntersection(object, material, raycaster, ray, position, morphPosition, morphTargetsRelative, uv, uv2, a, b, c) {
    _vA.fromBufferAttribute(position, a);

    _vB.fromBufferAttribute(position, b);

    _vC.fromBufferAttribute(position, c);

    const morphInfluences = object.morphTargetInfluences;

    if (material.morphTargets && morphPosition && morphInfluences) {
      _morphA.set(0, 0, 0);

      _morphB.set(0, 0, 0);

      _morphC.set(0, 0, 0);

      for (let i = 0, il = morphPosition.length; i < il; i++) {
        const influence = morphInfluences[i];
        const morphAttribute = morphPosition[i];
        if (influence === 0) continue;

        _tempA.fromBufferAttribute(morphAttribute, a);

        _tempB.fromBufferAttribute(morphAttribute, b);

        _tempC.fromBufferAttribute(morphAttribute, c);

        if (morphTargetsRelative) {
          _morphA.addScaledVector(_tempA, influence);

          _morphB.addScaledVector(_tempB, influence);

          _morphC.addScaledVector(_tempC, influence);
        } else {
          _morphA.addScaledVector(_tempA.sub(_vA), influence);

          _morphB.addScaledVector(_tempB.sub(_vB), influence);

          _morphC.addScaledVector(_tempC.sub(_vC), influence);
        }
      }

      _vA.add(_morphA);

      _vB.add(_morphB);

      _vC.add(_morphC);
    }

    if (object.isSkinnedMesh) {
      object.boneTransform(a, _vA);
      object.boneTransform(b, _vB);
      object.boneTransform(c, _vC);
    }

    const intersection = checkIntersection(object, material, raycaster, ray, _vA, _vB, _vC, _intersectionPoint);

    if (intersection) {
      if (uv) {
        _uvA.fromBufferAttribute(uv, a);

        _uvB.fromBufferAttribute(uv, b);

        _uvC.fromBufferAttribute(uv, c);

        intersection.uv = THREE.Triangle.getUV(_intersectionPoint, _vA, _vB, _vC, _uvA, _uvB, _uvC, new THREE.Vector2());
      }

      if (uv2) {
        _uvA.fromBufferAttribute(uv2, a);

        _uvB.fromBufferAttribute(uv2, b);

        _uvC.fromBufferAttribute(uv2, c);

        intersection.uv2 = THREE.Triangle.getUV(_intersectionPoint, _vA, _vB, _vC, _uvA, _uvB, _uvC, new THREE.Vector2());
      }

      const face = new Face3(a, b, c);
      THREE.Triangle.getNormal(_vA, _vB, _vC, face.normal);
      intersection.face = face;
    }

    return intersection;
  }
});