define("m08-2020/lib/StateControl/DynamicTextsController", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DynamicTextsController = void 0;

  const _textWorldPos = new THREE.Vector3();

  const _cameraLookDir = new THREE.Vector3();

  const _lookDirVec = new THREE.Vector3();

  class DynamicTextsController {
    constructor() {
      this.dynamicTexts = [];
    }

    resetState() {
      this.dynamicTexts = [];
    }

    updateDynamicTexts(camera) {
      camera.getWorldDirection(_cameraLookDir);

      _cameraLookDir.negate();

      this.dynamicTexts.forEach(text => {
        text.getWorldPosition(_textWorldPos);

        _lookDirVec.addVectors(_textWorldPos, _cameraLookDir);

        text.lookAt(_lookDirVec);
      });
    }

    addToDynamicTexts(text) {
      this.dynamicTexts.push(text);
    }

  }

  _exports.DynamicTextsController = DynamicTextsController;
});