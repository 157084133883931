define("m08-2020/lib/FileGeneration/DXFGenerator", ["exports", "three", "m08-2020/lib/Utils", "m08-2020/lib/ClippingUtils/OutlineGenerator", "m08-2020/lib/FileGeneration/dxf_colors"], function (_exports, THREE, _Utils, _OutlineGenerator, _dxf_colors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DXFGenerator = void 0;
  const POINT_SIZE = 0.1;
  const utils = new _Utils.Utils(THREE);

  class DXFGenerator {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D; // global dxf data -->

      this.blockSection = "";
      this.entitiesSection = "";
      this.textStyles = "";
      this.offsetFromTop = 0; // object data -->

      this.referenceZeroPoint = new THREE.Vector3();
      this.scaleFactor = 1;
      this.blocks = {
        fasteners: [],
        drawingObjects: []
      };
      this.projectedPoints = [];
      this.wireframePoints = [];
      this.fastenerPoints = {};
      this.pointObjectsData = [];
      this.lineObjectsData = []; // TODO: Might need better solution.

      this.outerFrameOffset = 0;
      this.drawingScaleBarBBox = null;
      this.drawingSizeHalf = {
        width: 0,
        height: 0
      };
    }

    downloadDXF3D() {
      this.generateBlockAndEntitySections3D();
      let contentDXF = this.generateContentDXF();
      downloadTextFile("scene.dxf", contentDXF.trim());
    }

    generateBlockAndEntitySections3D() {
      this.positionCamera();
      this.drawingSizeHalf = {
        width: this.GraphicsThree3D.camera.right,
        height: this.GraphicsThree3D.camera.top
      };
      this.setScaleAndReferencePoint();
      let visProps = {
        cutParams: {
          type: 1
        },
        dxfFastenerVisualisation: {
          addOn: true,
          axis: true,
          fastener: false
        },
        visualisation: {
          arrow: true,
          dimensionalChain: true,
          drawingObject: true,
          fastener: true
        }
      };
      this.addToBlockSection(visProps);
      this.addToEntitiesSection(visProps);
    }

    positionCamera() {
      let controls = this.GraphicsThree3D.controls;
      let camera = this.GraphicsThree3D.camera;
      let midVector = this.GraphicsThree3D.objectMidVector;
      let initialPos = new THREE.Vector3(-1, 1, 1).normalize().multiplyScalar(150).add(midVector);
      let cameraTargetPos = {
        x: midVector.x + initialPos.x,
        y: midVector.y + initialPos.y,
        z: midVector.z + initialPos.z
      };
      camera.position.set(cameraTargetPos.x, cameraTargetPos.y, cameraTargetPos.z);
      camera.zoom = 1;
      camera.lookAt(midVector);
      camera.updateProjectionMatrix();
      controls.update();
    }

    downloadDXF2D(dxfName, drawingPlaneData) {
      this.generateBlockAndEntitySections(drawingPlaneData);
      let contentDXF = this.generateContentDXF();
      downloadTextFile(dxfName, contentDXF.trim());
      this.clearDXF();
    }

    generateContentDXF() {
      let contentDXF = ""; // Set units to centimeters

      contentDXF += "0\nSECTION\n2\nHEADER\n9\n$INSUNITS\n70\n5\n0\nENDSEC\n";
      contentDXF += `0\nSECTION\n2\nTABLES\n0\nTABLE\n2\nSTYLE\n${this.textStyles}0\nENDTAB\n0\nENDSEC\n`;
      contentDXF += `0\nSECTION\n2\nBLOCKS\n${this.blockSection}0\nENDSEC\n`;
      contentDXF += `0\nSECTION\n2\nENTITIES\n${this.entitiesSection}0\nENDSEC\n0\nEOF`;
      return contentDXF;
    }

    generateBlockAndEntitySections(drawingPlaneData) {
      this.GraphicsThree3D.camera.updateProjectionMatrix();
      this.outerFrameOffset = drawingPlaneData.drawingComponents.frameDataAndProps.frameProps.outerLine.thickness / 2;
      this.setDrawingSizeHalf(drawingPlaneData.drawingComponents.frameDataAndProps.frameData, drawingPlaneData.drawingComponents.frameDataAndProps.frameProps);
      this.setScaleAndReferencePoint();
      this.addToBlockSection(drawingPlaneData);
      this.addToEntitiesSection(drawingPlaneData);
      this.createDrawingTextStyles(drawingPlaneData);
      this.addDrawingFrame(drawingPlaneData.drawingComponents.frameDataAndProps);
      this.addDrawingFreeTexts(drawingPlaneData.drawingComponents.freeTextDataAndProps);
      this.addDrawingScaleTextBlock(drawingPlaneData.drawingComponents.scaleTextBlockDataAndProps, drawingPlaneData.scale);
      this.addDrawingUnitTextBlock(drawingPlaneData.drawingComponents.unitTextBlockDataAndProps, drawingPlaneData.units);
      this.addDrawingScaleBar(drawingPlaneData.drawingComponents.scaleBarDataAndProps, drawingPlaneData.scale, drawingPlaneData.units);
      this.offsetFromTop += drawingPlaneData.drawingComponents.frameDataAndProps.frameData.height;
      this.clearObjectData();
    }

    addToBlockSection(drawingPlaneData) {
      let visualisation = drawingPlaneData.visualisation;
      if (visualisation.fastener) this.blockSection += this.generateFastenerBlocks(drawingPlaneData, this.fastenerPoints);
    }

    addToEntitiesSection(drawingPlaneData) {
      let visualisation = drawingPlaneData.visualisation;
      if (visualisation.drawingObject) this.addDrawingObjectsToEntities(drawingPlaneData);
      if (visualisation.fastener) this.addFastenerBlocksToEntities();
    }

    addDrawingObjectsToEntities(drawingPlaneData) {
      let {
        wireframePtsCorrected,
        pointsCorrected
      } = this.getCorrectedObjectPoints(drawingPlaneData.cutParams);
      this.drawWireframeLines(wireframePtsCorrected).forEach(line => this.entitiesSection += `${line.trim()}\n`);
      this.drawPoints(pointsCorrected, drawingPlaneData.scale).forEach(point => this.entitiesSection += `${point.trim()}\n`);
      if (drawingPlaneData.cutParams.type === 1) this.drawLines(this.lineObjectsData).forEach(line => this.entitiesSection += `${line.trim()}\n`);
    }

    addFastenerBlocksToEntities() {
      this.blocks.fasteners.forEach(({
        blockName,
        layerName
      }) => this.entitiesSection += `0\nINSERT\n2\n${blockName}\n8\n${layerName}\n10\n${this.referenceZeroPoint.x}\n20\n${this.referenceZeroPoint.y}\n30\n${this.referenceZeroPoint.z}\n`);
    }

    getCorrectedObjectPoints(cutParams) {
      let wireframePtsCorrected, pointsCorrected;

      if (cutParams.type === 1) {
        wireframePtsCorrected = this.wireframePoints;
        pointsCorrected = this.pointObjectsData;
      } else {
        wireframePtsCorrected = cutObjects(cutParams, this.wireframePoints);
        pointsCorrected = getFilteredPoints(cutParams, this.pointObjectsData);
        pointsCorrected.push(...cutLines(cutParams, this.lineObjectsData));
      }

      return {
        wireframePtsCorrected,
        pointsCorrected
      };
    }

    createDrawingTextStyles(drawingPlaneData) {
      this.createDrawingTextStyle(drawingPlaneData.drawingComponents.freeTextDataAndProps.freeTextProps);
      this.createDrawingTextStyle(drawingPlaneData.drawingComponents.scaleTextBlockDataAndProps.scaleTextBlockProps);
      this.createDrawingTextStyle(drawingPlaneData.drawingComponents.unitTextBlockDataAndProps.unitTextBlockProps);
      this.createDrawingTextStyle(drawingPlaneData.drawingComponents.scaleBarDataAndProps.scaleBarProps.text);
    }

    createDrawingTextStyle(textProps) {
      let fontName = this.generateFontName(textProps); //"ARIAL.TTF";

      this.textStyles += `0\nSTYLE\n2\n${fontName}\n70\n0\n40\n0\n41\n0\n50\n0\n71\n0\n42\n0\n3\n${fontName + ".TTF"}\n`;
    }

    generateFontName(textProps) {
      let name = "";

      if (textProps) {
        let {
          style,
          type
        } = textProps;

        switch (type) {
          case 1:
            name += "ARIAL_";
            break;

          case 2:
            name += "CALIBRI_";
            break;

          case 3:
            name += "COURIER_";
            break;

          case 4:
            name += "TIMESNEWROMAN_";
            break;

          default:
            break;
        }

        switch (style) {
          case 1:
            name += "REGULAR";
            break;

          case 2:
            name += "ITALIC";
            break;

          case 3:
            name += "BOLD";
            break;

          case 4:
            name += "BOLD_ITALIC";
            break;

          default:
            break;
        }
      } else name += "ARIAL";

      return name;
    }

    addDrawingScaleBar(scaleBarDataAndProps, scale, units) {
      let {
        scaleBarData,
        scaleBarProps
      } = scaleBarDataAndProps;

      if (scaleBarData && scaleBarProps) {
        let areaProps = scaleBarProps.area;
        let lineProps = scaleBarProps.line;
        let textProps = scaleBarProps.text;
        let areaColor = this.getColorIndexFromColor(this.getColorFromProps(areaProps));
        let lineColor = this.getColorIndexFromColor(this.getColorFromProps(lineProps));
        const NUM_OF_SEGMENTS = 5;
        const SEGMENT_SIZE = 1;
        const TEXT_ALIGNMENT = 1;
        const Z_AXIS = new THREE.Vector3(0, 0, 1);
        let insertionPoint = utils.initVector3(scaleBarData.insertionPoint);
        let bboxSize = this.drawingScaleBarBBox.getSize(new THREE.Vector3());
        let centerOffset = this.drawingScaleBarBBox.getCenter(new THREE.Vector3());
        let alignmentOffset = this.GraphicsThree3D.DrawingPlaneLoader.getScaleBarAlignmentOffset(bboxSize, scaleBarData.relativePosition);
        let rotation = this.getRotationFromDirection(scaleBarData.directionVector) * Math.PI / 180;
        let barHeight = scaleBarData.height;
        let barWidth = SEGMENT_SIZE * NUM_OF_SEGMENTS; /// Line -->

        let linePoints = [new THREE.Vector3(0, 0, 0), new THREE.Vector3(barWidth, 0, 0), new THREE.Vector3(barWidth, barHeight, 0), new THREE.Vector3(0, barHeight, 0), new THREE.Vector3(0, 0, 0)].map(pt => pt.sub(centerOffset).add(alignmentOffset).applyAxisAngle(Z_AXIS, rotation).add(insertionPoint).sub(new THREE.Vector3(0, this.offsetFromTop, 0)));

        for (let i = 0; i < linePoints.length - 1; i++) this.entitiesSection += this.drawLine(linePoints[i], linePoints[i + 1], lineColor, 0); /// <--
        /// Fills -->


        let solidPoints = [];

        for (let i = 0; i < NUM_OF_SEGMENTS; i++) {
          if (i % 2) solidPoints.push([new THREE.Vector3(SEGMENT_SIZE * i, 0, 0), new THREE.Vector3(SEGMENT_SIZE * (i + 1), 0, 0), new THREE.Vector3(SEGMENT_SIZE * i, barHeight, 0), new THREE.Vector3(SEGMENT_SIZE * (i + 1), barHeight, 0)].map(pt => pt.sub(centerOffset).add(alignmentOffset).applyAxisAngle(Z_AXIS, rotation).add(insertionPoint).sub(new THREE.Vector3(0, this.offsetFromTop, 0))));
        }

        solidPoints.forEach(ptArr => this.entitiesSection += this.drawSolid(ptArr[0], ptArr[1], ptArr[2], ptArr[3], areaColor, 0)); /// <--
        /// Texts -->

        let decimalPlaces = scaleBarData.decimalPlaces;
        let distance = scaleBarData.distance;
        let height = scaleBarData.height;
        let textOffset = scaleBarData.textOffset;
        let scaleFactor = this.GraphicsThree3D.DrawingPlaneLoader.getScaleFactor(units.length);
        let heightOffset = -distance - textProps.size / 2;
        let content = "";
        let position = new THREE.Vector3(0, heightOffset, 0);

        for (let i = 0; i <= NUM_OF_SEGMENTS; i++) {
          content = (i * scale * scaleFactor).toFixed(decimalPlaces);
          if (i === 5 && units.length) content += ` ${units.length}`;

          if (textOffset) {
            if (i % 2 === 1) position.y = height - heightOffset;else position.y = heightOffset;
          }

          position.x = i * SEGMENT_SIZE;
          let textPosition = position.clone().sub(centerOffset).add(alignmentOffset).add(insertionPoint);
          let textData = {
            content,
            directionVector: scaleBarData.directionVector,
            insertionPoint: textPosition,
            relativePosition: TEXT_ALIGNMENT
          };
          this.entitiesSection += this.makeTextFromDataAndProps(textData, textProps);
        } /// <--

      }
    }

    addDrawingUnitTextBlock(unitTextBlockDataAndProps, units) {
      let {
        unitTextBlockData,
        unitTextBlockProps
      } = unitTextBlockDataAndProps;

      if (unitTextBlockData && unitTextBlockProps) {
        let textData = { ...unitTextBlockData
        };
        textData.content = this.GraphicsThree3D.DrawingPlaneLoader.generateUnitTextContent(unitTextBlockData, units);
        this.entitiesSection += this.makeTextFromDataAndProps(textData, unitTextBlockProps);
      }
    }

    addDrawingScaleTextBlock(scaleTextBlockDataAndProps, scale) {
      let {
        scaleTextBlockData,
        scaleTextBlockProps
      } = scaleTextBlockDataAndProps;

      if (scaleTextBlockData && scaleTextBlockProps) {
        let textData = { ...scaleTextBlockData
        };
        textData.content = `${scaleTextBlockData.text} ${scale.toFixed(scaleTextBlockData.decimals)}`;
        this.entitiesSection += this.makeTextFromDataAndProps(textData, scaleTextBlockProps);
      }
    }

    addDrawingFreeTexts(freeTextDataAndProps) {
      let {
        freeTexts,
        freeTextProps
      } = freeTextDataAndProps;

      if (freeTexts && freeTextProps) {
        freeTexts.forEach(text => this.entitiesSection += this.makeTextFromDataAndProps(text, freeTextProps));
      }
    }

    makeTextFromDataAndProps(textData, textProps) {
      let {
        size,
        heightWidthRatio
      } = textProps;
      let color = this.getColorIndexFromColor(this.getColorFromProps(textProps));
      let fontName = this.generateFontName(textProps);
      let {
        content,
        directionVector,
        relativePosition
      } = textData;
      let justifications = this.getTextJustifications(relativePosition);
      let rotation = this.getRotationFromDirection(directionVector);
      let insertionPoint = { ...textData.insertionPoint
      };
      insertionPoint.y -= this.offsetFromTop;
      insertionPoint.z = 0;
      let textParams = {
        content,
        position: insertionPoint,
        height: size,
        widthRatio: heightWidthRatio,
        justifications,
        rotation,
        color,
        fontName,
        layerName: 0
      };
      return this.drawText(textParams);
    }

    getRotationFromDirection(directionVector) {
      let xAxis = new THREE.Vector2(1, 0, 0);
      let direction = utils.initVector2(directionVector).normalize();
      let dot = direction.dot(xAxis);
      let cross = direction.cross(xAxis);
      let rotation = Math.acos(dot) * (cross < 0 ? 1 : -1) * 180 / Math.PI;
      return rotation;
    }

    getTextJustifications(relativePosition) {
      let justifications = {
        justificHorizontal: 0,
        justificVertical: 0
      };

      switch (relativePosition) {
        case 1:
          justifications.justificHorizontal = 1;
          justifications.justificVertical = 2;
          break;

        case 2:
          justifications.justificHorizontal = 0;
          justifications.justificVertical = 1;
          break;

        case 3:
          justifications.justificHorizontal = 0;
          justifications.justificVertical = 3;
          break;

        case 4:
          justifications.justificHorizontal = 2;
          justifications.justificVertical = 3;
          break;

        case 5:
          justifications.justificHorizontal = 2;
          justifications.justificVertical = 1;
          break;

        default:
          break;
      }

      return justifications;
    }

    drawSolid(p1, p2, p3, p4, color, layerName) {
      let solid = `0\nSOLID\n8\n${layerName}\n62\n${color}\n` + `10\n${p1.x}\n20\n${p1.y}\n30\n${p1.y}\n11\n${p2.x}\n21\n${p2.y}\n31\n${p2.z}\n` + `12\n${p3.x}\n22\n${p3.y}\n32\n${p3.z}\n13\n${p4.x}\n23\n${p4.y}\n33\n${p4.z}\n`;
      return solid;
    }

    drawText({
      content,
      position,
      height,
      widthRatio,
      justifications,
      rotation,
      color,
      fontName,
      layerName
    }) {
      let {
        justificHorizontal,
        justificVertical
      } = justifications;
      let text = `0\nTEXT\n8\n${layerName}\n1\n${content}\n40\n${height}\n41\n${widthRatio}\n72\n${justificHorizontal}\n73\n${justificVertical}\n` + `50\n${rotation}\n62\n${color}\n7\n${fontName}\n10\n${0}\n20\n${0}\n30\n${0}\n` + `11\n${position.x}\n21\n${position.y}\n31\n${position.z}\n`;
      return text;
    }

    addDrawingFrame(frameDataAndProps) {
      let {
        frameData,
        frameProps
      } = frameDataAndProps;
      this.generateFrameOuterLine(frameData, frameProps.outerLine);
      this.generateFrameInnerLine(frameData, frameProps.innerLine);
    }

    generateFrameOuterLine(frameData, outerProps) {
      let {
        width,
        height
      } = frameData;
      let thickness = 0; //outerProps.thickness;

      let color = this.getColorIndexFromColor(this.getColorFromProps(outerProps));
      let outerPoints = [{
        x: 0,
        y: -this.offsetFromTop,
        z: 0
      }, {
        x: width,
        y: -this.offsetFromTop,
        z: 0
      }, {
        x: width,
        y: height - this.offsetFromTop,
        z: 0
      }, {
        x: 0,
        y: height - this.offsetFromTop,
        z: 0
      }, {
        x: 0,
        y: -this.offsetFromTop,
        z: 0
      }];

      for (let i = 0; i < outerPoints.length - 1; i++) {
        let extrudeDirection = i % 2 ? {
          x: 0,
          y: 1,
          z: 0
        } : {
          x: 1,
          y: 0,
          z: 0
        };
        this.entitiesSection += this.drawThickLine(outerPoints[i], outerPoints[i + 1], color, thickness, extrudeDirection, 0);
      }
    }

    generateFrameInnerLine(frameData, innerProps) {
      let {
        width,
        height
      } = frameData;
      let {
        left,
        right,
        top,
        bottom
      } = frameData.border;
      let thickness = 0; //innerProps.thickness;

      let color = this.getColorIndexFromColor(this.getColorFromProps(innerProps));
      let innerPoints = [{
        x: left,
        y: -this.offsetFromTop + bottom,
        z: 0
      }, {
        x: width - right,
        y: -this.offsetFromTop + bottom,
        z: 0
      }, {
        x: width - right,
        y: -this.offsetFromTop + height - top,
        z: 0
      }, {
        x: left,
        y: -this.offsetFromTop + height - top,
        z: 0
      }, {
        x: left,
        y: -this.offsetFromTop + bottom,
        z: 0
      }];

      for (let i = 0; i < innerPoints.length - 1; i++) {
        let extrudeDirection = i % 2 ? {
          x: 0,
          y: 1,
          z: 0
        } : {
          x: 1,
          y: 0,
          z: 0
        };
        this.entitiesSection += this.drawThickLine(innerPoints[i], innerPoints[i + 1], color, thickness, extrudeDirection, 0);
      }
    }

    setScaleAndReferencePoint() {
      this.getScale();
      this.referenceZeroPoint.copy(this.getProjectedReferenceZeroPoint());
    }

    getProjectedReferenceZeroPoint() {
      return this.projectPointOnScreen(new THREE.Vector3(), this.GraphicsThree3D.camera);
    }

    getScale() {
      let {
        x,
        y
      } = this.GraphicsThree3D.renderer.getSize(new THREE.Vector2());
      this.scaleFactor = x / y / (this.drawingSizeHalf.width / this.drawingSizeHalf.height);
    }

    generateFastenerBlocks(drawingPlaneData, fastenerPoints) {
      const CAMERA = this.GraphicsThree3D.camera;
      const POINT_SIZE_CORRECTED = POINT_SIZE / drawingPlaneData.scale;
      let fastenerBlocks = "";
      let dxfFastenerVisualisation = drawingPlaneData.dxfFastenerVisualisation;
      Object.entries(fastenerPoints).forEach(([id, pointData]) => {
        let blockName = `${drawingPlaneData.id}_${id}`;
        let layerName = `fastener_${id}`;
        this.blocks.fasteners.push({
          blockName,
          layerName
        });
        fastenerBlocks += `0\nBLOCK\n8\n${layerName}\n2\n${blockName}\n70\n0\n10\n${this.referenceZeroPoint.x}\n20\n${this.referenceZeroPoint.y}\n30\n${this.referenceZeroPoint.x}\n3\n${blockName}\n`;
        let isCutDrawing = drawingPlaneData.cutParams.type === 2;

        if (dxfFastenerVisualisation.axis) {
          pointData.axis.forEach(({
            pointPair,
            properties
          }) => {
            let lineColor = this.getColorIndexFromColor(this.getColorFromProps(properties.line));
            let pointColor = this.getColorIndexFromColor(this.getColorFromProps(properties.point));

            if (isCutDrawing) {
              const DELTA = 1e-4;
              let plane = getDrawingCuttingPlane(drawingPlaneData.cutParams);
              let distanceOne = plane.distanceToPoint(pointPair[0]);
              let distanceTwo = plane.distanceToPoint(pointPair[1]);

              if (Math.abs(distanceOne) < DELTA && Math.abs(distanceTwo) < DELTA) {
                let projectedPoints = this.getProjectedPointsArr([pointPair], CAMERA);
                projectedPoints.forEach(ptsPair => fastenerBlocks += this.drawLine(ptsPair[0], ptsPair[1], lineColor, layerName));
                projectedPoints.forEach(ptsPair => fastenerBlocks += this.drawCircle(ptsPair[0], POINT_SIZE_CORRECTED, pointColor, layerName));
              } else {
                let line = new THREE.Line3(pointPair[0], pointPair[1]);
                let point = plane.intersectLine(line, new THREE.Vector3());
                if (point) fastenerBlocks += this.drawCircle(this.projectPointOnScreen(point, CAMERA), POINT_SIZE_CORRECTED, lineColor, layerName);
              }
            } else {
              let projectedPoints = this.getProjectedPointsArr([pointPair], CAMERA);
              projectedPoints.forEach(ptsPair => fastenerBlocks += this.drawLine(ptsPair[0], ptsPair[1], lineColor, layerName));
              projectedPoints.forEach(ptsPair => fastenerBlocks += this.drawCircle(ptsPair[0], POINT_SIZE_CORRECTED, pointColor, layerName));
            }
          });
        }

        if (dxfFastenerVisualisation.addOn) {
          pointData.addon.forEach(({
            points,
            mesh,
            properties
          }) => {
            let color = this.getColorIndexFromColor(this.getColorFromProps(properties));

            if (isCutDrawing) {
              let cuttingPlane = getDrawingCuttingPlane(drawingPlaneData.cutParams);

              _OutlineGenerator.OutlineGenerator.generateCutLinePts(mesh, cuttingPlane).forEach(ptPair => {
                let transfromedPts = ptPair.map(pt => pt.clone());
                let pts = this.getProjectedPoints(transfromedPts, CAMERA);
                fastenerBlocks += this.drawLine(pts[0], pts[1], color, layerName);
              });
            } else {
              let projectedPoints = this.getProjectedPointsArr(points, CAMERA);
              projectedPoints.forEach(pointsArr => {
                for (let i = 0; i < pointsArr.length - 1; i++) fastenerBlocks += this.drawLine(pointsArr[i], pointsArr[i + 1], color, layerName);
              });
            }
          });
        }

        if (dxfFastenerVisualisation.fastener) {
          let cuttingPlane = isCutDrawing ? getDrawingCuttingPlane(drawingPlaneData.cutParams) : getFastenerCuttingPlane(drawingPlaneData.normal);
          pointData.fastener.forEach(({
            mesh,
            color
          }) => {
            let fastenerColor = this.getColorIndexFromColor(this.getColorFromProps({
              color
            }));
            let cutMesh = mesh.clone();
            if (!isCutDrawing) cutMesh.matrixWorld.identity();

            _OutlineGenerator.OutlineGenerator.generateCutLinePts(cutMesh, cuttingPlane).forEach(ptPair => {
              let transfromedPts = ptPair.map(pt => isCutDrawing ? pt.clone() : mesh.localToWorld(pt.clone()));
              let pts = this.getProjectedPoints(transfromedPts, CAMERA);
              fastenerBlocks += this.drawLine(pts[0], pts[1], fastenerColor, layerName);
            });
          });
        }

        fastenerBlocks += "0\nENDBLK\n";
      });
      return fastenerBlocks;
    }

    drawLines(linesData) {
      let lines = [];
      linesData.forEach(line => {
        let color = this.getColorIndexFromColor(this.getColorFromProps(line.properties));
        let projectedPointPairs = this.getProjectedPointsArr(line.pointPairs, this.GraphicsThree3D.camera);
        projectedPointPairs.forEach(pair => lines.push(this.drawLine(pair[0], pair[1], color, `drawingObject_${line.id}`)));
      });
      return lines;
    }

    drawPoints(pointsData, drawingScale) {
      let points = [];
      pointsData.forEach(point => {
        let color = this.getColorIndexFromColor(this.getColorFromProps(point.properties));
        let radius = point.properties.size / drawingScale;
        let centerPos = utils.initVector3(point.position);
        let projectedCenterPos = this.projectPointOnScreen(centerPos, this.GraphicsThree3D.camera);
        points.push(this.drawCircle(projectedCenterPos, radius, color, `drawingObject_${point.id}`));
      });
      return points;
    }

    drawCircle(center, radius, color, layerName) {
      return `0\nCIRCLE\n8\n${layerName}\n62\n${color}\n40\n${radius}\n10\n${center.x}\n20\n${center.y}\n30\n${center.z}\n`;
    }

    drawArc(center, radius, color, layerName) {
      const START_ANGLE = 0,
            END_ANGLE = 359.9;
      return `0\nARC\n8\n${layerName}\n62\n${color}\n10\n${center.x}\n20\n${center.y}\n30\n${center.z}\n40\n${radius}\n50\n${START_ANGLE}\n51\n${END_ANGLE}`;
    }

    getProjectedPointsArr(ptPairs, camera) {
      return ptPairs.map(pair => this.getProjectedPoints(pair, camera));
    }

    getProjectedPoints(points, camera) {
      return points.map(pt => this.projectPointOnScreen(pt, camera));
    }

    projectPointOnScreen(point, camera) {
      let widthHalf = this.drawingSizeHalf.width;
      let heightHalf = this.drawingSizeHalf.height;
      let projectedPoint = point.clone();
      projectedPoint.project(camera);
      projectedPoint.x = projectedPoint.x * widthHalf + widthHalf - this.outerFrameOffset;
      projectedPoint.y = projectedPoint.y * heightHalf + heightHalf - this.offsetFromTop;
      projectedPoint.z = 0;
      this.projectedPoints.push(projectedPoint);
      return projectedPoint;
    }

    setDrawingSizeHalf(frameData, frameProps) {
      this.drawingSizeHalf = {
        width: (frameData.width + frameProps.outerLine.thickness) / 2,
        height: (frameData.height + frameProps.outerLine.thickness) / 2
      };
    }

    getFacesFromScene(scene) {
      let faces = [];
      scene.traverse(child => {
        if (child.addToDXF) {
          faces.push(...this.getMeshFaces(child));
        }
      });
      return faces;
    }

    getMeshFaces(mesh) {
      let faces = [];
      let color = this.getColorIndexFromColor(mesh.material[0].color);
      let geometry = mesh.geometry;
      let meshFaces = geometry.faces;
      let meshVertices = geometry.vertices;
      meshFaces.forEach(face => {
        let vectorA = meshVertices[face.a].clone();
        let vectorB = meshVertices[face.b].clone();
        let vectorC = meshVertices[face.c].clone();
        vectorA.applyMatrix4(mesh.matrix);
        vectorB.applyMatrix4(mesh.matrix);
        vectorC.applyMatrix4(mesh.matrix);
        faces.push(this.drawFace(vectorA, vectorB, vectorC, color, mesh.dxfLayer));
      });
      return faces;
    }

    drawWireframeLines(wireframePoints) {
      let lines = [];
      wireframePoints.forEach(({
        lineProps,
        pointPairs,
        id
      }) => {
        let color = this.getColorIndexFromColor(this.getColorFromProps(lineProps));
        let projectedPoints = this.getProjectedPointsArr(pointPairs, this.GraphicsThree3D.camera);
        let layerName = `drawingObject_${id}`;
        projectedPoints.forEach(ptsPair => lines.push(this.drawLine(ptsPair[0], ptsPair[1], color, layerName)));
      });
      return lines;
    }

    drawThickLine(p1, p2, color, thickness, extrudeDirection, layerName) {
      let line = `0\nLINE\n8\n${layerName}\n62\n${color}\n10\n${p1.x}\n20\n${p1.y}\n30\n${p1.z}\n11\n${p2.x}\n21\n${p2.y}\n31\n${p2.z}\n` + `39\n${thickness}\n210\n${extrudeDirection.x}\n220\n${extrudeDirection.y}\n230\n${extrudeDirection.z}\n`;
      return line;
    }

    drawLine(p1, p2, color, layerName) {
      return `0\nLINE\n8\n${layerName}\n62\n${color}\n10\n${p1.x}\n20\n${p1.y}\n30\n${p1.z}\n11\n${p2.x}\n21\n${p2.y}\n31\n${p2.z}\n`;
    }

    drawFace(p1, p2, p3, color, layerName) {
      let face = [`0\n3DFACE\n8\n${layerName}\n62\n${color}`, `10\n${p1.x}\n20\n${p1.y}\n30\n${p1.z}`, `11\n${p2.x}\n21\n${p2.y}\n31\n${p2.z}`, `12\n${p3.x}\n22\n${p3.y}\n32\n${p3.z}`, `13\n${p3.x}\n23\n${p3.y}\n33\n${p3.z}`].join("\n");
      return face;
    }

    getColorIndexFromColor(color) {
      let colorRGB = this.getRGBFromColor(color);
      let colorIndex = this.getColorIndexFromRGB(colorRGB);
      return colorIndex;
    }

    getColorIndexFromRGB([r, g, b]) {
      let minDistance = Infinity;
      let matchedIndex = 0;

      for (let i = 0; i < 255; i++) {
        let lookupColor = _dxf_colors.COLOR_LOOKUP[i];
        let distance = Math.pow(r - lookupColor[0], 2) + Math.pow(g - lookupColor[1], 2) + Math.pow(b - lookupColor[2], 2);

        if (distance < minDistance) {
          minDistance = distance;
          matchedIndex = i;
        }
      }

      return matchedIndex;
    }

    getRGBFromColor(color) {
      return [255 * color.r, 255 * color.g, 255 * color.b];
    }

    getColorFromProps(props) {
      return {
        r: props.color.red / 255,
        g: props.color.green / 255,
        b: props.color.blue / 255
      };
    }

    clearObjectData() {
      this.referenceZeroPoint = new THREE.Vector3();
      this.scaleFactor = 1;
      this.blocks = {
        fasteners: [],
        drawingObjects: []
      };
      this.projectedPoints = [];
      this.wireframePoints = [];
      this.fastenerPoints = {};
      this.pointObjectsData = [];
      this.lineObjectsData = [];
      this.outerFrameOffset = 0;
      this.drawingScaleBarBBox = null;
      this.drawingSizeHalf = {
        width: 0,
        height: 0
      };
    }

    clearDXF() {
      this.blockSection = "";
      this.entitiesSection = "";
      this.textStyles = "";
      this.offsetFromTop = 0;
      this.clearObjectData();
    }

  }

  _exports.DXFGenerator = DXFGenerator;

  function cutLines(cutParams, lineObjectsData) {
    let generatedPts = [];
    let plane = getDrawingCuttingPlane(cutParams);
    lineObjectsData.forEach(data => {
      for (let pair of data.pointPairs) {
        if (Math.sign(plane.distanceToPoint(pair[0])) !== Math.sign(plane.distanceToPoint(pair[1]))) {
          let line = new THREE.Line3(pair[0], pair[1]);
          generatedPts.push({
            id: data.id,
            position: plane.intersectLine(line, new THREE.Vector3()),
            properties: {
              color: data.properties.color,
              size: data.properties.thickness
            }
          });
          break;
        }
      }
    });
    return generatedPts;
  }

  function getFastenerCuttingPlane(drawingNormal) {
    let cutNormal = getFastenerCutNormal(drawingNormal);
    return new THREE.Plane(cutNormal, 0);
  }

  function getFastenerCutNormal(normal) {
    let drawingNormal = utils.initVector3(normal).normalize();
    let axisArr = [new THREE.Vector3(1, 0, 0), new THREE.Vector3(0, 1, 0), new THREE.Vector3(0, 0, 1)];
    let maxDotProd = 0;
    let cutNormal = new THREE.Vector3();

    for (let axis of axisArr) {
      let dotProdAbs = Math.abs(axis.dot(drawingNormal));

      if (dotProdAbs > maxDotProd) {
        maxDotProd = dotProdAbs;
        cutNormal.copy(axis);
      }
    }

    return cutNormal;
  }

  function getFilteredPoints(cutParams, pointsDataArr) {
    const MIN_DISTANCE = 1e-4;
    let plane = getDrawingCuttingPlane(cutParams);
    return pointsDataArr.filter(data => Math.abs(plane.distanceToPoint(utils.initVector3(data.position))) < MIN_DISTANCE);
  }

  function cutObjects(cutParams, objectDataArr) {
    let plane = getDrawingCuttingPlane(cutParams);
    let objectsCutted = objectDataArr.map(data => ({ ...data,
      pointPairs: _OutlineGenerator.OutlineGenerator.generateCutLinePts(data.object, plane)
    }));
    return objectsCutted;
  }

  function getDrawingCuttingPlane(cutParams) {
    let normalVec = utils.initVector3(cutParams.normalVector);
    let constant = getCuttingPlaneDistance(cutParams.positionVector, normalVec);
    return new THREE.Plane(normalVec, constant);
  }

  function getCuttingPlaneDistance(positionVector, normalVector) {
    // maybe use method "getClippingPlaneDistance" from drawing plane loader?
    let positionVec = utils.initVector3(positionVector);
    let positionDirection = positionVec.clone().normalize();
    let planeDistance = positionVec.length();
    if (normalVector.dot(positionDirection) > 0) planeDistance *= -1;
    return planeDistance;
  }

  function downloadTextFile(filename, text) {
    let element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;charset=utf-8,${text}`);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
});