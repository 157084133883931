define("m08-2020/lib old/Utils/LineIntersectionDetection", ["exports"], function (_exports) {
  "use strict";
  /**
   * https://www.geeksforgeeks.org/check-if-two-given-line-segments-intersect/
   * Check if Lines intersect 2D
   */

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.linesIntersect = linesIntersect;
  _exports.onSegment = onSegment;
  _exports.orientation = orientation;

  function onSegment(p, q, r) {
    // p, q and r points are colinear, q lies on line segment pr
    return q.x <= Math.max(p.x, r.x) && q.x >= Math.min(p.x, r.x) && q.y <= Math.max(p.y, r.y) && q.y >= Math.min(p.y, r.y);
  }

  function orientation(p, q, r) {
    /**
     * 0: Colinear
     * 1: Clockwise
     * 2: Counterclockwise
     */
    let val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
    if (val > 0) return 1;else if (val < 0) return 2;else return 0;
  }

  function linesIntersect(p1, q1, p2, q2) {
    /**
     * p1, q1 -> first line points
     * p2, q2 -> second line points
     */
    // Get orientations
    let o1 = orientation(p1, q1, p2);
    let o2 = orientation(p1, q1, q2);
    let o3 = orientation(p2, q2, p1);
    let o4 = orientation(p2, q2, q1); // General Case

    if (o1 !== o2 && o3 !== o4) return true; // Special Cases
    // p1, q1 and p2 are colinear and p2 lies on segment p1q1

    if (o1 === 0 && onSegment(p1, p2, q1)) return true; // p1, q1 and q2 are colinear and q2 lies on segment p1q1

    if (o2 === 0 && onSegment(p1, q2, q1)) return true; // p2, q2 and p1 are colinear and p1 lies on segment p2q2

    if (o3 === 0 && onSegment(p2, p1, q2)) return true; // p2, q2 and q1 are colinear and q1 lies on segment p2q2

    if (o4 === 0 && onSegment(p2, q1, q2)) return true; // no intersection

    return false;
  }
});