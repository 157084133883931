define("m08-2020/lib/SectionalPlane", ["exports", "three", "m08-2020/lib/Utils"], function (_exports, THREE, _Utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SectionalPlane = void 0;
  let utils = new _Utils.Utils(THREE);

  class SectionalPlane {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.clipObjects = [];
      this.renderOrder = 1;
    }

    applySectionalPlanes(sectionalPlanes, properties) {
      let intersectionLineProps = properties.intersectionLine;
      let intersectionAreaProps = properties.intersectionArea; // TODO: Implement sectionalPlane properties for lines and hatching

      sectionalPlanes.forEach(sectionalPlane => this.applySectionalPlane(sectionalPlane, intersectionAreaProps ? intersectionAreaProps : intersectionLineProps));
    }

    applySectionalPlane(sectionalPlaneData, intersectionAreaProps) {
      let positionVector = utils.initVector3(sectionalPlaneData.positionVector);
      let normalVector = utils.initVector3(sectionalPlaneData.normalVector).negate();
      let planeDistance = positionVector.clone().multiply(normalVector).length();
      let distanceSign = positionVector.normalize().dot(normalVector) > 0 ? -1 : 1;
      planeDistance *= distanceSign;
      let clippingPlane = new THREE.Plane(normalVector, planeDistance);
      this.clipObjects.forEach(child => {
        if (child.type == "Mesh" || child.type == "Line" || child.type == "LineSegments") {
          this.clipMesh(clippingPlane, child, intersectionAreaProps);
        }
      });
    }

    clipMesh(clippingPlane, mesh, intersectionAreaProps) {
      if (intersectionAreaProps) {
        let [frontFaceStencilMat, backFaceStencilMat, planeStencilMat] = this.getClippingMaterials(intersectionAreaProps);
        frontFaceStencilMat.clippingPlanes = [clippingPlane];
        backFaceStencilMat.clippingPlanes = [clippingPlane];
        let meshGeo = mesh.geometry.clone();
        let frontMesh = new THREE.Mesh(meshGeo, frontFaceStencilMat);
        frontMesh.applyMatrix4(mesh.matrix);
        this.GraphicsThree3D.scene.add(frontMesh);
        let backMesh = new THREE.Mesh(meshGeo, backFaceStencilMat);
        backMesh.applyMatrix4(mesh.matrix);
        this.GraphicsThree3D.scene.add(backMesh); ///

        frontMesh.renderOrder = this.renderOrder;
        frontMesh.renderOrder = this.renderOrder;
        this.renderOrder++; ///

        let planeNormal = clippingPlane.normal.clone();
        let forwardVector = new THREE.Vector3(0, 0, -1);
        let planeGeom = new THREE.PlaneBufferGeometry();
        let planeMesh = new THREE.Mesh(planeGeom, planeStencilMat);
        planeMesh.scale.setScalar(1000);
        clippingPlane.coplanarPoint(planeMesh.position);
        planeMesh.quaternion.setFromUnitVectors(forwardVector, planeNormal);
        planeMesh.renderOrder = 1;
        planeMesh.position.copy(mesh.position);
        frontMesh.position.copy(mesh.position);
        backMesh.position.copy(mesh.position);

        planeMesh.onBeforeRender = function (renderer) {
          renderer.clearDepth();
        }; ///


        planeMesh.onAfterRender = function (renderer) {
          renderer.clearStencil();
        };

        planeMesh.renderOrder = this.renderOrder + 1.1; ///

        this.GraphicsThree3D.scene.add(planeMesh);
      } ///


      if (mesh.children) mesh.children.forEach(child => this.clipMaterial(child.material, clippingPlane));
      this.clipMaterial(mesh.material, clippingPlane);
    }

    clipMaterial(material, plane) {
      if (material.length) material.forEach(mat => this.addClippingPlane(mat, plane));else this.addClippingPlane(material, plane);
    }

    addClippingPlane(material, plane) {
      if (material.clippingPlanes) material.clippingPlanes.push(plane);else material.clippingPlanes = [plane];
    }

    getClippingMaterials(materialProps) {
      let frontFaceStencilMat, backFaceStencilMat, planeStencilMat; //https://discourse.threejs.org/t/capping-clipped-planes-using-stencil-on-a-buffergeometry/18407/18
      // PASS 1
      // everywhere that the back faces are visible (clipped region) the stencil
      // buffer is incremented by 1.

      backFaceStencilMat = new THREE.MeshBasicMaterial();
      backFaceStencilMat.depthWrite = false;
      backFaceStencilMat.depthTest = false;
      backFaceStencilMat.colorWrite = false;
      backFaceStencilMat.stencilWrite = true;
      backFaceStencilMat.stencilFunc = THREE.AlwaysStencilFunc;
      backFaceStencilMat.side = THREE.BackSide;
      backFaceStencilMat.stencilFail = THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZFail = THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZPass = THREE.IncrementWrapStencilOp; // PASS 2
      // everywhere that the front faces are visible the stencil
      // buffer is decremented back to 0.

      frontFaceStencilMat = new THREE.MeshBasicMaterial();
      frontFaceStencilMat.depthWrite = false;
      frontFaceStencilMat.depthTest = false;
      frontFaceStencilMat.colorWrite = false;
      frontFaceStencilMat.stencilWrite = true;
      frontFaceStencilMat.stencilFunc = THREE.AlwaysStencilFunc;
      frontFaceStencilMat.side = THREE.FrontSide;
      frontFaceStencilMat.stencilFail = THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZFail = THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZPass = THREE.DecrementWrapStencilOp; // PASS 3
      // draw the plane everywhere that the stencil buffer != 0, which will
      // only be in the clipped region where back faces are visible.

      planeStencilMat = this.getPlaneStencilMaterial(materialProps);
      planeStencilMat.stencilWrite = true;
      planeStencilMat.stencilRef = 0;
      planeStencilMat.stencilFunc = THREE.NotEqualStencilFunc;
      planeStencilMat.stencilFail = THREE.ReplaceStencilOp;
      planeStencilMat.stencilZFail = THREE.ReplaceStencilOp;
      planeStencilMat.stencilZPass = THREE.ReplaceStencilOp;
      return [frontFaceStencilMat, backFaceStencilMat, planeStencilMat];
    }

    getPlaneStencilMaterial(areaProps) {
      const SIZE = 20;
      let planeStencilMat;
      let colorProps = areaProps.color;

      if (areaProps.hatching.type === 2) {
        let orientationVector = utils.normalizeVector(areaProps.hatching.orientationVector);
        let hatchSpacing = areaProps.hatching.factor;
        let hatchColor = areaProps.hatching.color;
        let hatchThickness = areaProps.hatching.thickness;
        planeStencilMat = this.GraphicsThree3D.makeCanvasMaterial(colorProps, hatchColor, orientationVector, hatchSpacing, hatchThickness);
        planeStencilMat.map.wrapS = planeStencilMat.map.wrapT = THREE.RepeatWrapping;
        planeStencilMat.map.repeat.set(SIZE, SIZE);
        planeStencilMat.map.offset.set(0.5, 0.5);
      } else if (areaProps.hatching.type === 1) {
        planeStencilMat = this.GraphicsThree3D.getMaterialFromColor(colorProps);
      } // planeStencilMat.side = THREE.FrontSide;


      return planeStencilMat;
    }

    resetClipObjects() {
      this.clipObjects = [];
    }

  }

  _exports.SectionalPlane = SectionalPlane;
});