define("m08-2020/lib/StateControl/DimChainViewController", ["exports", "three", "m08-2020/lib/ThreeExtensions/VectorString"], function (_exports, THREE, _VectorString) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DimChainViewController = void 0;

  const _cameraLookVec = new THREE.Vector3();

  const _affected3DVisIDs = new Set();

  const _chainNormal = new _VectorString.VectorString();

  let _activeView2D = false;

  class DimChainViewController {
    constructor() {
      this.dimChainVisAngles = {
        view3d: {},
        view2d: {}
      };
      this.angleThreshold = 0.9;
    }

    updateChainVisualization(camera) {
      camera.getWorldDirection(_cameraLookVec); // _affected3DVisIDs.clear();

      _activeView2D = false;
      Object.keys(this.dimChainVisAngles["view2d"]).forEach(key => {
        _chainNormal.setFromString(key);

        if (_chainNormal.dot(_cameraLookVec) < -this.angleThreshold) this.show2D_hide3D(key);else this.hide2D_show3D(key);
      });
    } // Hide all 3D views when 2D is active -->


    show2D_hide3D(key) {
      Object.values(this.dimChainVisAngles["view2d"][key]).forEach(chainArr => {
        _activeView2D = true;
        chainArr.forEach(chain => chain.visible = true);
      });
      Object.values(this.dimChainVisAngles["view3d"]).forEach(chainArr => chainArr.forEach(chain => chain.visible = false));
    }

    hide2D_show3D(key) {
      Object.values(this.dimChainVisAngles["view2d"][key]).forEach(chainArr => chainArr.forEach(chain => chain.visible = false));
      if (!_activeView2D) Object.values(this.dimChainVisAngles["view3d"]).forEach(chainArr => chainArr.forEach(chain => chain.visible = true));
    } // <-- Hide all 3D views when 2D is active
    // Toggle only specific views -->


    makeVisible2D(key) {
      Object.entries(this.dimChainVisAngles["view2d"][key]).forEach(([id, chainArr]) => {
        _affected3DVisIDs.add(id);

        chainArr.forEach(chain => chain.visible = true);
        Object.values(this.dimChainVisAngles["view3d"][id]).forEach(chain => chain.visible = false);
      });
    }

    makeVisible3D(key) {
      Object.entries(this.dimChainVisAngles["view2d"][key]).forEach(([id, chainArr]) => {
        chainArr.forEach(chain => chain.visible = false);
        if (!_affected3DVisIDs.has(id)) Object.values(this.dimChainVisAngles["view3d"][id]).forEach(chain => chain.visible = true);
      });
    } // <-- Toggle only specific views


    setAngleThreshold(rawValue) {
      this.angleThreshold = +rawValue / 100;
    }

    clearState() {
      this.dimChainVisAngles["view3d"] = {};
      this.dimChainVisAngles["view2d"] = {};
    }

    initView2D(normal, id) {
      if (!this.dimChainVisAngles["view2d"][normal]) this.dimChainVisAngles["view2d"][normal] = {};
      if (!this.dimChainVisAngles["view2d"][normal][id]) this.dimChainVisAngles["view2d"][normal][id] = [];
    }

    addChainsToView3D(id, chains) {
      this.dimChainVisAngles["view3d"][id] = [...chains];
    }

    addChainToView2D(normal, id, chain) {
      this.dimChainVisAngles["view2d"][normal][id].push(chain);
    }

  }

  _exports.DimChainViewController = DimChainViewController;
});