define("m08-2020/lib/Utils/DimensionalChainIntersectionDetection", ["exports", "three", "hull.js", "intersects", "m08-2020/lib/Utils"], function (_exports, THREE, hull, intersects, _Utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DimensionalChainIntersectionDetection = void 0;
  let utils = new _Utils.Utils(THREE);

  class DimensionalChainIntersectionDetection {
    constructor() {
      this.meshArr = []; // [ DoubleFace, FrontFace, BackFace ]

      this.chainArr = []; // [ DoubleFace, FrontFace, BackFace ]

      this.chainsForZoom = []; // [ DoubleFace, FrontFace, BackFace ]
    }

    checkCollision2D(linePtsArr, objArr, drawingNormal) {
      const Z_AXIS = new THREE.Vector3(0, 0, 1);
      let quaternion = new THREE.Quaternion().setFromUnitVectors(drawingNormal, Z_AXIS);
      let projectedLinePts = projectLinePoints(linePtsArr);
      let projectedObjPts = projectObjectPoints(objArr);
      return isCollision(projectedLinePts, projectedObjPts); // Helper functions -->

      function isCollision(projectedLinePts, projectedObjPts) {
        const TOLERANCE = 1e-4;
        let collisionDetected = false;

        for (let line of projectedLinePts) {
          for (let polygon of projectedObjPts) {
            let pts = [];
            polygon.forEach(pt => pts.push(pt[0], pt[1]));
            if (intersects.linePolygon(line[0][0], line[0][1], line[1][0], line[1][1], pts, TOLERANCE)) collisionDetected = true;
          }
        }

        return collisionDetected;
      }

      function projectLinePoints(linePtsArr) {
        let projectedLinePts = [];

        for (let ptPair of linePtsArr) {
          let ptsProj = [];

          for (let pt of ptPair) {
            let projectedPt = pt.clone().projectOnPlane(drawingNormal).applyQuaternion(quaternion);
            ptsProj.push([projectedPt.x, projectedPt.y]);
          }

          projectedLinePts.push(ptsProj);
        }

        return projectedLinePts;
      }

      function projectObjectPoints(objArr) {
        let projectedObjPts = [];

        for (let meshArr of objArr) {
          let mesh = meshArr[0];
          let ptsProj = [];

          for (let vertex of mesh.geometry.vertices) {
            ptsProj.push(vertex.clone().applyMatrix4(mesh.matrix).projectOnPlane(drawingNormal).applyQuaternion(quaternion));
          }

          projectedObjPts.push(hull(ptsProj.map(pt => [pt.x, pt.y]), Infinity));
        }

        return projectedObjPts;
      } // Helper functions <--

    }

    checkCollision(linePtsArr, objArr) {
      let isIntersection = false;
      let raycaster = new THREE.Raycaster();

      for (let [pointOne, pointTwo] of linePtsArr) {
        let pointsDistance = pointOne.distanceTo(pointTwo);

        for (let meshArr of objArr) {
          let intersectionsOne = this.getIntersections(raycaster, [pointOne, pointTwo], meshArr);
          let intersectionsTwo = this.getIntersections(raycaster, [pointTwo, pointOne], meshArr);
          if (this.intersectionDetected(pointsDistance, intersectionsOne, intersectionsTwo)) isIntersection = true;
        }
      }

      return isIntersection;
    }

    intersectionDetected(pointsDistance, intersectionsOne, intersectionsTwo) {
      let [intersectionsOneDouble, intersectionsOneFront, intersectionsOneBack] = intersectionsOne;
      let [intersectionsTwoDouble, intersectionsTwoFront, intersectionsTwoBack] = intersectionsTwo;
      let mutualIntersections = this.getMutualIntersections(intersectionsOneDouble, intersectionsTwoDouble);
      return mutualIntersections.length !== 1 && this.bothPointsOutsideMesh(mutualIntersections, pointsDistance) || this.onePointInsideMeshOneOut(mutualIntersections, intersectionsOneDouble, intersectionsTwoDouble) || this.bothPointsInMesh(intersectionsOneBack, intersectionsTwoBack, intersectionsOneFront, intersectionsTwoFront);
    }

    bothPointsOutsideMesh(mutualIntersections, pointsDistance) {
      let intersectionInMiddle = false;
      mutualIntersections.forEach(intersection => {
        if (intersection.distance < pointsDistance) intersectionInMiddle = true;
      });
      return intersectionInMiddle;
    }

    onePointInsideMeshOneOut(mutualIntersections, intersectionsOneDouble, intersectionsTwoDouble) {
      let pointOneInside = this.pointInsideMesh(intersectionsOneDouble);
      let pointTwoInside = this.pointInsideMesh(intersectionsTwoDouble);
      return mutualIntersections.length > 0 && (pointOneInside && !pointTwoInside || !pointOneInside && pointTwoInside);
    }

    bothPointsInMesh(intersectionsOneBack, intersectionsTwoBack, intersectionsOneFront, intersectionsTwoFront) {
      return intersectionsOneBack.length > 0 && intersectionsTwoBack.length > 0 && intersectionsOneFront.length === 0 && intersectionsTwoFront.length === 0;
    }

    getIntersections(raycaster, ptsPair, [meshDoubleFace, meshFrontFace, meshBackFace]) {
      let [pointOne, pointTwo] = ptsPair;
      let direction = pointTwo.clone().sub(pointOne).normalize();
      raycaster.set(pointOne, direction);
      let intersectionsDouble = [];
      let intersectionsFront = [];
      let intersectionsBack = [];
      const DELTA = 1e-4;
      intersectionsDouble.push(...raycaster.intersectObject(meshDoubleFace).filter(intersection => intersection.distance > DELTA));
      intersectionsFront.push(...raycaster.intersectObject(meshFrontFace).filter(intersection => intersection.distance > DELTA));
      intersectionsBack.push(...raycaster.intersectObject(meshBackFace).filter(intersection => intersection.distance > DELTA));
      intersectionsDouble = this.removeDuplicateIntersections(intersectionsDouble);
      intersectionsFront = this.removeDuplicateIntersections(intersectionsFront);
      intersectionsBack = this.removeDuplicateIntersections(intersectionsBack);
      return [intersectionsDouble, intersectionsFront, intersectionsBack];
    }

    getMutualIntersections(intersectionsOne, intersectionsTwo) {
      let intersections = intersectionsOne.filter(intersectionOne => {
        let isMutual = false;
        let testPoint = intersectionOne.point;
        intersectionsTwo.forEach(intersectionTwo => {
          if (utils.checkVectorsEquality(intersectionTwo.point, testPoint)) isMutual = true;
        });
        return isMutual;
      });
      return intersections;
    }

    removeDuplicateIntersections(intersections) {
      let filterArr = [];
      intersections.forEach(intersection => {
        let isUnique = true;
        filterArr.forEach(([key, _]) => {
          if (utils.vectorsAreEqual(intersection.point, key)) isUnique = false;
        });
        if (isUnique) filterArr.push([intersection.point, intersection]);
      });
      intersections = filterArr.map(([_, value]) => value);
      return intersections;
    }

    intersectionsSharePoints(intersectionsOne, intersectionsTwo) {
      let intersectionsSharePoints = false;

      for (let intersectionOne of intersectionsOne) {
        for (let intersectionTwo of intersectionsTwo) {
          if (utils.vectorsAreEqual(intersectionOne.point, intersectionTwo.point)) intersectionsSharePoints = true;
        }
      }

      return intersectionsSharePoints;
    }

    pointInsideMesh(intersections) {
      return intersections.length % 2;
    }

  }

  _exports.DimensionalChainIntersectionDetection = DimensionalChainIntersectionDetection;
});