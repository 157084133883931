define("m08-2020/lib/FigureLoaders/TextLoader", ["exports", "three", "m08-2020/lib/Utils"], function (_exports, THREE, _Utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TextLoader = void 0;
  const utils = new _Utils.Utils(THREE);

  class TextLoader {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
    }

    loadTexts(textProps, texts, jsonData, insertionPoints) {
      let font = this.loadFont(textProps);

      if (font) {
        let textArr = [];
        texts.forEach(text => {
          let newText = this.drawText(text, textProps, font);
          newText.jsonID = text.id;
          textArr.push(newText);
        });
        let positionedTexts = this.applyPositionAndRotation(textArr, insertionPoints);
        positionedTexts.forEach(text => {
          let objectInfo = {
            id: text.jsonID,
            data: jsonData,
            sceneObject: text
          }; ///

          this.GraphicsThree3D.ThreeObjects.push(objectInfo);
          this.GraphicsThree3D.scene.add(text); ///
        });
      }
    }

    drawText(text, textProps, font) {
      let normalVector = utils.initVector3(text.normalVector);
      let directionVector = utils.initVector3(text.directionVector);
      let textMesh;

      if (!normalVector.dot(directionVector) && normalVector.lengthSq() && directionVector.lengthSq()) {
        let textInsertionPoint = text.insertionPoint;
        let textGeo = this.getTextGeo(text, textProps, font);
        let textMaterial = this.GraphicsThree3D.getMaterialFromColor(textProps.color);
        textMesh = new THREE.Mesh(textGeo, textMaterial);
        this.GraphicsThree3D.alignObjectWithVectorsAndChangePos(textMesh, textInsertionPoint, normalVector, directionVector);
      } else {
        textMesh = new THREE.Mesh();
      }

      return textMesh;
    }

    loadFont(textProps) {
      let loadedFonts = this.GraphicsThree3D.loadedFonts;
      let [fontUrl, fontName] = this.getFontUrl(textProps);
      let storedFont = loadedFonts[fontName];
      let loader = new THREE.FontLoader();
      let font;

      if (fontUrl) {
        if (storedFont) {
          font = storedFont;
        } else {
          $.ajax({
            'async': false,
            type: "GET",
            url: fontUrl
          }).then(function (dataFont) {
            font = loader.parse(dataFont);
            loadedFonts[fontName] = font;
          });
        }
      }

      return font;
    }

    getTextGeo(text, textProps, font) {
      let textContent = text.content;
      let fontSize = textProps.size;
      let heightWidthRatio = textProps.heightWidthRatio;
      let textGeo = new THREE.TextBufferGeometry(textContent, {
        font: font,
        size: fontSize,
        height: 0.01,
        curveSegments: 8
      });
      textGeo.scale(heightWidthRatio, 1, 1);
      textGeo.computeBoundingBox();

      switch (text.relativePosition) {
        case 1:
          textGeo.center();
          break;

        case 2:
          textGeo.translate(0, -textGeo.boundingBox.min.y, 0);
          break;

        case 3:
          textGeo.translate(0, -textGeo.boundingBox.max.y, 0);
          break;

        case 4:
          textGeo.translate(-textGeo.boundingBox.max.x, -textGeo.boundingBox.max.y, 0);
          break;

        case 5:
          textGeo.translate(-textGeo.boundingBox.max.x, -textGeo.boundingBox.min.y, 0);
          break;

        default:
          textGeo.center();
          break;
      }

      return textGeo;
    }

    applyPositionAndRotation(textArr, insertionPoints) {
      let positionedMeshArr = [];
      if (insertionPoints) insertionPoints.forEach(insertionPoint => {
        let insertionVector = utils.initVector3(insertionPoint.insertionVector);
        let rotationAngle = insertionPoint.rotationAngle;
        let pivotPoint = new THREE.Object3D();

        if (rotationAngle) {
          pivotPoint.position.set(insertionPoint.rotationPoint.x, insertionPoint.rotationPoint.y, insertionPoint.rotationPoint.z);
          textArr.forEach(mesh => pivotPoint.attach(mesh.clone()));
          rotationAngle = rotationAngle * Math.PI / 180;
          let rotationAxis = utils.initVector3(insertionPoint.rotationAxis);
          pivotPoint.rotateOnAxis(rotationAxis, rotationAngle);
        } else {
          textArr.forEach(mesh => pivotPoint.attach(mesh.clone()));
        }

        pivotPoint.position.add(insertionVector);
        pivotPoint.updateMatrixWorld();
        pivotPoint.children.forEach(child => {
          child.applyMatrix4(pivotPoint.matrixWorld);
          positionedMeshArr.push(child);
        });
      });
      return positionedMeshArr;
    }

    getFontUrl(textProps) {
      let fontType = textProps.type;
      let fontStyle = textProps.style;
      let fontUrl = "";
      let fontName = "";

      if (fontStyle == 1) {
        if (fontType == 1) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Regular["Arial"];
          fontName = "FONT_REGULAR_ARIAL";
        } else if (fontType == 2) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Regular["Calibri"];
          fontName = "FONT_REGULAR_CALIBRI";
        } else if (fontType == 3) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Regular["Courier"];
          fontName = "FONT_REGULAR_COURIER";
        } else if (fontType == 4) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Regular["TimesNewRoman"];
          fontName = "FONT_REGULAR_TIMESNEWROMAN";
        }
      } else if (fontStyle == 2) {
        if (fontType == 1) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Italic["Arial"];
          fontName = "FONT_ITALIC_ARIAL";
        } else if (fontType == 2) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Italic["Calibri"];
          fontName = "FONT_ITALIC_CALIBRI";
        } else if (fontType == 3) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Italic["Courier"];
          fontName = "FONT_ITALIC_COURIER";
        } else if (fontType == 4) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Italic["TimesNewRoman"];
          fontName = "FONT_ITALIC_TIMESNEWROMAN";
        }
      } else if (fontStyle == 3) {
        if (fontType == 1) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Bold["Arial"];
          fontName = "FONT_BOLD_ARIAL";
        } else if (fontType == 2) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Bold["Calibri"];
          fontName = "FONT_BOLD_CALIBRI";
        } else if (fontType == 3) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Bold["Courier"];
          fontName = "FONT_BOLD_COURIER";
        } else if (fontType == 4) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.Bold["TimesNewRoman"];
          fontName = "FONT_BOLD_TIMESNEWROMAN";
        }
      } else if (fontStyle == 4) {
        if (fontType == 1) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.BoldItalic["Arial"];
          fontName = "FONT_BOLD_ITALIC_ARIAL";
        } else if (fontType == 2) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.BoldItalic["Calibri"];
          fontName = "FONT_BOLD_ITALIC_CALIBRI";
        } else if (fontType == 3) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.BoldItalic["Courier"];
          fontName = "FONT_BOLD_ITALIC_COURIER";
        } else if (fontType == 4) {
          fontUrl = this.GraphicsThree3D.fontUrlArray.BoldItalic["TimesNewRoman"];
          fontName = "FONT_BOLD_ITALIC_TIMESNEWROMAN";
        }
      }

      return [fontUrl, fontName];
    }

  }

  _exports.TextLoader = TextLoader;
});