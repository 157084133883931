define("m08-2020/lib/ThreeExtensions/PlanarRotationPointsGenerator", ["exports", "three", "polybooljs", "delaunator", "poly-decomp", "hull.js", "m08-2020/lib/WireframeGenerator"], function (_exports, THREE, _polybooljs, _delaunator, PolyDecomp, hull, _WireframeGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PlanarRotationPointsGenerator = void 0;

  class PlanarRotationPointsGenerator {
    constructor(pointsToRotate, rotationParameters) {
      this.rotatedPoints = [];
      this.holePoints = [];
      this.generateRotatedPoints(pointsToRotate, rotationParameters);
    }

    generateRotatedPoints(pointsToRotate, rotationParameters) {
      const DETAIL = 64;
      let {
        rotationAngle,
        rotationPitch
      } = rotationParameters;
      let numOfSegments = DETAIL * Math.abs(rotationAngle) / Math.PI;
      let segmentRotation = rotationAngle / numOfSegments;
      let polyPts2D = pointsToRotate.map(pt => [pt.x, pt.y]);
      PolyDecomp.makeCCW(polyPts2D);
      let convexPolygons = PolyDecomp.quickDecomp(polyPts2D);
      let polygonSegments = [];
      convexPolygons.forEach(polygon => {
        let polyPts3D = polygon.map(pt => new THREE.Vector3(pt[0], pt[1], 0));
        let rotatedPointsPolygons = this.getRotatedPointsPolygons(polyPts3D, numOfSegments, segmentRotation);
        let decomposedPolySegments = rotatedPointsPolygons[0];

        for (let i = 1; i < rotatedPointsPolygons.length; i++) {
          let comb = _polybooljs.default.combine(decomposedPolySegments, rotatedPointsPolygons[i]);

          decomposedPolySegments = _polybooljs.default.selectUnion(comb);
        }

        polygonSegments.push(decomposedPolySegments);
      });
      let finalPolygon = this.uniteSegmentsAndReturnPolygon(polygonSegments);
      this.getPointsFromFinalPolygon(finalPolygon);
    }

    getPointsFromFinalPolygon(finalPolygon) {
      // TODO: Maybe need to check radiuses of segments to check which ones are outer shape and which are holes?
      if (finalPolygon.regions.length > 1) {
        for (let i = 0; i < finalPolygon.regions.length - 1; i++) {
          this.holePoints.push(finalPolygon.regions[i].map(pt => new THREE.Vector3(pt[0], pt[1], 0)));
        }
      }

      this.rotatedPoints = finalPolygon.regions[finalPolygon.regions.length - 1].map(pt => new THREE.Vector3(pt[0], pt[1], 0));
    }

    uniteSegmentsAndReturnPolygon(segments) {
      let finalSegments = segments[0];

      for (let i = 1; i < segments.length; i++) {
        let comb = _polybooljs.default.combine(finalSegments, segments[i]);

        finalSegments = _polybooljs.default.selectUnion(comb);
      }

      return _polybooljs.default.polygon(finalSegments);
    }

    getRotatedPointsPolygons(points, numOfSegments, segmentRotation) {
      const Z_AXIS = new THREE.Vector3(0, 0, 1);
      let rotatedPointsPolygons = [];
      let previousPts = [...points];

      for (let s = 0; s < numOfSegments; s++) {
        let oldPoints = previousPts.map(pt => pt.clone());
        let newPoints = previousPts.map(pt => pt.clone().applyAxisAngle(Z_AXIS, segmentRotation));
        previousPts = newPoints.map(pt => pt.clone());
        let pointsToGetEdges = [...oldPoints, ...newPoints];
        let outerPoints = hull(pointsToGetEdges.map(pt => [pt.x, pt.y]), Infinity);
        rotatedPointsPolygons.push(_polybooljs.default.segments({
          regions: [outerPoints],
          inverted: false
        }));
      }

      return rotatedPointsPolygons;
    }

    generateSegmentsFromPoints(pointsArr) {
      return pointsArr.reduce((allSegments, currentPoints) => [...allSegments, _polybooljs.default.segments({
        regions: [currentPoints.map(pt => [pt.x, pt.y])],
        inverted: false
      })], []);
    }

    getPolygonPoints(oldPoints, newPoints) {
      const Z_AXIS = new THREE.Vector3(0, 0, 1);
      let startPts = [...oldPoints, oldPoints[0].clone()];
      let endPts = [...newPoints, newPoints[0].clone()];
      let polyPts = [];
      let segmentPoints;

      for (let i = 0; i < startPts.length - 1; i++) {
        segmentPoints = [startPts[i], startPts[i + 1], endPts[i], endPts[i + 1]];
        segmentPoints = this.sortPoints(segmentPoints, Z_AXIS);
        polyPts.push(segmentPoints);
      }

      return polyPts;
    }

    getOuterPointsFromPointCloud(points, zAxis) {
      let linePairs = this.getOuterLinePairsFromPoints(points);
      let outerPoints = linePairs.reduce((allPts, currentPair) => {
        allPts.push(...currentPair);
        return allPts;
      }, []).reduce((filteredPts, currentPt, idx) => {
        if (idx % 2) filteredPts.push(currentPt);
        return filteredPts;
      }, []);
      outerPoints = this.sortPoints(outerPoints, zAxis);
      return outerPoints;
    }

    getOuterLinePairsFromPoints(points) {
      let facesDict = this.getFacesDictFromPoints(points);
      let linePairs = [];

      for (let [edgeIndices, facePair] of Object.entries(facesDict)) {
        if (facePair.length !== 2) {
          let verticesIdx = edgeIndices.split(",").map(val => parseInt(val));
          let vectorOne = points[verticesIdx[0]];
          let vectorTwo = points[verticesIdx[1]];
          linePairs.push([vectorOne, vectorTwo]);
        }
      }

      return linePairs;
    }

    getFacesDictFromPoints(points) {
      let faces = this.getFacesFromPoints(points);
      return new _WireframeGenerator.WireframeGenerator().getFacesDict(faces);
    }

    getFacesFromPoints(points) {
      let delaunayFaces = _delaunator.default.from(points.map(p => [p.x, p.y])).triangles;

      let faces = [];

      for (let i = 0; i < delaunayFaces.length; i += 3) faces.push({
        a: delaunayFaces[i],
        b: delaunayFaces[i + 1],
        c: delaunayFaces[i + 2]
      });

      return faces;
    }

    sortPoints(points, normal) {
      let centerPoint = this.getCenterPoint(points);

      let sortOrder = (a, b) => normal.dot(new THREE.Vector3().crossVectors(a.clone().sub(centerPoint), b.clone().sub(centerPoint)));

      points.sort(sortOrder);
      return points;
    }

    getCenterPoint(pointsArr) {
      let length = pointsArr.length;
      let centerPoint = pointsArr.reduce((finalVec, currentVec) => finalVec.add(currentVec), new THREE.Vector3()).multiplyScalar(1 / length);
      return centerPoint;
    }

  }

  _exports.PlanarRotationPointsGenerator = PlanarRotationPointsGenerator;
});