define("m08-2020/lib/StateControl/ZoomController", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ZoomController = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const DEFAULT_SCALE = 1;

  const _lineStartingRotation = new THREE.Euler();

  const _lineStartingPosition = new THREE.Vector3();

  class ZoomController {
    constructor(GraphicsThree3D) {
      _defineProperty(this, "changeTextsZoomableStatus", () => {
        this.textsZoomableStatus = !this.textsZoomableStatus;

        if (!this.textsZoomableStatus) {
          this.setTextsScale(DEFAULT_SCALE);
        } else {
          if (this.zoomScale > this.textScale) this.setTextsScale(this.zoomScale);else this.setTextsScale(this.textScale);
        }
      });

      _defineProperty(this, "updateTextsScale", scale => {
        if (!this.textsZoomableStatus || this.textScale <= ZoomController.getDefaultScaleAmount) return;
        const previousScale = this.textScale;
        this.textScale = scale;
        this.textScale = parseFloat(this.textScale.toFixed(1));

        if (this.zoomScale > previousScale) {
          this.zoomScale /= previousScale;
          this.zoomScale *= this.textScale;
          this.setTextsScale(this.zoomScale);
          return;
        }

        this.setTextsScale(this.textScale);
      });

      _defineProperty(this, "setTextsScale", scale => {
        this.drawingTexts.forEach(item => {
          item.scale.set(scale, scale, 1);
        });
      });

      _defineProperty(this, "getDrawingTexts", () => {
        this.GraphicsThree3D.ThreeObjects.forEach(item => {
          item.sceneObject.traverse(object => {
            if (object.geometry && object.geometry.type === 'TextBufferGeometry') {
              this.drawingTexts.push(object);
            }
          });
        });
      });

      this.GraphicsThree3D = GraphicsThree3D;
      this.controls = GraphicsThree3D.controls;
      this.camera = GraphicsThree3D.camera;
      this.drawingObjectWireframesSolid = [];
      this.textsZoomableStatus = true;
      this.drawingTexts = [];
      this.textScale = DEFAULT_SCALE;
      this.zoomScale = DEFAULT_SCALE; ///

      this.controls.addEventListener("change", this.zoomDrawingObjectSolidLines()); ///
    }

    static get getDefaultScaleAmount() {
      return 0.1;
    }

    zoomDrawingObjectSolidLines() {
      return () => this.updateScale();
    }

    resetScale() {
      this.updateLinesScale(DEFAULT_SCALE);
      this.updateTextsScale(DEFAULT_SCALE);
    }

    updateScale() {
      if (this.camera.zoom < 1) {
        let scaleFactor = 1 / this.camera.zoom;
        this.updateLinesScale(scaleFactor);
        this.zoomScale = this.textScale * scaleFactor;
        if (this.textsZoomableStatus) this.setTextsScale(this.zoomScale);
      }
    }

    updateLinesScale(scaleFactor) {
      this.drawingObjectWireframesSolid.forEach(lineData => this.lineScaleUpdate(lineData, scaleFactor));
    }

    lineScaleUpdate(lineData, scaleFactor) {
      _lineStartingRotation.copy(lineData.line.rotation);

      _lineStartingPosition.copy(lineData.line.position);

      lineData.line.position.sub(lineData.line.position);
      lineData.line.setRotationFromEuler(lineData.eulerOne);
      lineData.line.scale.set(scaleFactor, scaleFactor, 1);
      lineData.line.setRotationFromEuler(_lineStartingRotation);
      lineData.line.position.add(_lineStartingPosition);
    }

    getDrawingObjectWireframes(object) {
      const Z_AXIS = new THREE.Vector3(0, 0, 1);
      object.children.forEach(child => {
        // TODO: Add support for buffergeometries
        if (child.isLine && child.needsZoomControl) {
          let ptOne = child.pointsStartEnd.startPoint;
          let ptTwo = child.pointsStartEnd.endPoint;
          let lineDirection = new THREE.Vector3().copy(ptTwo).sub(ptOne).normalize();
          let quaternion = new THREE.Quaternion().setFromUnitVectors(lineDirection, Z_AXIS);
          let quaternionConjugate = new THREE.Quaternion().setFromUnitVectors(Z_AXIS, lineDirection);
          let eulerOne = new THREE.Euler().setFromQuaternion(quaternion);
          let eulerTwo = new THREE.Euler().setFromQuaternion(quaternionConjugate);
          this.drawingObjectWireframesSolid.push({
            line: child,
            eulerOne,
            eulerTwo
          });
        }
      });
    }

    clearZoomState() {
      this.drawingObjectWireframesSolid = [];
      this.drawingTexts = [];
    }

  }

  _exports.ZoomController = ZoomController;
});