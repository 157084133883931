define("m08-2020/lib/ThreeExtensions/CustomEllipseCurve", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CustomEllipseCurve = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CustomEllipseCurve extends THREE.EllipseCurve {
    constructor(aX, aY, xRadius, yRadius, aStartAngle, aEndAngle, aClockwise, aRotation, rotationPitch) {
      super(aX, aY, xRadius, yRadius, aStartAngle, aEndAngle, aClockwise, aRotation);

      _defineProperty(this, "getPoint", function (t) {
        // this is modified version of three js curve.getPoint to return vector3 instead of vector2;
        const point = new THREE.Vector3();
        const twoPi = Math.PI * 2;
        let deltaAngle = this.aEndAngle - this.aStartAngle;
        const samePoints = Math.abs(deltaAngle) < Number.EPSILON; // ensures that deltaAngle is 0 .. 2 PI

        while (deltaAngle < 0) deltaAngle += twoPi;

        while (deltaAngle > twoPi) deltaAngle -= twoPi;

        if (deltaAngle < Number.EPSILON) {
          if (samePoints) {
            deltaAngle = 0;
          } else {
            deltaAngle = twoPi;
          }
        }

        if (this.aClockwise === true && !samePoints) {
          if (deltaAngle === twoPi) {
            deltaAngle = -twoPi;
          } else {
            deltaAngle = deltaAngle - twoPi;
          }
        }

        const angle = this.aStartAngle + t * deltaAngle;
        let x = this.aX + this.xRadius * Math.cos(angle);
        let y = this.aY + this.yRadius * Math.sin(angle);

        if (this.aRotation !== 0) {
          const cos = Math.cos(this.aRotation);
          const sin = Math.sin(this.aRotation);
          const tx = x - this.aX;
          const ty = y - this.aY; // Rotate the point about the center of the ellipse.

          x = tx * cos - ty * sin + this.aX;
          y = tx * sin + ty * cos + this.aY;
        }

        return point.set(x, -t * this.rotationPitch, y);
      });

      _defineProperty(this, "getTangent", function (t) {
        // this is modified version of three js getTangent to modify tangent vector;
        const delta = 0.0001;
        let t1 = t - delta;
        let t2 = t + delta; // Capping in case of danger

        if (t1 < 0) t1 = 0;
        if (t2 > 1) t2 = 1;
        const pt1 = this.getPoint(t1);
        const pt2 = this.getPoint(t2);
        const tangent = new THREE.Vector3();
        tangent.copy(pt2).add(new THREE.Vector3(0, this.rotationPitch * delta, 0)).sub(pt1).normalize();
        return tangent;
      });

      _defineProperty(this, "computeFrenetFrames", function (segments, closed) {
        // this is modified version of three js computeFrenetFrames to modify normal vector;
        // see http://www.cs.indiana.edu/pub/techreports/TR425.pdf
        const normal = new THREE.Vector3();
        const tangents = [];
        const normals = [];
        const binormals = [];
        const vec = new THREE.Vector3();
        const mat = new THREE.Matrix4(); // compute the tangent vectors for each segment on the curve

        for (let i = 0; i <= segments; i++) {
          const u = i / segments;
          tangents[i] = this.getTangentAt(u, new THREE.Vector3());
          tangents[i].normalize();
        } // select an initial normal vector perpendicular to the first tangent vector,
        // and in the direction of the minimum tangent xyz component


        normals[0] = new THREE.Vector3();
        binormals[0] = new THREE.Vector3();
        let min = Number.MAX_VALUE;
        const tx = Math.abs(tangents[0].x);
        const ty = Math.abs(tangents[0].y);
        const tz = Math.abs(tangents[0].z);

        if (tx <= min) {
          min = tx;
          normal.set(1, 0, 0);
        }

        if (ty <= min) {
          min = ty;
          normal.set(0, 1, 0);
        }

        if (tz <= min) {
          normal.set(0, 0, 1);
        }

        vec.crossVectors(tangents[0], normal).normalize();
        normals[0].crossVectors(tangents[0], vec);
        binormals[0].crossVectors(tangents[0], normals[0]); // compute the slowly-varying normal and binormal vectors for each segment on the curve

        for (let i = 1; i <= segments; i++) {
          normals[i] = normals[i - 1].clone();
          binormals[i] = binormals[i - 1].clone();
          vec.crossVectors(tangents[i - 1], tangents[i]);

          if (vec.length() > Number.EPSILON) {
            vec.normalize();
            const theta = Math.acos(THREE.MathUtils.clamp(tangents[i - 1].dot(tangents[i]), -1, 1)); // clamp for floating pt errors

            normals[i].applyAxisAngle(new THREE.Vector3(0, 1, 0), theta);
          }

          binormals[i].crossVectors(tangents[i], normals[i]);
        } // if the curve is closed, postprocess the vectors so the first and last normal vectors are the same


        if (closed === true) {
          let theta = Math.acos(THREE.MathUtils.clamp(normals[0].dot(normals[segments]), -1, 1));
          theta /= segments;

          if (tangents[0].dot(vec.crossVectors(normals[0], normals[segments])) > 0) {
            theta = -theta;
          }

          for (let i = 1; i <= segments; i++) {
            // twist a little...
            normals[i].applyMatrix4(mat.makeRotationAxis(tangents[i], theta * i));
            binormals[i].crossVectors(tangents[i], normals[i]);
          }
        }

        return {
          tangents: tangents,
          normals: normals,
          binormals: binormals
        };
      });

      this.rotationPitch = rotationPitch;
    }

  }

  _exports.CustomEllipseCurve = CustomEllipseCurve;
});