define("m08-2020/lib old/ThreeExtensions/OutlinesGeometry", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OutlinesGeometry = void 0;

  class OutlinesGeometry extends THREE.Geometry {
    constructor(geometry) {
      super();
      this.faceSplitFailed = false;
      this.initGeometry(geometry);
    }

    initGeometry(geometry) {
      let geometry2 = this.getGeometry(geometry);
      this.vertices = geometry2.vertices;
      this.faces = geometry2.faces;
      this.splitFaces();
    }

    splitFaces() {
      const DELTA = 1e-5;
      const keys = ['a', 'b', 'c'];
      let vertices = this.vertices;
      let faces = this.faces;
      let vertexDict = vertices.reduce((dictX, vertex, index) => {
        if (vertex.x in dictX) dictX[vertex.x].push({
          vertex,
          index
        });else {
          dictX[vertex.x] = [];
          dictX[vertex.x].push({
            vertex,
            index
          });
        }
        return dictX;
      }, {}); // for ( let keyX of Object.keys( vertexDict ) ) {
      //   vertexDict[ keyX ] = vertexDict[ keyX ].reduce((dictY, vertexData) => {
      //     if ( vertexData.vertex.y in dictY ) dictY[ vertexData.vertex.y ].push( vertexData );
      //     else { dictY[ vertexData.vertex.y ] = []; dictY[ vertexData.vertex.y ].push( vertexData ) }
      //     return dictY;
      //   }, {});
      //   for ( let keyY of Object.keys( vertexDict[ keyX ] ) ) {
      //     vertexDict[ keyX ][ keyY ] = vertexDict[ keyX ][ keyY ].reduce((dictZ, vertexData) => {
      //       if ( vertexData.vertex.z in dictZ ) dictZ[ vertexData.vertex.z ].push( vertexData );
      //       else { dictZ[ vertexData.vertex.z ] = []; dictZ[ vertexData.vertex.z ].push( vertexData ) }
      //       return dictZ;
      //     }, {});
      //   }
      // }
      // let sortedVertices = vertices
      //                     .map(( vertex, index ) => {
      //                       return { vertex, index };
      //                     })
      //                     .sort( ( a, b ) => a.vertex.x - b.vertex.x );

      let sortedKeysX = Object.keys(vertexDict).sort((a, b) => parseFloat(a) - parseFloat(b));
      let edge = [0, 0],
          face;

      for (let i = 0; i < faces.length; i++) {
        face = faces[i];

        EDGES_LOOP: for (let j = 0; j < 3; j++) {
          edge[0] = face[keys[j]];
          edge[1] = face[keys[(j + 1) % 3]];
          let vertexOne = vertices[edge[0]];
          let vertexTwo = vertices[edge[1]];
          let lowerBound = findBoundIndex(vertexOne.x, sortedKeysX);
          let upperBound = findBoundIndex(vertexTwo.x, sortedKeysX);
          if (lowerBound > upperBound) [lowerBound, upperBound] = [upperBound, lowerBound];
          lowerBound = Math.max(lowerBound - 2, 0);
          upperBound = Math.min(upperBound + 2, sortedKeysX.length - 1);
          let line = new THREE.Line3(vertexOne, vertexTwo); // for each vertex checks if it lies in the edge

          for (let e = lowerBound; e < upperBound; e++) {
            let keyX = sortedKeysX[e];

            for (let vertexData of vertexDict[keyX]) {
              let i = vertexData.index;
              if (i === edge[0] || i === edge[1]) continue;
              let v = vertexData.vertex;
              let closestPoint = line.closestPointToPoint(v, true, new THREE.Vector3());

              if (closestPoint.distanceTo(v) < DELTA && v.distanceTo(line.start) > DELTA && v.distanceTo(line.end) > DELTA) {
                // mark the current face as splitted so that his cords won't be considered
                face.splitted = true; // Add two new faces, created splitting the face in two

                let faceOne = new THREE.Face3(i, face[keys[(j + 2) % 3]], face[keys[j % 3]], face.normal, face.color, face.materialIndex);
                let faceTwo = new THREE.Face3(i, face[keys[(j + 2) % 3]], face[keys[(j + 1) % 3]], face.normal, face.color, face.materialIndex);
                faces.push(faceOne);
                faces.push(faceTwo);
                break EDGES_LOOP;
              }
            }
          }

          if (face.splitted) break;
        }
      }
    }

    getGeometry(geometry) {
      let geometry2;
      if (geometry.isBufferGeometry) geometry2 = new THREE.Geometry().fromBufferGeometry(geometry);else geometry2 = geometry.clone();
      geometry2.mergeVertices();
      geometry2.computeFaceNormals();
      return geometry2;
    }

  }

  _exports.OutlinesGeometry = OutlinesGeometry;

  function findBoundIndex(coord, array) {
    let min = 0;
    let max = array.length - 1;

    while (min <= max) {
      let mid = Math.floor((min + max) / 2);
      if (array[mid] == coord) return mid;else if (parseFloat(array[mid]) > parseFloat(coord)) max = mid - 1;else min = mid + 1;
    }

    return 0;
  }
});