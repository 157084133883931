define("m08-2020/lib old/ThreeExtensions/RotatedGeometry", ["exports", "three", "three/src/extras/Earcut", "m08-2020/lib old/Utils"], function (_exports, THREE, _Earcut, _Utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RotatedGeometry = void 0;
  const utils = new _Utils.Utils(THREE);

  class RotatedGeometry extends THREE.Geometry {
    constructor(points, rotationData) {
      super();
      this.generateSelf(points, rotationData);
    }

    generateSelf(points, rotationData) {
      const DETAIL = 64;
      const NUM_OF_POINTS = points.length;
      this.reveresePointsIfNeeded(points, rotationData);
      let {
        rotationPoint,
        rotationAxis,
        rotationAngle,
        rotationPitch
      } = rotationData;
      let numOfSegments = DETAIL * Math.abs(rotationAngle) / (2 * Math.PI);
      this.vertices.push(...points);
      let orientationFront = getOrientation(points, rotationAxis, rotationPoint);
      let crossVecFront = getCrossProduct(points, orientationFront);
      let pitchVector = new THREE.Vector3();

      for (let i = 0; i < numOfSegments; i++) {
        for (let point of points) {
          let rotatedPoint = new THREE.Vector3().copy(point).sub(rotationPoint).applyAxisAngle(rotationAxis, (i + 1) * rotationAngle / numOfSegments).add(rotationPoint).add(pitchVector.copy(rotationAxis).multiplyScalar((i + 1) * rotationPitch / numOfSegments / 2));
          this.vertices.push(rotatedPoint);
        }
      }

      let frontFaces = triangulatePoints3D(points, crossVecFront);
      let backIndexStart = this.vertices.length - NUM_OF_POINTS;
      let backPoints = this.vertices.slice(backIndexStart);
      let backIndexMap = {};

      for (let i = 0; i < backPoints.length; i++) backIndexMap[i] = backIndexStart + i;

      let orientationBack = getOrientation(backPoints, rotationAxis, rotationPoint);
      let crossVecBack = getCrossProduct(backPoints, orientationBack);
      let backFaces = triangulatePoints3D(backPoints, crossVecBack);
      if (crossVecFront.dot(crossVecBack) > 0) backFaces.reverse();

      if (!pointsAreSameFrontBack(points, backPoints)) {
        // Front
        for (let i = 0, l = frontFaces.length; i < l; i += 3) {
          this.faces.push(new THREE.Face3(frontFaces[i], frontFaces[i + 1], frontFaces[i + 2]));
          addUV(this.faceVertexUvs[0]);
        } // Back


        for (let i = 0, l = backFaces.length; i < l; i += 3) {
          this.faces.push(new THREE.Face3(backIndexMap[backFaces[i]], backIndexMap[backFaces[i + 1]], backIndexMap[backFaces[i + 2]]));
          addUV(this.faceVertexUvs[0]);
        }
      } // Side


      for (let s = 0; s < numOfSegments; s++) {
        let offsetFromStart = s * NUM_OF_POINTS;

        for (let i = 0; i < NUM_OF_POINTS; i++) {
          let correctedIndexBack = i === NUM_OF_POINTS - 1 ? NUM_OF_POINTS : i + NUM_OF_POINTS + 1; // Bad naming/style?

          let correctedIndexFront = i === NUM_OF_POINTS - 1 ? 0 : i + 1;
          this.faces.push(new THREE.Face3(i + offsetFromStart, i + NUM_OF_POINTS + offsetFromStart, correctedIndexBack + offsetFromStart));
          this.faces.push(new THREE.Face3(i + offsetFromStart, correctedIndexBack + offsetFromStart, correctedIndexFront + offsetFromStart));
          addUV(this.faceVertexUvs[0]);
          addUV(this.faceVertexUvs[0]);
        }
      }

      this.computeFaceNormals();
      this.computeVertexNormals();
    }

    reveresePointsIfNeeded(points, rotationData) {
      let {
        rotationPoint,
        rotationAxis,
        rotationAngle
      } = rotationData;
      let orientation = getOrientation(points, rotationAxis, rotationPoint);
      let crossVec = getCrossProduct(points, orientation);
      let extrusionDir = getExtrusionDirection(points[0], rotationPoint, rotationAxis, Math.sign(rotationAngle));
      if (extrusionDir.dot(crossVec) < 0) points.reverse();
    }

  } // Helper functions


  _exports.RotatedGeometry = RotatedGeometry;

  function triangulatePoints3D(points, surfaceNormal) {
    const Z_AXIS = new THREE.Vector3(0, 0, 1);
    const QUATERNION = new THREE.Quaternion().setFromUnitVectors(surfaceNormal, Z_AXIS);
    let dummyVec = new THREE.Vector3();
    let pointsFlat = points.reduce((ptArr, pt) => {
      dummyVec.copy(pt);
      dummyVec.applyQuaternion(QUATERNION);
      ptArr.push(dummyVec.x, dummyVec.y, dummyVec.z);
      return ptArr;
    }, []);

    let faces = _Earcut.Earcut.triangulate(pointsFlat, [], 3);

    if (surfaceNormal.dot(Z_AXIS) < 0) faces.reverse();
    return faces;
  }

  function pointsAreSameFrontBack(frontPts, backPts) {
    let allPointsAreEqual = true;

    for (let i = 0; i < frontPts.length; i++) {
      if (!utils.vectorsAreEqual(frontPts[i], backPts[i])) allPointsAreEqual = false;
    }

    return allPointsAreEqual;
  }

  function getExtrusionDirection(referencePoint, rotationPoint, rotationAxis, rotationSign) {
    let rotatedPoint = new THREE.Vector3().copy(referencePoint).sub(rotationPoint).applyAxisAngle(rotationAxis, rotationSign * 0.1).add(rotationPoint);
    return new THREE.Vector3().subVectors(rotatedPoint, referencePoint);
  }

  function getOrientation(points, rotationAxis, rotationPoint) {
    let orientation = {
      xAxis: getAxisX(points, rotationAxis, rotationPoint),
      yAxis: rotationAxis
    };
    orientation.zAxis = new THREE.Vector3().crossVectors(orientation.xAxis, orientation.yAxis);
    return orientation;
  }

  function getAxisX(points, rotationAxis, rotationPoint) {
    let midVector = getMidVector(points);
    let surfaceNormal = utils.getCrossVectorFromPoints(points);
    let rotationPointCorrected = rotationPoint.clone().sub(midVector);
    let rotPointOnSurface = rotationPointCorrected.clone().projectOnPlane(surfaceNormal);
    let rotPointOnAxis = rotPointOnSurface.clone().projectOnVector(rotationAxis);
    return rotPointOnSurface.sub(rotPointOnAxis).normalize();
  }

  function getMidVector(pts) {
    let maxX = -Infinity;
    let maxY = -Infinity;
    let maxZ = -Infinity;
    let minX = Infinity;
    let minY = Infinity;
    let minZ = Infinity;
    pts.forEach(point => {
      if (point.x > maxX) maxX = point.x;
      if (point.y > maxY) maxY = point.y;
      if (point.z > maxZ) maxZ = point.z;
      if (point.x < minX) minX = point.x;
      if (point.y < minY) minY = point.y;
      if (point.z < minZ) minZ = point.z;
    });
    let midX = minX + (maxX - minX) / 2;
    let midY = minY + (maxY - minY) / 2;
    let midZ = minZ + (maxZ - minZ) / 2;
    let midVector = new THREE.Vector3(midX, midY, midZ);
    return midVector;
  }

  function getCrossProduct(points, orientation) {
    let {
      xAxis,
      yAxis,
      zAxis
    } = orientation;
    let ptA = new THREE.Vector3(0, 0, 0);
    let minY = Infinity;
    let maxX = -Infinity;
    let idxA = 0;
    let zProjection = new THREE.Vector3().copy(points[0]).projectOnVector(zAxis);
    points.forEach((point, idx) => {
      let projectedPoint = point.clone().sub(zProjection);
      let projectedX = projectedPoint.dot(xAxis);
      let projectedY = projectedPoint.dot(yAxis);

      if (projectedY <= minY && projectedX >= maxX) {
        minY = projectedY;
        maxX = projectedX;
        ptA.copy(projectedPoint);
        idxA = idx;
      }
    });
    let idxB = idxA === 0 ? points.length - 1 : idxA - 1;
    let idxC = idxA === points.length - 1 ? 0 : idxA + 1;
    let vecAB = new THREE.Vector3().subVectors(points[idxB], points[idxA]);
    let vecAC = new THREE.Vector3().subVectors(points[idxC], points[idxA]);
    return vecAB.cross(vecAC);
  }

  function addUV(UVs) {
    // TEMP Func
    UVs.push([new THREE.Vector2(0, 0), new THREE.Vector2(0, 0), new THREE.Vector2(0, 0)]);
  }
});