define("m08-2020/lib/ThreeExtensions/VectorString", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VectorString = void 0;

  class VectorString extends THREE.Vector3 {
    constructor() {
      super();
    }

    convertToString() {
      return `${this.x},${this.y},${this.z}`;
    }

    setFromString(string) {
      let elems = string.split(",");
      this.set(+elems[0], +elems[1], +elems[2]);
    }

    static vecToString(vec) {
      return `${vec.x},${vec.y},${vec.z}`;
    }

    static generateVectorsFromString(string) {
      const STEP_SIZE = 3;
      let vectors = [];
      let elems = string.split(",");

      for (let i = 0; i < elems.length; i += STEP_SIZE) {
        let vec = new VectorString().setFromString(elems.slice(i, i + STEP_SIZE).join(","));
        vectors.push(vec);
      }

      return vectors;
    }

    static generateStringFromVertices(vertices) {
      return vertices.reduce((arr, vec) => {
        arr.push(vec.x, vec.y, vec.z);
        return arr;
      }, []).join(",");
    }

  }

  _exports.VectorString = VectorString;
});