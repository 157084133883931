define("m08-2020/lib/ThreeExtensions/PlanarExtrusionPointsGenerator", ["exports", "three", "polybooljs", "poly-decomp", "hull.js"], function (_exports, THREE, _polybooljs, PolyDecomp, hull) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PlanarExtrusionPointsGenerator = void 0;

  class PlanarExtrusionPointsGenerator {
    constructor(points2D, extrusionVector2D) {
      this.extrudedPoints = [];
      this.generateExtrudedPoints(points2D, extrusionVector2D);
    }

    generateExtrudedPoints(points2D, extrusionVector2D) {
      let polyPts2D = points2D.map(pt => [pt.x, pt.y]);
      PolyDecomp.makeCCW(polyPts2D);
      let convexPolygons = PolyDecomp.quickDecomp(polyPts2D);
      let polygonSegments = this.generatePolygonSegments(convexPolygons, extrusionVector2D);
      let finalPolygon = this.uniteSegmentsAndReturnPolygon(polygonSegments);
      let finalPoints = this.getPointsFromFinalPolygon(finalPolygon);
      this.extrudedPoints.push(...finalPoints);
    }

    getPointsFromFinalPolygon(finalPolygon) {
      return finalPolygon.regions[0].map(pt => new THREE.Vector3(pt[0], pt[1], 0));
    }

    uniteSegmentsAndReturnPolygon(segments) {
      let finalSegments = segments[0];

      for (let i = 1; i < segments.length; i++) {
        let comb = _polybooljs.default.combine(finalSegments, segments[i]);

        finalSegments = _polybooljs.default.selectUnion(comb);
      }

      return _polybooljs.default.polygon(finalSegments);
    }

    generatePolygonSegments(convexPolygons, extrusionVector2D) {
      let polygonSegments = [];
      convexPolygons.forEach(polygon => {
        let polyPts = polygon.map(pt => new THREE.Vector3(pt[0], pt[1], 0));
        let polyPtsExtr = polyPts.map(pt => pt.clone().add(extrusionVector2D));
        let pointsToGetEdges = [...polyPts, ...polyPtsExtr];
        let outerPoints = hull(pointsToGetEdges.map(pt => [pt.x, pt.y]), Infinity);
        polygonSegments.push(_polybooljs.default.segments({
          regions: [outerPoints],
          inverted: false
        }));
      });
      return polygonSegments;
    }

    generateSegmentsFromPoints(pointsArr) {
      return pointsArr.reduce((allSegments, currentPoints) => [...allSegments, _polybooljs.default.segments({
        regions: [currentPoints.map(pt => [pt.x, pt.y])],
        inverted: false
      })], []);
    }

    getPolygonPoints(oldPoints, newPoints) {
      const Z_AXIS = new THREE.Vector3(0, 0, 1);
      let startPts = [...oldPoints, oldPoints[0].clone()];
      let endPts = [...newPoints, newPoints[0].clone()];
      let polyPts = [];
      let segmentPoints;

      for (let i = 0; i < startPts.length - 1; i++) {
        segmentPoints = [startPts[i], startPts[i + 1], endPts[i], endPts[i + 1]];
        segmentPoints = this.sortPoints(segmentPoints, Z_AXIS);
        polyPts.push(segmentPoints);
      }

      return polyPts;
    }

    sortPoints(points, normal) {
      let centerPoint = this.getCenterPoint(points);

      let sortOrder = (a, b) => normal.dot(new THREE.Vector3().crossVectors(a.clone().sub(centerPoint), b.clone().sub(centerPoint)));

      points.sort(sortOrder);
      return points;
    }

    getCenterPoint(pointsArr) {
      let length = pointsArr.length;
      let centerPoint = pointsArr.reduce((finalVec, currentVec) => finalVec.add(currentVec), new THREE.Vector3()).multiplyScalar(1 / length);
      return centerPoint;
    }

  }

  _exports.PlanarExtrusionPointsGenerator = PlanarExtrusionPointsGenerator;
});