define("m08-2020/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    beforeModel() {
      this.intl.setLocale(['de']);
    },

    model: function () {
      var self = this;
      return Ember.RSVP.hash({
        application: self.store.findAll('application')
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      setTimeout(function () {
        console.log('setupController');
        scene.set3DSceneSize(); // ./vendor/scenesizemanagement/scenesizemanagement.js
      }, 400);
      $(window).resize(function () {
        scene.set3DSceneSize(); // ./vendor/scenesizemanagement/scenesizemanagement.js
      });
    }

  });

  _exports.default = _default;
});