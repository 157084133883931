define("m08-2020/lib old/ClippingUtils/OutlineGenerator", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OutlineGenerator = void 0;
  const TOLERANCE = 0.01;

  class OutlineGenerator {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.a = new THREE.Vector3();
      this.b = new THREE.Vector3();
      this.c = new THREE.Vector3();
      this.planePointA = new THREE.Vector3();
      this.planePointB = new THREE.Vector3();
      this.planePointC = new THREE.Vector3();
      this.lineAB = new THREE.Line3(), this.lineBC = new THREE.Line3(), this.lineCA = new THREE.Line3();
      this.pointOfIntersection = new THREE.Vector3();
      this.pointsOfIntersection = new THREE.Geometry();
    }

    drawStencilOutline(obj, mathPlane, lineProps) {
      let self = this;
      obj.geometry.faces.forEach(function (face, idx) {
        obj.localToWorld(self.a.copy(obj.geometry.vertices[face.a]));
        obj.localToWorld(self.b.copy(obj.geometry.vertices[face.b]));
        obj.localToWorld(self.c.copy(obj.geometry.vertices[face.c]));
        self.lineAB = new THREE.Line3(self.a, self.b);
        self.lineBC = new THREE.Line3(self.b, self.c);
        self.lineCA = new THREE.Line3(self.c, self.a);
        self.setPointOfIntersection(self.lineAB, mathPlane, idx);
        self.setPointOfIntersection(self.lineBC, mathPlane, idx);
        self.setPointOfIntersection(self.lineCA, mathPlane, idx);
      });
      let lines = [];
      let vertices = this.pointsOfIntersection.vertices;
      let facesDict = this.getFaceIndexMap(vertices);
      Object.values(facesDict).forEach(val => {
        if (val.length > 1) {
          let line = this.GraphicsThree3D.drawLine(val[0], val[1], lineProps);
          lines.push(line);
        }
      });
      return lines;
    }

    getFaceIndexMap(vertices) {
      let dict = {};
      vertices.forEach(vertex => {
        if (dict.hasOwnProperty(vertex.faceIndex)) dict[vertex.faceIndex].push(vertex);else dict[vertex.faceIndex] = [vertex];
      });
      return dict;
    }

    setPointOfIntersection(line, plane, faceIdx) {
      this.pointOfIntersection = plane.intersectLine(line, new THREE.Vector3());

      if (this.pointOfIntersection && !line.start.equals(this.pointOfIntersection)) {
        let p = this.pointOfIntersection.clone();
        p.faceIndex = faceIdx;
        p.checked = false;
        this.pointsOfIntersection.vertices.push(p);
      }
    }

    getContours(points, contours) {
      let contour = []; // find first line for the contour

      let firstPointIndex = 0;
      let secondPointIndex = 0;
      let firstPoint, secondPoint;

      for (let i = 0; i < points.length; i++) {
        if (points[i].checked == true) continue;
        firstPointIndex = i;
        firstPoint = points[firstPointIndex];
        firstPoint.checked = true;
        secondPointIndex = this.getPairIndex(firstPoint, firstPointIndex, points);
        secondPoint = points[secondPointIndex];
        secondPoint.checked = true;
        contour.push(firstPoint.clone());
        contour.push(secondPoint.clone());
        break;
      }

      contour = this.getContour(secondPoint, points, contour);
      contours.push(contour);
      let allChecked = 0;
      points.forEach(p => {
        allChecked += p.checked == true ? 1 : 0;
      });

      if (allChecked != points.length) {
        return this.getContours(points, contours);
      }

      return contours;
    }

    getContour(currentPoint, points, contour) {
      let p1Index = this.getNearestPointIndex(currentPoint, points);
      let p1 = points[p1Index];
      p1.checked = true;
      let p2Index = this.getPairIndex(p1, p1Index, points);
      let p2 = points[p2Index];
      p2.checked = true;
      let isClosed = p2.equals(contour[0], TOLERANCE);

      if (!isClosed) {
        contour.push(p2.clone());
        return this.getContour(p2, points, contour);
      } else {
        contour.push(contour[0].clone());
        return contour;
      }
    }

    getNearestPointIndex(point, points) {
      let index = 0;

      for (let i = 0; i < points.length; i++) {
        let p = points[i];

        if (p.checked == false && p.equals(point, TOLERANCE)) {
          index = i;
          break;
        }
      }

      return index;
    }

    getPairIndex(point, pointIndex, points) {
      let index = 0;

      for (let i = 0; i < points.length; i++) {
        let p = points[i];

        if (i != pointIndex && p.checked == false && p.faceIndex == point.faceIndex) {
          index = i;
          break;
        }
      }

      return index;
    }

    clearPointsOfIntersection() {
      this.pointOfIntersection = new THREE.Vector3();
      this.pointsOfIntersection = new THREE.Geometry();
    }

  }

  _exports.OutlineGenerator = OutlineGenerator;
});