define("m08-2020/lib/ClippingUtils/DynamicSectionalPlane", ["exports", "three", "m08-2020/lib/Utils"], function (_exports, THREE, _Utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DynamicSectionalPlane = void 0;
  const utils = new _Utils.Utils(THREE);
  const CHANGE_EVENT = new Event("change");

  class DynamicSectionalPlane {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.sectionalPlanes = [];
      this.renderOrder = 1;
    }

    applySectionalPlanes() {
      console.log("***** Hier geths lang *****");
      let controllerContainers = document.getElementById("sectional-plane-controllers");
      console.log(document.getElementById("sectional-plane-controllers"));
      console.log(this.sectionalPlanes);

      for (let planeData of this.sectionalPlanes) {
        console.log(planeData.targets);
        let targets = planeData.targets;
        this.clipDrawingObjects(planeData, targets.drawingObjects);
        this.clipObject3D(planeData, targets.fasteners);
        this.clipObject3D(planeData, targets.dimensionalChains);
        this.clipObject3D(planeData, targets.arrows);
        if (!this.GraphicsThree3D.dynamicCutApplied) this.addPlaneToGUI(planeData, controllerContainers);else this.updatePlaneGUI(planeData);
      }

      this.GraphicsThree3D.dynamicCutApplied = true;
    } // Clipping helper functions -->


    clipDrawingObjects(planeData, drawingObjects) {
      for (let {
        object,
        clippingPlanes
      } of drawingObjects) {
        let targetClippingPlanes = this.sectionalPlanes.filter(plane => clippingPlanes.includes(plane.id));
        this.clipMesh(planeData, object, targetClippingPlanes);
      }
    }

    clipObject3D(planeData, objectsData) {
      for (let {
        object,
        clippingPlanes
      } of objectsData) {
        let targetClippingPlanes = this.sectionalPlanes.filter(plane => clippingPlanes.includes(plane.id));
        object.traverse(child => {
          if (child.type === "Mesh" || child.type === "LineLoop" || child.type === "Sprite") this.clipMesh(planeData, child, targetClippingPlanes);
        });
      }
    } // Clipping helper functions <--


    initSectionalPlanes(dynamicSectionalPlanes) {
      for (let dynamicSectionalPlane of dynamicSectionalPlanes) {
        let planeData = dynamicSectionalPlane.planeData;
        let sectionalPlaneData = {
          clippingPlane: this.makePlane(planeData),
          positionVector: utils.initVector3(planeData.positionVector),
          id: planeData.id,
          range: planeData.range,
          factor: dynamicSectionalPlane.factor,
          stencilPlanes: [],
          targets: {
            drawingObjects: [],
            fasteners: [],
            dimensionalChains: [],
            arrows: []
          }
        };
        this.sectionalPlanes.push(sectionalPlaneData);
      }
    }

    updatePlaneGUI(planeData) {
      const self = this;
      const clippingPlane = planeData.clippingPlane;
      const ID = planeData.id;
      replaceVisibilityController();
      replaceInversionController();
      replacePositionController();

      function replaceVisibilityController() {
        const visibilityController_Old = document.getElementById(`show-cut-${ID}`);
        const visibilityController_New = visibilityController_Old.cloneNode(true);
        visibilityController_Old.parentNode.replaceChild(visibilityController_New, visibilityController_Old);
        visibilityController_New.addEventListener("change", ev => self.togglePlaneVisibility(planeData, clippingPlane, ev.target.checked));
        if (!visibilityController_New.checked) visibilityController_New.dispatchEvent(CHANGE_EVENT);
      }

      function replaceInversionController() {
        const invertController_Old = document.getElementById(`invert-cut-${ID}`);
        const invertController_New = invertController_Old.cloneNode(true);
        invertController_Old.parentNode.replaceChild(invertController_New, invertController_Old);
        invertController_New.addEventListener("change", () => clippingPlane.negate());
        if (invertController_New.checked) invertController_New.dispatchEvent(CHANGE_EVENT);
      }

      function replacePositionController() {
        const positionController_Old = document.getElementById(`cut-position-changer-${ID}`);
        const positionController_New = positionController_Old.cloneNode(true);
        positionController_Old.parentNode.replaceChild(positionController_New, positionController_Old);
        let baseConstant = clippingPlane.constant;
        positionController_New.addEventListener("input", ev => {
          clippingPlane.constant = baseConstant + parseInt(ev.target.value);
          self.moveStencilPlanes(planeData);
        });
      }
    }

    addPlaneToGUI(planeData, controllerContainers) {
      const self = this;
      const clippingPlane = planeData.clippingPlane;
      const ID = planeData.id;
      let container = document.createElement("li");
      [makeId(), makeCutVisibilityChanger(), makeCutDirectionChanger(), makeCutPositionChanger()].forEach(elem => container.appendChild(elem));
      controllerContainers.appendChild(container); // Helper functions -->

      function makeId() {
        let idElem = document.createElement("span");
        idElem.textContent = ID;
        return idElem;
      }

      function makeCutVisibilityChanger() {
        let wrapper = document.createElement("div");
        wrapper.className = "cut-controller";
        let input = document.createElement("input");
        input.type = "checkbox";
        input.className = "checkbox-button";
        input.name = "show-cut-" + ID;
        input.id = "show-cut-" + ID;
        input.checked = true;
        input.addEventListener("change", ev => self.togglePlaneVisibility(planeData, clippingPlane, ev.target.checked));
        let label = document.createElement("label");
        label.htmlFor = "show-cut-" + ID;
        label.textContent = "Show";
        wrapper.appendChild(input);
        wrapper.appendChild(label);
        return wrapper;
      }

      function makeCutDirectionChanger() {
        let wrapper = document.createElement("div");
        wrapper.className = "cut-controller";
        let input = document.createElement("input");
        input.type = "checkbox";
        input.className = "checkbox-button";
        input.name = "invert-cut-" + ID;
        input.id = "invert-cut-" + ID;
        input.addEventListener("change", () => clippingPlane.negate());
        let label = document.createElement("label");
        label.htmlFor = "invert-cut-" + ID;
        label.textContent = "Invert";
        wrapper.appendChild(input);
        wrapper.appendChild(label);
        return wrapper;
      }

      function makeCutPositionChanger() {
        let wrapper = document.createElement("div");
        wrapper.className = "cut-controller";
        let input = document.createElement("input");
        input.type = "range";
        input.id = "cut-position-changer-" + ID;
        let baseConstant = clippingPlane.constant;
        input.min = -planeData.range;
        input.max = planeData.range;
        input.value = 0;
        input.addEventListener("input", ev => {
          clippingPlane.constant = baseConstant + parseInt(ev.target.value);
          self.moveStencilPlanes(planeData);
        });
        wrapper.appendChild(input);
        return wrapper;
      } // Helper functions <--

    }

    moveStencilPlanes(planeData) {
      let {
        clippingPlane,
        stencilPlanes
      } = planeData;
      stencilPlanes.forEach(({
        stencilPlane
      }) => {
        let projectedPlanePos = stencilPlane.position.clone().projectOnVector(clippingPlane.normal);
        let clippingPlanePos = clippingPlane.normal.clone().multiplyScalar(-clippingPlane.constant);
        let delta = clippingPlanePos.sub(projectedPlanePos);
        stencilPlane.position.add(delta);
      });
    }

    clipMesh(planeData, mesh, targetPlanes) {
      mesh.updateMatrix();
      let clippingPlane = planeData.clippingPlane;
      let positionVector = planeData.positionVector;
      let targetScene = this.GraphicsThree3D.stencilScenes.length ? new THREE.Scene() : this.GraphicsThree3D.scene;

      if (mesh.stencilNeeded) {
        let stencilAndOutline = {
          stencilPlane: null,
          stencilClippingPlanes: targetPlanes,
          targetMesh: mesh,
          stencilOpacity: 0.0,
          targetScene
        };

        if (mesh.material) {
          let colorProps = this.getColorPropsFromMaterial(mesh.material, planeData.factor);
          stencilAndOutline.stencilOpacity = colorProps.alpha / 255.0;
          let [frontFaceStencilMat, backFaceStencilMat, planeStencilMat] = this.getClippingMaterials(colorProps);
          this.clipStencilMaterials(clippingPlane, targetPlanes, [frontFaceStencilMat, backFaceStencilMat, planeStencilMat]);
          let meshGeo = mesh.geometry.clone();

          for (let stencilMat of [frontFaceStencilMat, backFaceStencilMat]) {
            let stencilMesh = new THREE.Mesh(meshGeo, stencilMat);
            stencilMesh.applyMatrix4(mesh.matrix);
            stencilMesh.position.copy(mesh.position);
            stencilMesh.renderOrder = this.renderOrder;
            targetScene.add(stencilMesh);
          }

          let planeMesh = this.createStencilPlaneMesh(clippingPlane, positionVector, planeStencilMat);
          this.GraphicsThree3D.initWireframeObject(planeMesh, new THREE.Mesh(), this.GraphicsThree3D.jsonData, targetScene);
          targetScene.add(planeMesh);
          stencilAndOutline.stencilPlane = planeMesh;
        }

        planeData.stencilPlanes.push(stencilAndOutline);
      }

      this.GraphicsThree3D.stencilScenes.push(targetScene);
      if (mesh.children) mesh.children.forEach(child => this.clipMaterial(child.material, clippingPlane));
      this.clipMaterial(mesh.material, clippingPlane);
    }

    clipStencilMaterials(clippingPlane, targetPlanes, [frontFaceStencilMat, backFaceStencilMat, planeStencilMat]) {
      frontFaceStencilMat.clippingPlanes = [clippingPlane];
      backFaceStencilMat.clippingPlanes = [clippingPlane];
      planeStencilMat.clippingPlanes = targetPlanes.filter(plane => !plane.clippingPlane.equals(clippingPlane)).map(plane => plane.clippingPlane);
    }

    createStencilPlaneMesh(clippingPlane, positionVector, planeStencilMat) {
      let planeNormal = clippingPlane.normal.clone();
      let forwardVector = new THREE.Vector3(0, 0, -1);
      let planeGeom = new THREE.PlaneBufferGeometry();
      let planeMesh = new THREE.Mesh(planeGeom, planeStencilMat);
      planeMesh.scale.setScalar(1000);
      planeMesh.position.copy(positionVector);
      planeMesh.quaternion.setFromUnitVectors(forwardVector, planeNormal);

      planeMesh.onAfterRender = function (renderer) {
        renderer.clearStencil();
      };

      planeMesh.renderOrder = this.renderOrder + 0.1;
      planeMesh.isStencilPlane = true;
      return planeMesh;
    }

    getClippingMaterials(colorProps) {
      // https://discourse.threejs.org/t/capping-clipped-planes-using-stencil-on-a-buffergeometry/18407/18
      // PASS 1
      // everywhere that the back faces are visible (clipped region) the stencil
      // buffer is incremented by 1.
      let backFaceStencilMat = this.createBackStencilMat(); // PASS 2
      // everywhere that the front faces are visible the stencil
      // buffer is decremented back to 0.

      let frontFaceStencilMat = this.createFrontStencilMat(); // PASS 3
      // draw the plane everywhere that the stencil buffer != 0, which will
      // only be in the clipped region where back faces are visible.

      let planeStencilMat = this.createPlaneStencilMat(colorProps);
      return [frontFaceStencilMat, backFaceStencilMat, planeStencilMat];
    }

    createPlaneStencilMat(colorProps) {
      let planeStencilMat = this.GraphicsThree3D.getMaterialFromColor(colorProps); ///
      // planeStencilMat.side = THREE.FrontSide;
      ///

      planeStencilMat.stencilWrite = true;
      planeStencilMat.stencilRef = 0;
      planeStencilMat.stencilFunc = THREE.NotEqualStencilFunc;
      planeStencilMat.stencilFail = THREE.ReplaceStencilOp;
      planeStencilMat.stencilZFail = THREE.ReplaceStencilOp;
      planeStencilMat.stencilZPass = THREE.ReplaceStencilOp;
      return planeStencilMat;
    }

    createBackStencilMat() {
      let backFaceStencilMat = new THREE.MeshBasicMaterial();
      backFaceStencilMat.depthWrite = false;
      backFaceStencilMat.depthTest = false;
      backFaceStencilMat.colorWrite = false;
      backFaceStencilMat.stencilWrite = true;
      backFaceStencilMat.stencilFunc = THREE.AlwaysStencilFunc;
      backFaceStencilMat.side = THREE.BackSide;
      backFaceStencilMat.stencilFail = THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZFail = THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZPass = THREE.IncrementWrapStencilOp;
      return backFaceStencilMat;
    }

    createFrontStencilMat() {
      let frontFaceStencilMat = new THREE.MeshBasicMaterial();
      frontFaceStencilMat.depthWrite = false;
      frontFaceStencilMat.depthTest = false;
      frontFaceStencilMat.colorWrite = false;
      frontFaceStencilMat.stencilWrite = true;
      frontFaceStencilMat.stencilFunc = THREE.AlwaysStencilFunc;
      frontFaceStencilMat.side = THREE.FrontSide;
      frontFaceStencilMat.stencilFail = THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZFail = THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZPass = THREE.DecrementWrapStencilOp;
      return frontFaceStencilMat;
    }

    getColorPropsFromMaterial(meshMaterial, factor) {
      let material = meshMaterial.length ? meshMaterial[0].clone() : meshMaterial.clone();
      let {
        red,
        green,
        blue
      } = this.modifyColorWithFactor(material.color, factor);
      let colorProps = {
        alpha: material.opacity * 255,
        red,
        green,
        blue
      };
      return colorProps;
    }

    modifyColorWithFactor(color, factor) {
      let {
        r,
        g,
        b
      } = color;
      let red = r * 255;
      let green = g * 255;
      let blue = b * 255;

      if (factor > 0) {
        red += (255 - red) * factor;
        green += (255 - green) * factor;
        blue += (255 - blue) * factor;
      } else {
        red += red * factor;
        green += green * factor;
        blue += blue * factor;
      }

      red = Math.floor(red);
      green = Math.floor(green);
      blue = Math.floor(blue);
      return {
        red,
        green,
        blue
      };
    }

    clipMaterial(material, plane) {
      if (material) {
        if (material.length) material.forEach(mat => this.addClippingPlane(mat, plane));else this.addClippingPlane(material, plane);
      }
    }

    addClippingPlane(material, plane) {
      if (material.clippingPlanes) material.clippingPlanes.push(plane);else material.clippingPlanes = [plane];
    }

    togglePlaneVisibility(planeData, clippingPlane, isVisible) {
      if (isVisible) this.showClippingPlane(planeData, clippingPlane);else this.hideClippingPlane(planeData, clippingPlane);
    }

    showClippingPlane(planeData, clippingPlane) {
      [planeData.targets.drawingObjects, planeData.targets.fasteners, planeData.targets.dimensionalChains, planeData.targets.arrows].forEach(targetObjects => targetObjects.forEach(objData => this.addClippingPlaneToObject(objData.object, clippingPlane)));
      this.addClippingWhenVisible(clippingPlane);
      planeData.stencilPlanes.forEach(plane => plane.stencilPlane.material.opacity = plane.stencilOpacity);
    }

    hideClippingPlane(planeData, clippingPlane) {
      [planeData.targets.drawingObjects, planeData.targets.fasteners, planeData.targets.dimensionalChains, planeData.targets.arrows].forEach(targetObjects => targetObjects.forEach(objData => this.removeClippingPlaneFromObject(objData.object, clippingPlane)));
      this.removeClippingWhenNotVisible(clippingPlane);
      planeData.stencilPlanes.forEach(plane => plane.stencilPlane.material.opacity = 0);
    }

    addClippingWhenVisible(clippingPlane) {
      this.sectionalPlanes.forEach(planeData => {
        if (!planeData.clippingPlane.equals(clippingPlane)) {
          planeData.stencilPlanes.forEach(stencilData => {
            stencilData.stencilPlane.material.clippingPlanes.push(clippingPlane);
          });
        }
      });
    }

    removeClippingWhenNotVisible(clippingPlane) {
      this.sectionalPlanes.forEach(planeData => {
        planeData.stencilPlanes.forEach(stencilData => {
          stencilData.stencilPlane.material.clippingPlanes = stencilData.stencilPlane.material.clippingPlanes.filter(cp => !cp.equals(clippingPlane));
        });
      });
    }

    addClippingPlaneToObject(mesh, plane) {
      if (mesh.children) mesh.traverse(child => this.clipMaterial(child.material, plane));
      this.clipMaterial(mesh.material, plane);
    }

    removeClippingPlaneFromObject(mesh, plane) {
      if (mesh.children) mesh.traverse(child => this.removeClippingPlane(child.material, plane));
      this.removeClippingPlane(mesh.material, plane);
    }

    removeClippingPlane(material, plane) {
      if (material) {
        if (material.length > 0) material.forEach(mat => mat.clippingPlanes = mat.clippingPlanes.filter(cp => !cp.equals(plane)));else material.clippingPlanes = material.clippingPlanes.filter(cp => !cp.equals(plane));
      }
    }

    makePlane(planeData) {
      let positionVector = utils.initVector3(planeData.positionVector);
      let normalVector = utils.initVector3(planeData.normalVector).negate().normalize();
      let planeDistance = positionVector.clone().projectOnVector(normalVector).length();
      let distanceSign = positionVector.normalize().dot(normalVector) > 0 ? -1 : 1;
      planeDistance *= distanceSign;
      let plane = new THREE.Plane(normalVector, planeDistance);
      return plane;
    }

    clearSectionalPlanes() {
      this.sectionalPlanes.forEach(planeData => {
        planeData.stencilPlanes.forEach(stencilPlaneData => stencilPlaneData.targetScene.remove(stencilPlaneData.stencilPLane));
      });
      this.sectionalPlanes = [];
    }

  }

  _exports.DynamicSectionalPlane = DynamicSectionalPlane;
});