define("m08-2020/lib/ClippingUtils/SectionalPlane", ["exports", "three", "m08-2020/lib/Utils", "m08-2020/lib/ClippingUtils/OutlineGenerator"], function (_exports, THREE, _Utils, _OutlineGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SectionalPlane = void 0;
  let utils = new _Utils.Utils(THREE);

  class SectionalPlane {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.OutlineGenerator = new _OutlineGenerator.OutlineGenerator(GraphicsThree3D);
      this.clippingPlanes = [];
      this.clipObjects = [];
      this.renderOrder = 1;
      this.sectionalPlanesData = {};
    }

    addToSectionalPlanes(insertionPoint, object, objectData) {
      if (this.sectionalPlanesData.hasOwnProperty(objectData.id)) {
        if (this.sectionalPlanesData[objectData.id].hasOwnProperty(insertionPoint.id)) {
          this.sectionalPlanesData[objectData.id][insertionPoint.id].push({
            object,
            insertionPoint,
            sectionalPlanes: objectData.components.sectionalPlanes,
            properties: objectData.properties
          });
        } else {
          this.sectionalPlanesData[objectData.id][insertionPoint.id] = [{
            object,
            insertionPoint,
            sectionalPlanes: objectData.components.sectionalPlanes,
            properties: objectData.properties
          }];
        }
      } else {
        this.sectionalPlanesData[objectData.id] = {};
        this.sectionalPlanesData[objectData.id][insertionPoint.id] = [{
          object,
          insertionPoint,
          sectionalPlanes: objectData.components.sectionalPlanes,
          properties: objectData.properties
        }];
      }
    }

    applySectionalPlanes() {
      Object.values(this.sectionalPlanesData).forEach(drawingObj => Object.values(drawingObj).forEach(insertionPt => insertionPt.forEach(data => {
        let clippingPlanes = this.initClippingPlanes(data.sectionalPlanes, data.insertionPoint);
        let intersectionLineProps = data.properties.intersectionLine;
        let intersectionAreaProps = data.properties.intersectionArea;
        data.object.stencilNeeded = true;
        clippingPlanes.forEach(clippingPlane => {
          this.clipMesh(clippingPlane, data.object, intersectionAreaProps, intersectionLineProps, clippingPlanes);
        });
      })));
    }

    initClippingPlanes(sectionalPlanes, insertionPoint) {
      let clippingPlanes = [];
      let insertionVector = utils.initVector3(insertionPoint.insertionVector);
      let rotationAngle = insertionPoint.rotationAngle ? insertionPoint.rotationAngle * Math.PI / 180 : 0;
      let rotationAxis = rotationAngle ? utils.initVector3(insertionPoint.rotationAxis) : 0;
      let rotationPoint = rotationAngle ? utils.initVector3(insertionPoint.rotationPoint) : 0;
      sectionalPlanes.forEach(planeData => {
        let modifiedPlaneData = {};

        if (rotationAngle && rotationAxis && rotationPoint) {
          modifiedPlaneData.normalVector = utils.initVector3(planeData.normalVector).applyAxisAngle(rotationAxis, rotationAngle);
          modifiedPlaneData.positionVector = utils.initVector3(planeData.positionVector).sub(rotationPoint).applyAxisAngle(rotationAxis, rotationAngle).add(rotationPoint).add(insertionVector);
        } else {
          modifiedPlaneData.normalVector = planeData.normalVector;
          modifiedPlaneData.positionVector = {
            x: planeData.positionVector.x + insertionPoint.insertionVector.x,
            y: planeData.positionVector.y + insertionPoint.insertionVector.y,
            z: planeData.positionVector.z + insertionPoint.insertionVector.z
          };
        }

        clippingPlanes.push({
          clippingPlane: this.makePlane(modifiedPlaneData),
          positionVector: utils.initVector3(modifiedPlaneData.positionVector)
        });
      });
      return clippingPlanes;
    }

    applySectionalPlane(planeData, intersectionAreaProps, intersectionLineProps) {
      this.clipObjects.forEach(({
        object,
        insertionPoint
      }) => {
        if (object.type == "Mesh" || object.type == "Line" || object.type == "LineSegments") {
          this.clipMesh(planeData, object, insertionPoint, intersectionAreaProps, intersectionLineProps);
        }
      });
    }

    makePlane(planeData) {
      let positionVector = utils.initVector3(planeData.positionVector);
      let normalVector = utils.initVector3(planeData.normalVector).negate().normalize();
      let planeDistance = positionVector.clone().projectOnVector(normalVector).length();
      let distanceSign = positionVector.normalize().dot(normalVector) > 0 ? -1 : 1;
      planeDistance *= distanceSign;
      let plane = new THREE.Plane(normalVector, planeDistance);
      return plane;
    }

    clipMesh(planeData, mesh, intersectionAreaProps, lineProps, clippingPlanes) {
      mesh.updateMatrix();
      mesh.updateMatrixWorld();
      let clippingPlane = planeData.clippingPlane;
      let positionVector = planeData.positionVector;
      let targetScene = this.GraphicsThree3D.stencilScenes.length ? new THREE.Scene() : this.GraphicsThree3D.scene;

      if (mesh.stencilNeeded) {
        if (this.areaPropsDefined(intersectionAreaProps)) {
          let [frontFaceStencilMat, backFaceStencilMat, planeStencilMat] = this.getClippingMaterials(intersectionAreaProps);
          frontFaceStencilMat.clippingPlanes = [clippingPlane];
          backFaceStencilMat.clippingPlanes = [clippingPlane];
          planeStencilMat.clippingPlanes = clippingPlanes.filter(plane => !plane.clippingPlane.equals(clippingPlane)).map(plane => plane.clippingPlane);
          let meshGeo = mesh.geometry.clone();
          let frontMesh = new THREE.Mesh(meshGeo, frontFaceStencilMat);
          frontMesh.applyMatrix4(mesh.matrix);
          let backMesh = new THREE.Mesh(meshGeo, backFaceStencilMat);
          backMesh.applyMatrix4(mesh.matrix);
          frontMesh.renderOrder = this.renderOrder;
          backMesh.renderOrder = this.renderOrder;
          targetScene.add(frontMesh);
          targetScene.add(backMesh);
          let planeNormal = clippingPlane.normal.clone();
          let forwardVector = new THREE.Vector3(0, 0, -1);
          let planeGeom = new THREE.PlaneBufferGeometry();
          let planeMesh = new THREE.Mesh(planeGeom, planeStencilMat);
          planeMesh.scale.setScalar(1000);
          planeMesh.position.copy(positionVector);
          planeMesh.quaternion.setFromUnitVectors(forwardVector, planeNormal);
          frontMesh.position.copy(mesh.position);
          backMesh.position.copy(mesh.position);

          planeMesh.onAfterRender = function (renderer) {
            renderer.clearStencil();
          };

          planeMesh.renderOrder = this.renderOrder + 0.1;
          planeMesh.isSectionalPlane = true;
          this.GraphicsThree3D.initWireframeObject(planeMesh, new THREE.Mesh(), this.GraphicsThree3D.jsonData, targetScene);
          targetScene.add(planeMesh);
        }
      }

      if (lineProps) {
        let lines = this.OutlineGenerator.drawStencilOutline(mesh, clippingPlane, lineProps);
        this.OutlineGenerator.clearPointsOfIntersection();
        lines.forEach(line => {
          line.material.clippingPlanes = clippingPlanes.map(p => p.clippingPlane);
          targetScene.add(line);
        });
      }

      this.GraphicsThree3D.stencilScenes.push(targetScene);
      if (mesh.children) mesh.children.forEach(child => this.clipMaterial(child.material, clippingPlane));
      this.clipMaterial(mesh.material, clippingPlane);
    }

    clipMaterial(material, plane) {
      if (material.length) material.forEach(mat => this.addClippingPlane(mat, plane));else this.addClippingPlane(material, plane);
    }

    addClippingPlane(material, plane) {
      if (material.clippingPlanes) material.clippingPlanes.push(plane);else material.clippingPlanes = [plane];
    }

    getClippingMaterials(materialProps) {
      let frontFaceStencilMat, backFaceStencilMat, planeStencilMat; //https://discourse.threejs.org/t/capping-clipped-planes-using-stencil-on-a-buffergeometry/18407/18
      // PASS 1
      // everywhere that the back faces are visible (clipped region) the stencil
      // buffer is incremented by 1.

      backFaceStencilMat = new THREE.MeshBasicMaterial();
      backFaceStencilMat.depthWrite = false;
      backFaceStencilMat.depthTest = false;
      backFaceStencilMat.colorWrite = false;
      backFaceStencilMat.stencilWrite = true;
      backFaceStencilMat.stencilFunc = THREE.AlwaysStencilFunc;
      backFaceStencilMat.side = THREE.BackSide;
      backFaceStencilMat.stencilFail = THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZFail = THREE.IncrementWrapStencilOp;
      backFaceStencilMat.stencilZPass = THREE.IncrementWrapStencilOp; // PASS 2
      // everywhere that the front faces are visible the stencil
      // buffer is decremented back to 0.

      frontFaceStencilMat = new THREE.MeshBasicMaterial();
      frontFaceStencilMat.depthWrite = false;
      frontFaceStencilMat.depthTest = false;
      frontFaceStencilMat.colorWrite = false;
      frontFaceStencilMat.stencilWrite = true;
      frontFaceStencilMat.stencilFunc = THREE.AlwaysStencilFunc;
      frontFaceStencilMat.side = THREE.FrontSide;
      frontFaceStencilMat.stencilFail = THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZFail = THREE.DecrementWrapStencilOp;
      frontFaceStencilMat.stencilZPass = THREE.DecrementWrapStencilOp; // PASS 3
      // draw the plane everywhere that the stencil buffer != 0, which will
      // only be in the clipped region where back faces are visible.

      planeStencilMat = this.getPlaneStencilMaterial(materialProps);
      planeStencilMat.stencilWrite = true;
      planeStencilMat.stencilRef = 0;
      planeStencilMat.stencilFunc = THREE.NotEqualStencilFunc;
      planeStencilMat.stencilFail = THREE.ReplaceStencilOp;
      planeStencilMat.stencilZFail = THREE.ReplaceStencilOp;
      planeStencilMat.stencilZPass = THREE.ReplaceStencilOp;
      return [frontFaceStencilMat, backFaceStencilMat, planeStencilMat];
    }

    getPlaneStencilMaterial(areaProps) {
      const SIZE = 20;
      let planeStencilMat;
      let colorProps = areaProps.color;

      if (areaProps.hatching.type === 2) {
        let orientationVector = utils.normalizeVector(areaProps.hatching.orientationVector);
        let hatchSpacing = areaProps.hatching.factor;
        let hatchColor = areaProps.hatching.color;
        let hatchThickness = areaProps.hatching.thickness;
        planeStencilMat = this.GraphicsThree3D.makeCanvasMaterial(colorProps, hatchColor, orientationVector, hatchSpacing, hatchThickness);
        planeStencilMat.map.wrapS = planeStencilMat.map.wrapT = THREE.RepeatWrapping;
        planeStencilMat.map.repeat.set(SIZE, SIZE);
      } else if (areaProps.hatching.type === 1) {
        planeStencilMat = this.GraphicsThree3D.getMaterialFromColor(colorProps);
      }

      return planeStencilMat;
    }

    areaPropsDefined(areaProps) {
      return areaProps && areaProps.hatching.type > 0 && areaProps.hatching.type < 3;
    }

    resetClipObjects() {
      this.clipObjects = [];
    }

  }

  _exports.SectionalPlane = SectionalPlane;
});