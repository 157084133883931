define("m08-2020/lib/FigureLoaders/DrawingObjectComponents/ComplexVolume", ["exports", "m08-2020/lib/Utils/BooleanOperations"], function (_exports, BooleanOperations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ComplexVolume = void 0;

  class ComplexVolume {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.THREE = GraphicsThree3D.THREE;
      this.utils = GraphicsThree3D.utils;
      this.CSG = GraphicsThree3D.CSG;
      this.WireframeGenerator = GraphicsThree3D.WireframeGenerator;
    }

    addMultilayeredObjectsToScene(multilayeredObjects, wireframes, properties, insertionPoints, jsonData, drawingObject) {
      let meshArr = [];
      let positives = [];
      let negatives = [];
      multilayeredObjects.positives.forEach(objData => {
        let [geometry, faceSideNormal, grainDirection, midVector, eulerTwo] = objData;
        let mesh = new this.THREE.Mesh(geometry);
        mesh.position.add(midVector);
        mesh.setRotationFromEuler(eulerTwo);
        mesh.updateMatrix();
        positives.push([mesh, faceSideNormal, grainDirection]);
      });
      multilayeredObjects.negatives.forEach(objData => {
        let [geometry, faceSideNormal, grainDirection, midVector, eulerTwo] = objData;
        let mesh = new this.THREE.Mesh(geometry);
        mesh.position.add(midVector);
        mesh.setRotationFromEuler(eulerTwo);
        mesh.updateMatrix();
        negatives.push(mesh);
      });
      positives.forEach(positive => negatives.forEach(negative => positive[0] = BooleanOperations.doCSG(positive[0], negative, "subtract")));
      positives.forEach(posData => {
        let [mesh, faceSideNormal, grainDirection] = posData;
        this.GraphicsThree3D.indexVolumeFaces(mesh.geometry, faceSideNormal, grainDirection);
        let materials = this.GraphicsThree3D.getVolumeMaterials(properties.volume.color);
        mesh.material = materials;
        meshArr.push(mesh);
      });
      meshArr.forEach(mesh => this.GraphicsThree3D.DrawingObjectLoader.addToDimChainCollisionDetection(mesh, insertionPoints));
      meshArr.forEach(mesh => {
        let transparentMesh = new this.THREE.Mesh();
        transparentMesh.applyMatrix4(mesh.matrix);
        this.WireframeGenerator.applyWireframesArea(mesh, transparentMesh, properties.line, drawingObject); // TODO: Change to volume wireframe function

        this.GraphicsThree3D.addObjectToScene(mesh, transparentMesh, insertionPoints, jsonData, "volume", "drawingObject", drawingObject);
      });
    }

    generateBBoxDepthAndPoints(geometry) {
      const SIZE_DELTA = 1;
      geometry.computeBoundingBox();
      let bbox = geometry.boundingBox;
      let bboxDepth = (bbox.max.z - bbox.min.z + SIZE_DELTA) * 2;
      let pts = [new this.THREE.Vector3(bbox.min.x - SIZE_DELTA, bbox.min.y - SIZE_DELTA, 0), new this.THREE.Vector3(bbox.max.x + SIZE_DELTA, bbox.min.y - SIZE_DELTA, 0), new this.THREE.Vector3(bbox.max.x + SIZE_DELTA, bbox.max.y + SIZE_DELTA, 0), new this.THREE.Vector3(bbox.min.x - SIZE_DELTA, bbox.max.y + SIZE_DELTA, 0)].map(pt => pt.multiplyScalar(2));
      return [bboxDepth, pts];
    }

    makeExtrudedMeshForCSG(points2D, depth) {
      let rawGeo = this.GraphicsThree3D.makePositionedExtrusion({
        points2D,
        depth,
        translation: [0, 0, 0],
        skew: [0, 0]
      });
      let mesh = new this.THREE.Mesh(rawGeo);
      return mesh;
    }

    drawMultilayeredEllipses(ellipses, properties, multilayeredObjects) {
      let wireframes = [];
      ellipses.forEach(ellipse => {
        console.log(ellipse);

        if (ellipse.objectGeneration.type === 3) {
          let ellipseMesh = this.GraphicsThree3D.DrawingObjectLoader.Ellipse.drawEllipse(ellipse, properties);
          let [bboxDepth, pts] = this.generateBBoxDepthAndPoints(ellipseMesh.geometry);
          let extrusionVector = new this.THREE.Vector3(0, 0, bboxDepth);
          let centerPoint = this.utils.initVector3(ellipse.centerPoint);
          ellipseMesh.updateMatrix();
          pts.forEach(pt => pt.sub(centerPoint));

          if (properties.volume.type === 2) {
            let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
            let grainDirection = this.utils.initVector3(properties.volume.layers[0].grainDirectionVector).normalize();
            let segments = this.segmentPointsVertical(pts, properties.volume);
            segments.forEach(pointArr => {
              let rawMesh = this.makeExtrudedMeshForCSG(pointArr, bboxDepth);
              let modifiedMesh = BooleanOperations.doCSG(ellipseMesh, rawMesh, "intersect");
              let geometry = modifiedMesh.geometry;
              this.addToComplexVolumeData(ellipse, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, ellipseMesh.rotation]);
            });
          } else if (properties.volume.type === 3) {
            let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
            let grainDirectionOne = this.utils.initVector3(properties.volume.layers[0].grainDirectionVector).normalize();
            let grainDirectionTwo = this.utils.initVector3(properties.volume.layers[1].grainDirectionVector).normalize();
            let grainDirection;
            let segments = this.segmentPointsVertical(pts, properties.volume);
            segments.forEach(pointArr => {
              grainDirection = pointArr.type ? grainDirectionOne : grainDirectionTwo;
              let rawMesh = this.makeExtrudedMeshForCSG(pointArr.points, bboxDepth);
              let modifiedMesh = BooleanOperations.doCSG(ellipseMesh, rawMesh, "intersect");
              let geometry = modifiedMesh.geometry;
              this.addToComplexVolumeData(ellipse, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, ellipseMesh.rotation]);
            });
          } else if (properties.volume.type === 4) {
            let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
            let segments = this.segmentPointsVertical(pts, properties.volume);

            for (let i = 0; i < segments.length; i++) {
              let segment = segments[i];
              let layer = properties.volume.layers[i];
              let grainDirection = this.utils.initVector3(segment.layerProps.grainDirectionVector).normalize();

              if (segment.type) {
                let layerCount = Math.ceil(extrusionVector.z / layer.width);
                let layerWidth = bboxDepth / layerCount;
                let pointArr = segment.points;

                for (let j = 0; j < layerCount; j++) {
                  let rawGeo = this.GraphicsThree3D.makePositionedExtrusion({
                    points2D: pointArr,
                    depth: layerWidth,
                    translation: [0, 0, j * layerWidth],
                    skew: [0, 0]
                  });
                  let rawMesh = new this.THREE.Mesh(rawGeo);
                  let modifiedMesh = BooleanOperations.doCSG(ellipseMesh, rawMesh, "intersect");
                  let geometry = modifiedMesh.geometry;
                  this.addToComplexVolumeData(ellipse, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, ellipseMesh.rotation]);
                }
              } else {
                let horizontalSegments = this.segmentPointsHorizontal(segment.points, segment.layerProps);
                horizontalSegments.forEach(pointArr => {
                  let rawMesh = this.makeExtrudedMeshForCSG(pointArr, bboxDepth);
                  let modifiedMesh = BooleanOperations.doCSG(ellipseMesh, rawMesh, "intersect");
                  let geometry = modifiedMesh.geometry;
                  this.addToComplexVolumeData(ellipse, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, ellipseMesh.rotation]);
                });
              }
            }
          }
        } else if (ellipse.objectGeneration.type === 2) {
          let centerPoint = this.utils.initVector3(ellipse.centerPoint);
          let extrusionVector = this.utils.initVector3(ellipse.objectGeneration.extrusionVector);
          let extrusionNormalized = this.utils.initVector3(extrusionVector).normalize();
          let skewParams = this.utils.getSkewParams(extrusionVector);
          let pts = this.GraphicsThree3D.getEllipsePlanarPoints(ellipse);
          let [midVector, eulerOne, eulerTwo, crossVector] = this.GraphicsThree3D.getRotationParameters(pts, extrusionNormalized);
          pts.forEach(point => {
            point.sub(midVector);
            point.applyEuler(eulerOne);
          });
          extrusionVector.applyEuler(eulerOne);

          if (properties.volume.type === 2) {
            let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
            let grainDirection = this.utils.initVector3(properties.volume.layers[0].grainDirectionVector).normalize();
            let segments = this.segmentPointsVertical(pts, properties.volume);
            segments.forEach(pointArr => {
              let geometry = this.GraphicsThree3D.makePositionedExtrusion({
                points2D: pointArr,
                depth: extrusionVector.z,
                translation: [0, 0, 0],
                skew: skewParams
              });
              this.addToComplexVolumeData(ellipse, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, eulerTwo]);
            });
          } else if (properties.volume.type === 3) {
            let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
            let grainDirectionOne = this.utils.initVector3(properties.volume.layers[0].grainDirectionVector).normalize();
            let grainDirectionTwo = this.utils.initVector3(properties.volume.layers[1].grainDirectionVector).normalize();
            let grainDirection;
            let segments = this.segmentPointsVertical(pts, properties.volume);
            segments.forEach(pointArr => {
              grainDirection = pointArr.type ? grainDirectionOne : grainDirectionTwo;
              let geometry = this.GraphicsThree3D.makePositionedExtrusion({
                points2D: pointArr.points,
                depth: extrusionVector.z,
                translation: [0, 0, 0],
                skew: skewParams
              });
              this.addToComplexVolumeData(ellipse, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, eulerTwo]);
            });
          } else if (properties.volume.type === 4) {
            let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
            let segments = this.segmentPointsVertical(pts, properties.volume);

            for (let i = 0; i < segments.length; i++) {
              let segment = segments[i];
              let layer = properties.volume.layers[i];
              let grainDirection = this.utils.initVector3(segment.layerProps.grainDirectionVector).normalize();

              if (segment.type) {
                let layerCount = Math.ceil(extrusionVector.z / layer.width);
                let layerWidth = extrusionVector.z / layerCount;
                let pointArr = segment.points;

                for (let j = 0; j < layerCount; j++) {
                  let geometry = this.GraphicsThree3D.makePositionedExtrusion({
                    points2D: pointArr,
                    depth: layerWidth,
                    translation: [0, 0, j * layerWidth],
                    skew: skewParams
                  });
                  this.addToComplexVolumeData(ellipse, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, eulerTwo]);
                }
              } else {
                let horizontalSegments = this.segmentPointsHorizontal(segment.points, segment.layerProps);
                horizontalSegments.forEach(pointArr => {
                  let geometry = this.GraphicsThree3D.makePositionedExtrusion({
                    points2D: pointArr,
                    depth: extrusionVector.z,
                    translation: [0, 0, 0],
                    skew: skewParams
                  });
                  this.addToComplexVolumeData(ellipse, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, eulerTwo]);
                });
              }
            }
          }
        }
      });
      return wireframes;
    }

    drawMultilayeredPolygons(polygons, properties, multilayeredObjects) {
      let wireframes = [];
      polygons.forEach(polygon => {
        let extrusionVector = this.utils.initVector3(polygon.objectGeneration.extrusionVector);
        let extrusionNormalized = this.utils.initVector3(extrusionVector).normalize();
        let skewParams = this.utils.getSkewParams(extrusionVector);
        let pts = this.GraphicsThree3D.DrawingObjectLoader.Polygon.getPolygonPlanarPoints(polygon);
        let [midVector, eulerOne, eulerTwo, crossVector] = this.GraphicsThree3D.getRotationParameters(pts, extrusionNormalized);
        pts.forEach(point => {
          point.sub(midVector);
          point.applyEuler(eulerOne);
        });
        extrusionVector.applyEuler(eulerOne);

        if (properties.volume.type === 2) {
          let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
          let grainDirection = this.utils.initVector3(properties.volume.layers[0].grainDirectionVector).normalize();
          let segments = this.segmentPointsVertical(pts, properties.volume);
          let polygonDepth = extrusionVector.z;
          let translateZ = crossVector.dot(new this.THREE.Vector3(0, 0, -1)) > 0 ? -polygonDepth : 0;
          segments.forEach(pointArr => {
            let geometry = this.GraphicsThree3D.makePositionedExtrusion({
              points2D: pointArr,
              depth: polygonDepth,
              translation: [0, 0, translateZ],
              skew: skewParams
            });
            this.addToComplexVolumeData(polygon, multilayeredObjects, [geometry, faceSideNormal, grainDirection, midVector, eulerTwo]);
          });
        } else if (properties.volume.type === 3) {
          let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
          let grainDirectionOne = this.utils.initVector3(properties.volume.layers[0].grainDirectionVector).normalize();
          let grainDirectionTwo = this.utils.initVector3(properties.volume.layers[1].grainDirectionVector).normalize();
          let grainDirection;
          let polygonDepth = extrusionVector.z;
          let translateZ = crossVector.dot(new this.THREE.Vector3(0, 0, -1)) > 0 ? -polygonDepth : 0;
          let segments = this.segmentPointsVertical(pts, properties.volume);
          segments.forEach(pointArr => {
            grainDirection = pointArr.type ? grainDirectionOne : grainDirectionTwo;
            let geometry = this.GraphicsThree3D.makePositionedExtrusion({
              points2D: pointArr.points,
              depth: polygonDepth,
              translation: [0, 0, translateZ],
              skew: skewParams
            });
            this.addToComplexVolumeData(polygon, multilayeredObjects, [geometry, faceSideNormal, grainDirection, midVector, eulerTwo]);
          });
        } else if (properties.volume.type === 4) {
          let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
          let segments = this.segmentPointsVertical(pts, properties.volume);

          for (let i = 0; i < segments.length; i++) {
            let segment = segments[i];
            let layer = properties.volume.layers[i];
            let grainDirection = this.utils.initVector3(segment.layerProps.grainDirectionVector).normalize();

            if (segment.type) {
              let layerCount = Math.ceil(extrusionVector.z / layer.width);
              let layerWidth = extrusionVector.z / layerCount;
              let polygonDepth = layerWidth;
              let translateZ = crossVector.dot(new this.THREE.Vector3(0, 0, -1)) > 0 ? -polygonDepth : polygonDepth;
              let pointArr = segment.points;

              for (let j = 0; j < layerCount; j++) {
                let geometry = this.GraphicsThree3D.makePositionedExtrusion({
                  points2D: pointArr,
                  depth: polygonDepth,
                  translation: [0, 0, j * translateZ],
                  skew: skewParams
                });
                this.addToComplexVolumeData(polygon, multilayeredObjects, [geometry, faceSideNormal, grainDirection, midVector, eulerTwo]);
              }
            } else {
              let horizontalSegments = this.segmentPointsHorizontal(segment.points, segment.layerProps);
              let polygonDepth = extrusionVector.z;
              let translateZ = crossVector.dot(new this.THREE.Vector3(0, 0, -1)) > 0 ? -polygonDepth : 0;
              horizontalSegments.forEach(pointArr => {
                let geometry = this.GraphicsThree3D.makePositionedExtrusion({
                  points2D: pointArr,
                  depth: polygonDepth,
                  translation: [0, 0, translateZ],
                  skew: skewParams
                });
                this.addToComplexVolumeData(polygon, multilayeredObjects, [geometry, faceSideNormal, grainDirection, midVector, eulerTwo]);
              });
            }
          }
        }
      });
      return wireframes;
    }

    drawMultilayeredEllipsoids(ellipsoids, properties, multilayeredObjects) {
      const SIZE_DELTA = 1;
      let wireframes = [];
      ellipsoids.forEach(ellipsoid => {
        let ellipsoidMesh = this.GraphicsThree3D.DrawingObjectLoader.Ellipsoid.drawEllipsoid(ellipsoid);
        ellipsoidMesh.geometry.computeBoundingBox();
        let bbox = ellipsoidMesh.geometry.boundingBox;
        let bboxDepth = bbox.max.z - bbox.min.z + SIZE_DELTA;
        let pts = [new this.THREE.Vector3(bbox.min.x - SIZE_DELTA, bbox.min.y - SIZE_DELTA, 0), new this.THREE.Vector3(bbox.max.x + SIZE_DELTA, bbox.min.y - SIZE_DELTA, 0), new this.THREE.Vector3(bbox.max.x + SIZE_DELTA, bbox.max.y + SIZE_DELTA, 0), new this.THREE.Vector3(bbox.min.x - SIZE_DELTA, bbox.max.y + SIZE_DELTA, 0)];
        let extrusionVector = new this.THREE.Vector3(0, 0, bboxDepth);
        let centerPoint = this.utils.initVector3(ellipsoid.centerPoint);
        ellipsoidMesh.position.sub(centerPoint).add(extrusionVector.clone().multiplyScalar(0.5));
        ellipsoidMesh.updateMatrix();
        centerPoint.add(extrusionVector.clone().multiplyScalar(0.5));

        if (properties.volume.type === 2) {
          let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
          let grainDirection = this.utils.initVector3(properties.volume.layers[0].grainDirectionVector).normalize();
          let segments = this.segmentPointsVertical(pts, properties.volume);
          segments.forEach(pointArr => {
            let rawGeo = this.GraphicsThree3D.makePositionedExtrusion({
              points2D: pointArr,
              depth: bboxDepth,
              translation: [0, 0, 0],
              skew: [0, 0]
            });
            let rawMesh = new this.THREE.Mesh(rawGeo);
            let modifiedMesh = BooleanOperations.doCSG(ellipsoidMesh, rawMesh, "intersect");
            let geometry = modifiedMesh.geometry;
            this.addToComplexVolumeData(ellipsoid, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, ellipsoidMesh.rotation]);
          });
        } else if (properties.volume.type === 3) {
          let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
          let grainDirectionOne = this.utils.initVector3(properties.volume.layers[0].grainDirectionVector).normalize();
          let grainDirectionTwo = this.utils.initVector3(properties.volume.layers[1].grainDirectionVector).normalize();
          let grainDirection;
          let segments = this.segmentPointsVertical(pts, properties.volume);
          segments.forEach(pointArr => {
            grainDirection = pointArr.type ? grainDirectionOne : grainDirectionTwo;
            let rawGeo = this.GraphicsThree3D.makePositionedExtrusion({
              points2D: pointArr.points,
              depth: bboxDepth,
              translation: [0, 0, 0],
              skew: [0, 0]
            });
            let rawMesh = new this.THREE.Mesh(rawGeo);
            let modifiedMesh = BooleanOperations.doCSG(ellipsoidMesh, rawMesh, "intersect");
            let geometry = modifiedMesh.geometry;
            this.addToComplexVolumeData(ellipsoid, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, ellipsoidMesh.rotation]);
          });
        } else if (properties.volume.type === 4) {
          let faceSideNormal = this.utils.initVector3(properties.volume.faceSideNormalVector).normalize();
          let segments = this.segmentPointsVertical(pts, properties.volume);

          for (let i = 0; i < segments.length; i++) {
            let segment = segments[i];
            let layer = properties.volume.layers[i];
            let grainDirection = this.utils.initVector3(segment.layerProps.grainDirectionVector).normalize();

            if (segment.type) {
              let layerCount = Math.ceil(extrusionVector.z / layer.width);
              let layerWidth = bboxDepth / layerCount;
              let pointArr = segment.points;

              for (let j = 0; j < layerCount; j++) {
                let rawGeo = this.GraphicsThree3D.makePositionedExtrusion({
                  points2D: pointArr,
                  depth: layerWidth,
                  translation: [0, 0, j * layerWidth],
                  skew: [0, 0]
                });
                let rawMesh = new this.THREE.Mesh(rawGeo);
                let modifiedMesh = BooleanOperations.doCSG(ellipsoidMesh, rawMesh, "intersect");
                let geometry = modifiedMesh.geometry;
                this.addToComplexVolumeData(ellipsoid, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, ellipsoidMesh.rotation]);
              }
            } else {
              let horizontalSegments = this.segmentPointsHorizontal(segment.points, segment.layerProps);
              horizontalSegments.forEach(pointArr => {
                let rawGeo = this.GraphicsThree3D.makePositionedExtrusion({
                  points2D: pointArr,
                  depth: bboxDepth,
                  translation: [0, 0, 0],
                  skew: [0, 0]
                });
                let rawMesh = new this.THREE.Mesh(rawGeo);
                let modifiedMesh = BooleanOperations.doCSG(ellipsoidMesh, rawMesh, "intersect");
                let geometry = modifiedMesh.geometry;
                this.addToComplexVolumeData(ellipsoid, multilayeredObjects, [geometry, faceSideNormal, grainDirection, centerPoint, ellipsoidMesh.rotation]);
              });
            }
          }
        }
      });
      return wireframes;
    }

    addToComplexVolumeData(objectData, multilayeredObjects, [geometry, faceSideNormal, grainDirection, midVector, eulerTwo]) {
      if (objectData.objectType === 1) multilayeredObjects.positives.push([geometry, faceSideNormal, grainDirection, midVector, eulerTwo]);else multilayeredObjects.negatives.push([geometry, faceSideNormal, grainDirection, midVector, eulerTwo]);
    }

    segmentPointsVertical(points, volumeProps) {
      let PolyBool = this.GraphicsThree3D.PolyBool;
      let ptsPolygon = {
        regions: [[]],
        inverted: false
      };
      let maxX = 0,
          maxY = 0;
      let minX = Infinity,
          minY = Infinity;
      points.forEach(point => {
        if (point.x > maxX) maxX = point.x;
        if (point.y > maxY) maxY = point.y;
        if (point.x < minX) minX = point.x;
        if (point.y < minY) minY = point.y;
        ptsPolygon.regions[0].push([point.x, point.y]);
      });
      let segments = [];

      if (volumeProps.type == 2) {
        let layerThickness = volumeProps.layers[0].thickness;
        let eraserPolygon = {
          regions: [],
          inverted: false
        };

        while (minY < maxY) {
          let eraserRegion = [];
          eraserRegion.push([minX, minY]);
          eraserRegion.push([maxX, minY]);
          eraserRegion.push([maxX, minY + layerThickness]);
          eraserRegion.push([minX, minY + layerThickness]);
          eraserPolygon.regions.push(eraserRegion);
          minY += 2 * layerThickness;
        }

        let polygonOne = PolyBool.difference(ptsPolygon, eraserPolygon);
        let polygonTwo = PolyBool.intersect(ptsPolygon, eraserPolygon);
        polygonOne.regions.forEach(region => {
          let pointArr = [];
          region.forEach(point => pointArr.push(new this.THREE.Vector2(point[0], point[1])));
          segments.push(pointArr);
        });
        polygonTwo.regions.forEach(region => {
          let pointArr = [];
          region.forEach(point => pointArr.push(new this.THREE.Vector2(point[0], point[1])));
          segments.push(pointArr);
        });
      } else if (volumeProps.type == 3) {
        let objectHeight = maxY - minY;
        let layerThickness = objectHeight / Math.ceil(objectHeight / volumeProps.layers[0].thickness);
        let eraserPolygon = {
          regions: [],
          inverted: false
        };

        while (minY < maxY) {
          let eraserRegion = [];
          eraserRegion.push([minX, minY]);
          eraserRegion.push([maxX, minY]);
          eraserRegion.push([maxX, minY + layerThickness]);
          eraserRegion.push([minX, minY + layerThickness]);
          eraserPolygon.regions.push(eraserRegion);
          minY += 2 * layerThickness;
        }

        let polygonOne = PolyBool.difference(ptsPolygon, eraserPolygon);
        let polygonTwo = PolyBool.intersect(ptsPolygon, eraserPolygon);
        polygonOne.regions.forEach(region => {
          let pointArr = {
            points: [],
            type: 0
          };
          region.forEach(point => pointArr.points.push(new this.THREE.Vector2(point[0], point[1])));
          segments.push(pointArr);
        });
        polygonTwo.regions.forEach(region => {
          let pointArr = {
            points: [],
            type: 1
          };
          region.forEach(point => pointArr.points.push(new this.THREE.Vector2(point[0], point[1])));
          segments.push(pointArr);
        });
      } else {
        let layers = volumeProps.layers;
        let eraserPolygon = {
          regions: [],
          inverted: false
        };
        let layersHeight = layers.map(layer => layer = layer.thickness).reduce((a, b) => a + b, 0);
        let objectHeight = maxY - minY;

        if (layersHeight != objectHeight) {
          let proportion = objectHeight / layersHeight;
          layers.forEach(layer => layer.thickness *= proportion);
        }

        let layerPropsEven = [];
        let layerPropsOdd = [];

        for (let i = 0; i < layers.length; i++) {
          let layerThickness = layers[i].thickness;

          if (i % 2 == 0) {
            let eraserRegion = [];
            eraserRegion.push([minX, minY]);
            eraserRegion.push([maxX, minY]);
            eraserRegion.push([maxX, minY + layerThickness]);
            eraserRegion.push([minX, minY + layerThickness]);
            eraserPolygon.regions.push(eraserRegion);
            layerPropsEven.push(layers[i]);
          } else {
            layerPropsOdd.push(layers[i]);
          }

          minY += layerThickness;
        }

        let polygonOne = PolyBool.difference(ptsPolygon, eraserPolygon);
        let polygonTwo = PolyBool.intersect(ptsPolygon, eraserPolygon);
        polygonOne.regions.forEach((region, index) => {
          let pointArr = {
            points: [],
            type: 0,
            layerProps: layerPropsOdd[index]
          };
          region.forEach(point => pointArr.points.push(new this.THREE.Vector2(point[0], point[1])));
          segments.push(pointArr);
        });
        polygonTwo.regions.forEach((region, index) => {
          let pointArr = {
            points: [],
            type: 1,
            layerProps: layerPropsEven[index]
          };
          region.forEach(point => pointArr.points.push(new this.THREE.Vector2(point[0], point[1])));
          segments.push(pointArr);
        });
      }

      return segments;
    }

    segmentPointsHorizontal(points, layerProps) {
      let PolyBool = this.GraphicsThree3D.PolyBool;
      let segments = [];
      let ptsPolygon = {
        regions: [[]],
        inverted: false
      };
      let maxX = 0,
          maxY = 0;
      let minX = Infinity,
          minY = Infinity;
      points.forEach(point => {
        if (point.x > maxX) maxX = point.x;
        if (point.y > maxY) maxY = point.y;
        if (point.x < minX) minX = point.x;
        if (point.y < minY) minY = point.y;
        ptsPolygon.regions[0].push([point.x, point.y]);
      });
      let eraserPolygon = {
        regions: [],
        inverted: false
      };
      let objectWidth = maxX - minX;
      let segmentWidth = layerProps.width;
      let segmentCount = Math.ceil(objectWidth / segmentWidth);
      segmentWidth = objectWidth / segmentCount;

      while (minX < maxX) {
        let eraserRegion = [];
        eraserRegion.push([minX, maxY]);
        eraserRegion.push([minX, minY]);
        eraserRegion.push([minX + segmentWidth, minY]);
        eraserRegion.push([minX + segmentWidth, maxY]);
        eraserPolygon.regions.push(eraserRegion);
        minX += 2 * segmentWidth;
      }

      let polygonOne = PolyBool.difference(ptsPolygon, eraserPolygon);
      let polygonTwo = PolyBool.intersect(ptsPolygon, eraserPolygon);
      polygonOne.regions.forEach(region => {
        let pointArr = [];
        region.forEach(point => pointArr.push(new this.THREE.Vector2(point[0], point[1])));
        segments.push(pointArr);
      });
      polygonTwo.regions.forEach(region => {
        let pointArr = [];
        region.forEach(point => pointArr.push(new this.THREE.Vector2(point[0], point[1])));
        segments.push(pointArr);
      });
      return segments;
    }

  }

  _exports.ComplexVolume = ComplexVolume;
});