define("m08-2020/lib/Utils", ["exports", "three/examples/jsm/lines/Line2", "three/examples/jsm/lines/LineMaterial", "three/examples/jsm/lines/LineGeometry"], function (_exports, _Line, _LineMaterial, _LineGeometry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Utils = void 0;

  class Utils {
    constructor(three) {
      this.THREE = three;
    }

    initVector2(vectorData) {
      return new this.THREE.Vector2(vectorData.x, vectorData.y);
    }

    initVector3(vectorData) {
      let vector = new this.THREE.Vector3(vectorData.x, vectorData.y, vectorData.z ? vectorData.z : 0);
      return vector;
    }

    vectorsAreEqual(vecA, vecB) {
      const DELTA = 1e-4;
      return Math.abs(vecA.x - vecB.x) < DELTA && Math.abs(vecA.y - vecB.y) < DELTA && Math.abs(vecA.z - vecB.z) < DELTA;
    }

    addVectorObjects(vecOne, vecTwo) {
      return {
        x: vecOne.x + vecTwo.x,
        y: vecOne.y + vecTwo.y,
        z: vecOne.z + vecTwo.z
      };
    }

    applyRotationToVector(vectorData, rotationParams) {
      let rotationAngle = rotationParams.rotationAngle * Math.PI / 180;
      let rotationAxis = this.initVector3(rotationParams.rotationAxis).normalize();
      let rotationPoint = this.initVector3(rotationParams.rotationPoint);
      let vector = this.initVector3(vectorData).sub(rotationPoint).applyAxisAngle(rotationAxis, rotationAngle).add(rotationPoint);
      return vector;
    }

    normalizeVector(vector) {
      let vectorLength = Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2) + Math.pow(vector.z, 2));
      let normalizedVector = {
        x: vector.x / vectorLength,
        y: vector.y / vectorLength,
        z: vector.z / vectorLength
      };
      return normalizedVector;
    }

    roundToDecimals(num, decimals = 1) {
      let multiplier = Math.pow(10, decimals);
      return Math.round(num * multiplier) / multiplier;
    }

    checkVectorsEquality(vectorOne, vectorTwo, precision = 2) {
      return this.roundToDecimals(vectorOne.x, precision) === this.roundToDecimals(vectorTwo.x, precision) && this.roundToDecimals(vectorOne.y, precision) === this.roundToDecimals(vectorTwo.y, precision) && this.roundToDecimals(vectorOne.z, precision) === this.roundToDecimals(vectorTwo.z, precision);
    }

    getEulerOneAndTwo(crossVector) {
      let quaternionOne = new this.THREE.Quaternion();
      quaternionOne.setFromUnitVectors(crossVector, new this.THREE.Vector3(0, 0, 1));
      let eulerOne = new this.THREE.Euler();
      eulerOne.setFromQuaternion(quaternionOne);
      let quaternionTwo = new this.THREE.Quaternion();
      quaternionTwo.setFromUnitVectors(new this.THREE.Vector3(0, 0, 1), crossVector);
      let eulerTwo = new this.THREE.Euler();
      eulerTwo.setFromQuaternion(quaternionTwo);
      return [eulerOne, eulerTwo];
    }

    getCrossVector(vecOne, vecTwo) {
      return new this.THREE.Vector3().crossVectors(vecOne, vecTwo).normalize();
    }

    clampNum(num, min, max) {
      return Math.min(Math.max(num, min), max);
    }

    getColorRGB(colorData) {
      let color = "rgb(" + colorData.red + ", " + colorData.green + ", " + colorData.blue + ")";
      return color;
    }

    getMaterialFromColorProps(colorProps) {
      let materialOptions = {
        color: this.getColorRGB(colorProps),
        transparent: true,
        opacity: colorProps.alpha / 255,
        side: this.THREE.DoubleSide
      };
      let material = new this.THREE.MeshBasicMaterial(materialOptions);
      return material;
    }

    isComplexVolume(properties) {
      return properties && properties.volume && properties.volume.type > 1;
    }

    skewExtrudedGeometry(geometry, [skewX, skewY]) {
      geometry.applyMatrix4(new this.THREE.Matrix4().set(1, 0, skewX, 0, 0, 1, skewY, 0, 0, 0, 1, 0, 0, 0, 0, 1));
    }

    getSkewParams(extrusionVector) {
      let extrusionNormalized = extrusionVector.clone().normalize();
      let skewX = extrusionNormalized.x / extrusionNormalized.z;
      let skewY = extrusionNormalized.y / extrusionNormalized.z;
      return [skewX, skewY];
    }

    getVerticesFromBufferGeometry(geometry) {
      let vertices = [];
      let posAttr = geometry.attributes.position;

      if (posAttr) {
        for (let i = 0; i < posAttr.count; i++) {
          vertices.push(new this.THREE.Vector3().fromBufferAttribute(posAttr, i));
        }
      }

      return vertices;
    }

    mergeLinePoints(pairArr, lineDirection) {
      pairArr.sort((pairOne, pairTwo) => pairTwo[0].clone().projectOnVector(lineDirection).lengthSq() - pairOne[0].clone().projectOnVector(lineDirection).lengthSq());
      let startingLength = pairArr.length;
      let previousLengthChanged = true;

      while (previousLengthChanged) {
        for (let i = 0; i < pairArr.length; i++) {
          for (let j = i; j < pairArr.length; j++) {
            let pairOne = pairArr[i];
            let pairTwo = pairArr[j];

            if (this.linesLayOnSamePlane(pairOne, pairTwo, lineDirection)) {
              let mergedPair = this.mergeTwoLinePoints(pairOne, pairTwo, lineDirection);

              if (mergedPair) {
                pairArr[i] = mergedPair;
                if (i !== j) pairArr.splice(j, 1);
              }
            }
          }
        }

        if (pairArr.length === startingLength) previousLengthChanged = false;else startingLength = pairArr.length;
      }

      return pairArr;
    }

    mergeTwoLinePoints(pairOne, pairTwo, lineDirection) {
      if (pairOne[0].equals(pairTwo[0]) && pairOne[1].equals(pairTwo[1])) return pairOne;
      let pairOneSorted = [...pairOne].sort((a, b) => {
        let projA = new this.THREE.Vector3().copy(a).projectOnVector(lineDirection);
        let projB = new this.THREE.Vector3().copy(b).projectOnVector(lineDirection);
        return projA.dot(lineDirection) - projB.dot(lineDirection);
      });
      let pairTwoSorted = [...pairTwo].sort((a, b) => {
        let projA = new this.THREE.Vector3().copy(a).projectOnVector(lineDirection);
        let projB = new this.THREE.Vector3().copy(b).projectOnVector(lineDirection);
        return projA.dot(lineDirection) - projB.dot(lineDirection);
      });
      let projectedPoints = [...pairOneSorted, ...pairTwoSorted].sort((a, b) => {
        let projA = new this.THREE.Vector3().copy(a).projectOnVector(lineDirection);
        let projB = new this.THREE.Vector3().copy(b).projectOnVector(lineDirection);
        return projA.dot(lineDirection) - projB.dot(lineDirection);
      });
      let minPoint = projectedPoints[0];
      let [pOneL0, pOneL1] = [pairOneSorted[0].distanceTo(minPoint), pairOneSorted[1].distanceTo(minPoint)];
      let [pTwoL0, pTwoL1] = [pairTwoSorted[0].distanceTo(minPoint), pairTwoSorted[1].distanceTo(minPoint)];
      let merged;
      if (pTwoL0 === pOneL0 && pTwoL1 === pOneL1) merged = pairTwoSorted;else if (pTwoL0 > pOneL0 && pTwoL1 < pOneL1) merged = pairOneSorted;else if (pOneL0 > pTwoL0 && pOneL1 < pTwoL1) merged = pairTwoSorted;else if (pTwoL0 <= pOneL0 && pTwoL1 >= pOneL0 && pTwoL1 <= pOneL1) merged = [pairTwoSorted[0], pairOneSorted[1]];else if (pTwoL0 >= pOneL0 && pTwoL0 <= pOneL1 && pTwoL1 >= pOneL1) merged = [pairOneSorted[0], pairTwoSorted[1]];else if (pTwoL0 >= pOneL1 && pTwoL0 <= pOneL0 && pTwoL1 <= pOneL1) merged = [pairOneSorted[0], pairTwoSorted[1]];else if (pTwoL0 >= pOneL0 && pTwoL1 <= pOneL0 && pTwoL1 >= pOneL1) merged = [pairTwoSorted[0], pairOneSorted[1]];
      return merged;
    }

    linesLayOnSamePlane(pairOne, pairTwo, planeNormal) {
      const DELTA = 0.0001;
      let projectionOne = pairOne[0].clone().projectOnPlane(planeNormal);
      let projectionTwo = pairTwo[0].clone().projectOnPlane(planeNormal);
      return projectionOne.distanceTo(projectionTwo) < DELTA;
    }

    getCrossVectorFromPoints(points) {
      let pointVectors = [];

      for (let i = 0; i < points.length - 1; i++) {
        pointVectors.push(new this.THREE.Vector3().subVectors(points[i + 1], points[i]));
      }

      pointVectors.push(new this.THREE.Vector3().subVectors(points[0], points[points.length - 1]));
      let crossVector = new this.THREE.Vector3();

      for (let i = 0; i < pointVectors.length - 1; i++) {
        crossVector.add(new this.THREE.Vector3().crossVectors(pointVectors[i], pointVectors[i + 1]));
      }

      crossVector.add(new this.THREE.Vector3().crossVectors(pointVectors[pointVectors.length - 1], pointVectors[0]));
      crossVector.normalize();
      return crossVector;
    }

    vectorIsZero(vectorData) {
      return !vectorData.x && !vectorData.y && !vectorData.z;
    }

    drawLine(pointOne, pointTwo, lineProps) {
      if (lineProps.type === 0 || lineProps.thickness === 0 || lineProps.color.alpha === 0) return new this.THREE.Mesh();
      let lineMaterial = this.getMaterialFromColorProps(lineProps.color);
      let lineRadius = lineProps.thickness / 2;
      let lineSize = pointOne.distanceTo(pointTwo);
      let lineGeo = new this.THREE.CylinderGeometry(lineRadius, lineRadius, lineSize, 8);
      lineGeo.rotateX(Math.PI / 2);
      lineGeo.translate(0, 0, lineSize / 2);
      let lineMesh = new this.THREE.Mesh(lineGeo, lineMaterial);
      lineMesh.position.copy(pointOne);
      lineMesh.lookAt(pointTwo);
      return lineMesh;
    }

    drawSolidLine(points, lineProps, closed = false) {
      let lineMeshArray = [];
      let localPoints = points.map(pt => pt.clone());
      if (closed) localPoints.push(localPoints[0].clone());

      for (let i = 0; i < localPoints.length - 1; i++) {
        if (localPoints[i].distanceTo(localPoints[i + 1]) > 0) {
          let lineMesh = this.drawLine(localPoints[i], localPoints[i + 1], lineProps, true);
          lineMeshArray.push(lineMesh);
        }
      }

      let firstLine = lineMeshArray.length > 0 ? lineMeshArray.splice(0, 1)[0] : new this.THREE.Mesh();
      let lineMaterial = firstLine.material;
      firstLine.updateMatrixWorld();
      firstLine.geometry.applyMatrix4(firstLine.matrixWorld);

      for (let i = 0; i < lineMeshArray.length; i++) {
        lineMeshArray[i].updateMatrixWorld();
        lineMeshArray[i].geometry.applyMatrix4(lineMeshArray[i].matrixWorld);
        firstLine.geometry.merge(lineMeshArray[i].geometry);
      }

      firstLine = new this.THREE.Mesh(firstLine.geometry, lineMaterial);
      return firstLine;
    }

    drawDashedLine(pointOne, pointTwo, factor, pointPairs) {
      let newSegment = true;
      let direction = new this.THREE.Vector3().subVectors(pointTwo, pointOne).normalize();
      let distance = pointOne.distanceTo(pointTwo);
      let segmentSize = factor;

      while (distance > segmentSize) {
        let newPoint = new this.THREE.Vector3().copy(pointOne).add(direction.clone().multiplyScalar(segmentSize));
        if (newSegment) pointPairs.push([pointOne, newPoint]);
        pointOne = newPoint;
        distance -= segmentSize;
        newSegment = !newSegment;
      }
    }

    makeDashSegments(points, factor) {
      let distanceDelta = factor;

      for (let i = 0; i < points.length - 1; i++) {
        let distance = points[i].distanceTo(points[i + 1]);

        if (distance < distanceDelta) {
          distanceDelta -= distance;
        } else {
          let newPoint = new this.THREE.Vector3().lerpVectors(points[i], points[i + 1], distanceDelta / distance);
          newPoint.insertedPoint = true;
          points.splice(i + 1, 0, newPoint);
          distanceDelta = factor;
        }
      }
    }

    mergeLines(pointPairs, lineProps) {
      let lineMeshArray = [];
      pointPairs.forEach(pair => {
        if (pair[0].distanceTo(pair[1]) > 0) {
          let lineMesh = this.drawLine(pair[0], pair[1], lineProps);
          lineMeshArray.push(lineMesh);
        }
      });
      let firstLine = lineMeshArray.splice(0, 1)[0];
      let lineMaterial = firstLine.material;
      firstLine.updateMatrixWorld();
      firstLine.geometry.applyMatrix4(firstLine.matrixWorld);

      for (let i = 0; i < lineMeshArray.length; i++) {
        lineMeshArray[i].updateMatrixWorld();
        lineMeshArray[i].geometry.applyMatrix4(lineMeshArray[i].matrixWorld);
        firstLine.geometry.merge(lineMeshArray[i].geometry);
      }

      firstLine = new this.THREE.Mesh(firstLine.geometry, lineMaterial);
      return firstLine;
    }

    drawConnectedLines(points, lineProps, closed = false) {
      let localPoints = points.map(point => point.clone());
      let firstLine;

      if (canDrawLine(lineProps)) {
        if (canDrawSolidLine(lineProps)) {
          firstLine = this.drawSolidLine(points, lineProps, closed);
        } else if (canDrawDashedLine(lineProps)) {
          let factor = lineProps.factor;
          let pointPairs = [];
          let segmentSize = factor;
          if (closed) localPoints.push(localPoints[0].clone());
          let bigSegmentPts = [];
          let smallSegmentPts = [];

          for (let p = 0; p < localPoints.length - 1; p++) {
            let distance = localPoints[p].distanceTo(localPoints[p + 1]);

            if (distance > factor) {
              bigSegmentPts.push(localPoints[p]);
              bigSegmentPts.push(localPoints[p + 1]);
            } else {
              smallSegmentPts.push(localPoints[p]);
              smallSegmentPts.push(localPoints[p + 1]);
            }
          }

          for (let p = 0; p < bigSegmentPts.length - 1; p++) {
            let distance = bigSegmentPts[p].distanceTo(bigSegmentPts[p + 1]);
            if (distance > segmentSize) this.drawDashedLine(bigSegmentPts[p], bigSegmentPts[p + 1], factor, pointPairs);else segmentSize -= segmentSize - distance;
          }

          this.makeDashSegments(smallSegmentPts, factor);
          let newSegment = true;

          for (let p = 0; p < smallSegmentPts.length - 1; p++) {
            if (smallSegmentPts[p].insertedPoint) newSegment = !newSegment;
            if (newSegment) pointPairs.push([smallSegmentPts[p], smallSegmentPts[p + 1]]);
          }

          firstLine = this.mergeLines(pointPairs, lineProps);
        } else if (canDrawDottedLine(lineProps)) {
          let lineMaterial = this.getMaterialFromColorProps(lineProps.color);
          let factor = lineProps.factor;
          let radius = lineProps.thickness / 2;
          let circleGeos = [];
          let segmentSize = factor;

          if (closed) {
            localPoints.push(localPoints[0].clone());
          } ///


          let bigSegmentPts = [];
          let smallSegmentPts = [];

          for (let p = 0; p < localPoints.length - 1; p++) {
            let distance = localPoints[p].distanceTo(localPoints[p + 1]);

            if (distance > factor) {
              bigSegmentPts.push(localPoints[p]);
              bigSegmentPts.push(localPoints[p + 1]);
            } else {
              smallSegmentPts.push(localPoints[p]);
              smallSegmentPts.push(localPoints[p + 1]);
            }
          }

          this.makeDashSegments(smallSegmentPts, factor); ///

          smallSegmentPts.forEach(point => {
            if (point.insertedPoint) {
              let circle = new this.THREE.SphereGeometry(radius, 8, 8);
              circle.translate(point.x, point.y, point.z);
              circleGeos.push(circle);
            }
          });

          for (let p = 0; p < bigSegmentPts.length - 1; p++) {
            let distance = bigSegmentPts[p].distanceTo(bigSegmentPts[p + 1]);

            if (distance > 0) {
              if (distance > segmentSize) {
                let numOfSegments = Math.floor(distance / segmentSize);

                for (let i = 0; i < numOfSegments; i++) {
                  let newPoint = new this.THREE.Vector3().lerpVectors(bigSegmentPts[p], bigSegmentPts[p + 1], i / numOfSegments);
                  let circle = new this.THREE.SphereGeometry(radius, 8, 8);
                  circle.translate(newPoint.x, newPoint.y, newPoint.z);
                  circleGeos.push(circle);
                  if (segmentSize < factor) segmentSize = factor;
                }
              } else {
                segmentSize -= segmentSize - distance;
              }
            }
          }

          let firstCircle = circleGeos.splice(0, 1)[0];
          circleGeos.forEach(circle => firstCircle.merge(circle));
          firstLine = new this.THREE.Mesh(firstCircle, lineMaterial);
        }
      } else firstLine = new this.THREE.Mesh();

      return firstLine; // Helper functions -->

      function canDrawLine(lineProps) {
        return lineProps.thickness && lineProps.color.alpha && (lineProps.type || lineProps.type === undefined);
      }

      function canDrawSolidLine(lineProps) {
        return lineProps.type === 1 || lineProps.type === undefined || lineProps.factor === 0 && lineProps.type !== null && lineProps.type !== 0;
      }

      function canDrawDashedLine(lineProps) {
        return lineProps.type === 2;
      }

      function canDrawDottedLine(lineProps) {
        return lineProps.type === 3;
      } // <-- Helper functions

    }

    drawFatLine(pointsArr, lineProps) {
      const MIN_THICKNESS = 0.002;
      let thickness = lineProps.thickness * 5e-3;
      let {
        color,
        opacity
      } = this.getColorAndOpacity(lineProps.color);
      let lineGeoPoints = pointsArr.reduce((geoPoints, point) => {
        geoPoints.push(point.x, point.y, point.z);
        return geoPoints;
      }, []);
      let lineGeometry = new _LineGeometry.LineGeometry();
      lineGeometry.setPositions(lineGeoPoints);
      let lineMaterial = new _LineMaterial.LineMaterial({
        transparent: true,
        color,
        opacity,
        linewidth: thickness >= MIN_THICKNESS ? thickness : MIN_THICKNESS
      });
      let line = new _Line.Line2(lineGeometry, lineMaterial);
      line.scale.set(1, 1, 1);
      return line;
    }

    getColorAndOpacity(colorProps) {
      let color = "rgb(" + colorProps.red + ", " + colorProps.green + ", " + colorProps.blue + ")";
      let opacity = colorProps.alpha / 255;
      return {
        color,
        opacity
      };
    }

    alignObjectWithVectors(object, normalVector, directionVector) {
      let newUpVector = new this.THREE.Vector3().crossVectors(normalVector, directionVector);
      let dotProduct = object.up.dot(newUpVector);
      let rotationAngle = -Math.acos(dotProduct);
      object.rotateOnAxis(normalVector, rotationAngle);
      object.up = newUpVector;
      object.lookAt(normalVector.add(object.position));
    }

    getMeshUpVector(mesh, direction) {
      let upObject = new this.THREE.Object3D();
      upObject.position.copy(mesh.up);
      mesh.add(upObject);
      mesh.lookAt(direction);
      mesh.updateMatrixWorld();
      let upVector = upObject.getWorldPosition(new this.THREE.Vector3());
      return upVector;
    }

    makeTestMesh(position, size = 1, color = 0xff0000) {
      let geo = new this.THREE.BoxBufferGeometry(size, size, size);
      let mat = new this.THREE.MeshBasicMaterial({
        color
      });
      let mesh = new this.THREE.Mesh(geo, mat);
      mesh.position.copy(position);
      return mesh;
    }

    processUnits(unitsData) {
      let units = {
        length: "",
        angle: "",
        mass: "",
        force: "",
        temperature: ""
      };

      switch (unitsData.length) {
        case 1:
          units.length = "mm";
          break;

        case 2:
          units.length = "cm";
          break;

        case 3:
          units.length = "m";
          break;

        case 4:
          units.length = "km";
          break;

        default:
          break;
      }

      switch (unitsData.angle) {
        case 1:
          units.angle = "deg";
          break;

        case 2:
          units.angle = "rad";
          break;

        default:
          break;
      }

      switch (unitsData.mass) {
        case 1:
          units.mass = "g";
          break;

        case 2:
          units.mass = "kg";
          break;

        case 3:
          units.mass = "t";
          break;

        default:
          break;
      }

      switch (unitsData.force) {
        case 1:
          units.force = "N";
          break;

        case 2:
          units.force = "kN";
          break;

        case 3:
          units.force = "MN";
          break;

        default:
          break;
      }

      switch (unitsData.temperature) {
        case 1:
          units.temperature = "degC";
          break;

        case 2:
          units.temperature = "degF";
          break;

        default:
          break;
      }

      return units;
    }

  }

  _exports.Utils = Utils;
});