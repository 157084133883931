define("m08-2020/lib/ClippingUtils/OutlineGenerator", ["exports", "three"], function (_exports, THREE) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.OutlineGenerator = void 0;
  const TOLERANCE = 0.01;

  let _a = new THREE.Vector3();

  let _b = new THREE.Vector3();

  let _c = new THREE.Vector3();

  let _lineAB = new THREE.Line3();

  let _lineBC = new THREE.Line3();

  let _lineCA = new THREE.Line3();

  let _pointOfIntersection = new THREE.Vector3();

  let _pointsOfIntersection = new THREE.Geometry();

  class OutlineGenerator {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
    }

    drawStencilOutline(obj, mathPlane, lineProps) {
      obj.geometry.faces.forEach((face, idx) => {
        obj.localToWorld(_a.copy(obj.geometry.vertices[face.a]));
        obj.localToWorld(_b.copy(obj.geometry.vertices[face.b]));
        obj.localToWorld(_c.copy(obj.geometry.vertices[face.c]));
        _lineAB = new THREE.Line3(_a, _b);
        _lineBC = new THREE.Line3(_b, _c);
        _lineCA = new THREE.Line3(_c, _a);
        this.setPointOfIntersection(_lineAB, mathPlane, idx);
        this.setPointOfIntersection(_lineBC, mathPlane, idx);
        this.setPointOfIntersection(_lineCA, mathPlane, idx);
      });
      let lines = [];
      let vertices = _pointsOfIntersection.vertices;
      let facesDict = getFaceIndexMap(vertices);
      Object.values(facesDict).forEach(val => {
        if (val.length > 1) {
          let line = this.GraphicsThree3D.drawLine(val[0], val[1], lineProps);
          lines.push(line);
        }
      });
      return lines;
    }

    setPointOfIntersection(line, plane, faceIdx) {
      _pointOfIntersection = plane.intersectLine(line, new THREE.Vector3());

      if (_pointOfIntersection && !line.start.equals(_pointOfIntersection)) {
        let p = _pointOfIntersection.clone();

        p.faceIndex = faceIdx;
        p.checked = false;

        _pointsOfIntersection.vertices.push(p);
      }
    }

    getContours(points, contours) {
      let contour = []; // find first line for the contour

      let firstPointIndex = 0;
      let secondPointIndex = 0;
      let firstPoint, secondPoint;

      for (let i = 0; i < points.length; i++) {
        if (points[i].checked == true) continue;
        firstPointIndex = i;
        firstPoint = points[firstPointIndex];
        firstPoint.checked = true;
        secondPointIndex = this.getPairIndex(firstPoint, firstPointIndex, points);
        secondPoint = points[secondPointIndex];
        secondPoint.checked = true;
        contour.push(firstPoint.clone());
        contour.push(secondPoint.clone());
        break;
      }

      contour = this.getContour(secondPoint, points, contour);
      contours.push(contour);
      let allChecked = 0;
      points.forEach(p => {
        allChecked += p.checked == true ? 1 : 0;
      });

      if (allChecked != points.length) {
        return this.getContours(points, contours);
      }

      return contours;
    }

    getContour(currentPoint, points, contour) {
      let p1Index = this.getNearestPointIndex(currentPoint, points);
      let p1 = points[p1Index];
      p1.checked = true;
      let p2Index = this.getPairIndex(p1, p1Index, points);
      let p2 = points[p2Index];
      p2.checked = true;
      let isClosed = p2.equals(contour[0], TOLERANCE);

      if (!isClosed) {
        contour.push(p2.clone());
        return this.getContour(p2, points, contour);
      } else {
        contour.push(contour[0].clone());
        return contour;
      }
    }

    getNearestPointIndex(point, points) {
      let index = 0;

      for (let i = 0; i < points.length; i++) {
        let p = points[i];

        if (p.checked == false && p.equals(point, TOLERANCE)) {
          index = i;
          break;
        }
      }

      return index;
    }

    getPairIndex(point, pointIndex, points) {
      let index = 0;

      for (let i = 0; i < points.length; i++) {
        let p = points[i];

        if (i != pointIndex && p.checked == false && p.faceIndex == point.faceIndex) {
          index = i;
          break;
        }
      }

      return index;
    }

    clearPointsOfIntersection() {
      _pointOfIntersection = new THREE.Vector3();
      _pointsOfIntersection = new THREE.Geometry();
    }

    static generateCutLinePts(obj, mathPlane) {
      const SCALE_MULTIPLIER = 1 + 1e-4;
      const VERTEX_NUM = 3;
      let centroid = new THREE.Vector3();
      let keys = ["a", "b", "c"];
      let vertices = [new THREE.Vector3(), new THREE.Vector3(), new THREE.Vector3()];
      let lines = [new THREE.Line3(), new THREE.Line3(), new THREE.Line3()];
      let pointOfIntersection = new THREE.Vector3();
      let pointsOfIntersection = [];
      setFacePointsOfIntersection(obj.geometry.faces);
      let facesDict = getFaceIndexMap(pointsOfIntersection);
      let linePts = Object.values(facesDict).filter(ptPair => ptPair.length > 1);
      return linePts;

      function setFacePointsOfIntersection(faces) {
        faces.forEach((face, idx) => {
          updateVertices(face);

          for (let i = 0; i < VERTEX_NUM; i++) {
            lines[i].set(vertices[i % VERTEX_NUM], vertices[(i + 1) % VERTEX_NUM]);
            setPointOfIntersection(lines[i], mathPlane, idx);
          }
        });
      }

      function setPointOfIntersection(line, plane, faceIdx) {
        pointOfIntersection = plane.intersectLine(line, new THREE.Vector3());

        if (pointOfIntersection && !line.start.equals(pointOfIntersection)) {
          let p = pointOfIntersection.clone();
          p.faceIndex = faceIdx;
          p.checked = false;
          pointsOfIntersection.push(p);
        }
      }

      function updateVertices(face) {
        for (let i = 0; i < VERTEX_NUM; i++) obj.localToWorld(vertices[i].copy(obj.geometry.vertices[face[keys[i]]]));

        centroid.copy(getCentroid(vertices));

        for (let vec of vertices) vec.sub(centroid).multiplyScalar(SCALE_MULTIPLIER).add(centroid);
      }
    }

  }

  _exports.OutlineGenerator = OutlineGenerator;

  function getCentroid(vecArr) {
    let arrSize = vecArr.length;
    let sum = {
      x: 0,
      y: 0,
      z: 0
    };

    for (let vec of vecArr) {
      sum.x += vec.x;
      sum.y += vec.y;
      sum.z += vec.z;
    }

    return new THREE.Vector3(sum.x / arrSize, sum.y / arrSize, sum.z / arrSize);
  }

  function getFaceIndexMap(vertices) {
    let dict = {};
    vertices.forEach(vertex => {
      if (dict.hasOwnProperty(vertex.faceIndex)) dict[vertex.faceIndex].push(vertex);else dict[vertex.faceIndex] = [vertex];
    });
    return dict;
  }
});