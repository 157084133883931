define("m08-2020/lib/FigureLoaders/DrawingObjectComponents/Ellipse", ["exports", "m08-2020/lib/ThreeExtensions/RotatedGeometry", "m08-2020/lib/ThreeExtensions/PlanarRotationPointsGenerator", "m08-2020/lib/ThreeExtensions/PlanarExtrusionPointsGenerator", "m08-2020/lib/FigureLoaders/DrawingObjectComponents/Ellipsoid"], function (_exports, _RotatedGeometry, _PlanarRotationPointsGenerator, _PlanarExtrusionPointsGenerator, _Ellipsoid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Ellipse = void 0;

  class Ellipse {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.THREE = GraphicsThree3D.THREE;
      this.utils = GraphicsThree3D.utils;
      this.CSG = GraphicsThree3D.CSG;
      this.WireframeGenerator = GraphicsThree3D.WireframeGenerator;
      this.Ellipsoid = new _Ellipsoid.Ellipsoid(GraphicsThree3D);
    }

    drawEllipse(ellipseData) {
      let ellipseType = ellipseData.objectGeneration.type;
      let ellipseMesh;

      if (ellipseType === 1 || ellipseType === 3 && !ellipseData.objectGeneration.rotationAngle) {
        ellipseMesh = this.drawEllipsePlanar(ellipseData);
        ellipseMesh.generationType = "area";
      } else if (ellipseType === 2) {
        if (this.GraphicsThree3D.objectExtrudesPlanar(ellipseData, true)) {
          ellipseMesh = this.makeEllipseExtrudedArea(ellipseData);
          ellipseMesh.generationType = "area";
        } else {
          ellipseMesh = this.makeEllipseExtrudedVolume(ellipseData);
          ellipseMesh.generationType = "volume";
        }
      } else if (ellipseType === 3) {
        let rotatesPlanar = this.GraphicsThree3D.objectRotatesPlanar(ellipseData, true);
        let isPlanar = rotatesPlanar && !ellipseData.objectGeneration.rotationPitch;
        let isPlanarWithPitch = rotatesPlanar && ellipseData.objectGeneration.rotationPitch;

        if (isPlanar) {
          ellipseMesh = this.makeEllipseRotatedArea(ellipseData);
          ellipseMesh.generationType = "area";
        } else {
          ellipseMesh = this.makeEllipseRotatedVolume(ellipseData, isPlanarWithPitch);
          ellipseMesh.generationType = "volume";
        }
      }

      return ellipseMesh;
    }

    drawEllipsePlanar(ellipseData) {
      let centerPoint = this.utils.initVector3(ellipseData.centerPoint);
      let extrusionDepth = 0.1;
      let extrusionVector = new this.THREE.Vector3(0, 0, extrusionDepth);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let pts = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData);
      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(pts, extrusionNormalized);
      pts.forEach(point => {
        // point.sub( midVector );
        point.applyEuler(eulerOne);
      });
      let shapePts = pts.map(point => new this.THREE.Vector2(point.x, point.y));
      let ellipseShape = new this.THREE.Shape(shapePts);
      extrusionVector.applyEuler(eulerOne);
      extrusionNormalized.applyEuler(eulerOne);
      let extrudeSettings = {
        depth: extrusionDepth,
        bevelEnabled: false
      };
      let ellipseGeo = new this.THREE.ExtrudeGeometry(ellipseShape, extrudeSettings); // ellipseGeo.translate(0, 0, -extrusionVector.z);

      let ellipse = new this.THREE.Mesh(ellipseGeo); // ellipse.position.add( midVector );
      // ellipse.updateMatrix();

      ellipse.setRotationFromEuler(eulerTwo);
      ellipse.position.add(centerPoint);
      ellipse.updateMatrix();
      return ellipse;
    }

    makeEllipseExtrudedArea(ellipseData) {
      const Z_AXIS = new this.THREE.Vector3(0, 0, 1);
      let curvePoints = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData);
      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(curvePoints, Z_AXIS);
      curvePoints.forEach(point => point.applyEuler(eulerOne));
      let extrusionVector = this.utils.initVector3(ellipseData.objectGeneration.extrusionVector).applyEuler(eulerOne);
      let shapePts = new _PlanarExtrusionPointsGenerator.PlanarExtrusionPointsGenerator(curvePoints, extrusionVector).extrudedPoints;
      let curveShape = new this.THREE.Shape(shapePts);
      let curveGeo = new this.THREE.ExtrudeGeometry(curveShape, {
        depth: -0.01,
        bevelEnabled: false
      });
      curveGeo.translate(ellipseData.centerPoint.x, ellipseData.centerPoint.y, ellipseData.centerPoint.z);
      let ellipse = new this.THREE.Mesh(curveGeo);
      ellipse.setRotationFromEuler(eulerTwo);
      ellipse.updateMatrix();
      return ellipse;
    }

    makeEllipseExtrudedVolume(ellipseData) {
      let centerPoint = this.utils.initVector3(ellipseData.centerPoint);
      let extrusionVector = this.utils.initVector3(ellipseData.objectGeneration.extrusionVector);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let skewX = extrusionNormalized.x / extrusionNormalized.z;
      let skewY = extrusionNormalized.y / extrusionNormalized.z;
      let curvePoints = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData); // this.WireframeGenerator.getPlanarPoints(curvePoints.map(point => point.clone().add(centerPoint)), ellipseData.objectType);

      let curveShape = new this.THREE.Shape(curvePoints);
      let curveGeo = new this.THREE.ExtrudeGeometry(curveShape, {
        depth: extrusionVector.z,
        bevelEnabled: false
      }); // curveGeo.translate(0, 0, extrusionVector.z);

      curveGeo.applyMatrix4(new this.THREE.Matrix4().set(1, 0, skewX, 0, 0, 1, skewY, 0, 0, 0, 1, 0, 0, 0, 0, 1));
      let ellipse = new this.THREE.Mesh(curveGeo);
      ellipse.position.copy(centerPoint);
      ellipse.updateMatrix(); ///

      let linePairs = [];

      for (let i = 0, ptsLen = curvePoints.length; i < ptsLen; i++) {
        let ptOne = curvePoints[i % ptsLen].clone().add(centerPoint);
        let ptTwo = curvePoints[(i + 1) % ptsLen].clone().add(centerPoint);
        linePairs.push([ptOne, ptTwo]);
        let ptOneExtr = ptOne.clone().add(extrusionVector);
        let ptTwoExtr = ptTwo.clone().add(extrusionVector);
        linePairs.push([ptOneExtr, ptTwoExtr]);
        linePairs.push([ptOne, ptOneExtr]);
      } // this.WireframeGenerator.getRegularWireframePointPairs( ellipse, Math.PI / 720 )
      //                        .forEach( pair => linePairs.push([
      //                           pair[ 0 ].clone().applyMatrix4( ellipse.matrix ),
      //                           pair[ 1 ].clone().applyMatrix4( ellipse.matrix )
      //                         ]));


      let linePairsForOpaque = [];
      this.WireframeGenerator.getRegularWireframePointPairs(ellipse, 20 * Math.PI / 180).forEach(pair => linePairsForOpaque.push([pair[0].clone().applyMatrix4(ellipse.matrix), pair[1].clone().applyMatrix4(ellipse.matrix)]));
      this.WireframeGenerator.getVolumeWireframeData(ellipse, linePairsForOpaque, linePairs, ellipseData.objectType); ///

      return ellipse;
    }

    makeEllipseRotatedArea(ellipseData) {
      // TODO: Maybe need to implement the same logic as in polygon planar rotation?
      const DELTA = 0.0001;
      let centerPoint = ellipseData.centerPoint;
      let rotationPoint = ellipseData.objectGeneration.rotationPoint;
      let rotationAngle = ellipseData.objectGeneration.rotationAngle * Math.PI / 180;
      let rotationPitch = ellipseData.objectGeneration.rotationPitch;
      let rotationAxis = this.utils.initVector3(ellipseData.objectGeneration.rotationAxis);
      let centerVector = this.utils.initVector3(centerPoint);
      let rotationVector = this.utils.initVector3(rotationPoint).sub(centerVector);
      let curvePoints = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData).map(point => point.sub(rotationVector));
      let zAxis = new this.THREE.Vector3(0, 0, 1);
      let [_, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(curvePoints, zAxis);
      if (curvePoints[0].distanceTo(curvePoints[curvePoints.length - 1]) < DELTA) curvePoints.pop();
      curvePoints.forEach(point => point.applyEuler(eulerOne));
      let zValue = curvePoints[0].z;
      if (rotationAxis.applyEuler(eulerOne).dot(zAxis) < 0) rotationAngle *= -1;
      let rotationPtsGen = new _PlanarRotationPointsGenerator.PlanarRotationPointsGenerator(curvePoints, {
        rotationAngle,
        rotationPitch
      });
      curvePoints = rotationPtsGen.rotatedPoints;
      let holePtsArr = rotationPtsGen.holePoints;
      let ellipse = this.drawExtrudedEllipseMesh(curvePoints, holePtsArr, -0.01);
      ellipse.geometry.translate(rotationPoint.x, rotationPoint.y, rotationPoint.z + zValue);
      ellipse.setRotationFromEuler(eulerTwo);
      return ellipse;
    }

    drawExtrudedEllipseMesh(shapePts, holePtsArr, extrDepth) {
      let curveShape = new this.THREE.Shape(shapePts);
      holePtsArr.forEach(holePts => {
        if (holePts.length > 2) curveShape.holes.push(new this.THREE.Path(holePts));
      });
      let curveGeo = new this.THREE.ExtrudeGeometry(curveShape, {
        depth: extrDepth,
        bevelEnabled: false
      });
      return new this.THREE.Mesh(curveGeo);
    }

    makeEllipseRotatedVolume(ellipseData, isPlanarWithPitch) {
      let ellipse;

      if (this.rotationAxisDefined(ellipseData.objectGeneration.rotationAxis)) {
        let centerPoint = this.utils.initVector3(ellipseData.centerPoint);
        let rotationPoint = this.utils.initVector3(ellipseData.objectGeneration.rotationPoint);
        let rotationAxis = this.utils.initVector3(ellipseData.objectGeneration.rotationAxis).normalize();
        let rotationAngle = ellipseData.objectGeneration.rotationAngle * Math.PI / 180;
        let rotationPitch = ellipseData.objectGeneration.rotationPitch;
        let rotationRadius = centerPoint.distanceTo(rotationPoint);

        if (this.generatesEllipsoid(rotationRadius, rotationAngle, rotationPitch)) {
          let ellipsoidData = this.generateEllipsoidData(ellipseData, rotationAxis);
          ellipse = this.Ellipsoid.drawEllipsoid(ellipsoidData);
        } else {
          let pts = this.GraphicsThree3D.getEllipsePlanarPoints(ellipseData).map(pt => pt.add(centerPoint));
          let rotationParams = {
            rotationPoint: this.utils.initVector3(ellipseData.objectGeneration.rotationPoint),
            rotationAxis: this.utils.initVector3(ellipseData.objectGeneration.rotationAxis).normalize(),
            rotationAngle: ellipseData.objectGeneration.rotationAngle * Math.PI / 180,
            rotationPitch: ellipseData.objectGeneration.rotationPitch
          };
          let rotatedGeometry = new _RotatedGeometry.RotatedGeometry(pts.map(pt => pt.clone()), rotationParams);
          ellipse = new this.THREE.Mesh(rotatedGeometry);
          ellipse.updateMatrix();
          this.addToWireframePts3D(ellipse, ellipseData, pts, rotationParams, isPlanarWithPitch);
        }
      } else ellipse = new this.THREE.Mesh();

      return ellipse;
    }

    addToWireframePts3D(ellipseMesh, ellipseData, ellipsePts, rotationParams, isPlanarWithPitch) {
      let {
        rotationPoint,
        rotationAxis,
        rotationAngle,
        rotationPitch
      } = rotationParams;
      let pitchVec = rotationAxis.clone().multiplyScalar(rotationPitch * Math.abs(rotationAngle) / (2 * Math.PI));
      let wireframePts = [...ellipsePts, ellipsePts[0]];
      let linePairs;

      if (isPlanarWithPitch) {
        linePairs = [];

        for (let i = 0, l = wireframePts.length - 1; i < l; i++) {
          let pair = [wireframePts[i], wireframePts[i + 1]];
          linePairs.push(pair);
          let rotatedPoints = pair.map(pt => pt.clone().sub(rotationPoint).applyAxisAngle(rotationAxis, rotationAngle).add(rotationPoint).add(pitchVec));
          linePairs.push(rotatedPoints);
        }

        for (let point of wireframePts) {
          let sidePoints = this.getSidePoints(point, rotationParams);
          linePairs.push([point, sidePoints[0]]);

          for (let j = 0; j < sidePoints.length - 1; j++) linePairs.push([sidePoints[j], sidePoints[j + 1]]);
        }
      } else {
        linePairs = this.WireframeGenerator.getRegularWireframePointPairs(ellipseMesh, Math.PI / 180).map(pair => [pair[0].clone().applyMatrix4(ellipseMesh.matrix), pair[1].clone().applyMatrix4(ellipseMesh.matrix)]);
      }

      let linePairsForOpaque = [];

      for (let i = 0, l = wireframePts.length - 1; i < l; i++) {
        linePairsForOpaque.push([wireframePts[i], wireframePts[i + 1]]);
        let rotatedPoints = [wireframePts[i], wireframePts[i + 1]].map(pt => pt.clone().sub(rotationPoint).applyAxisAngle(rotationAxis, rotationAngle).add(rotationPoint).add(pitchVec));
        linePairsForOpaque.push(rotatedPoints);
      }

      if (ellipseData.segmentVector1 && ellipseData.segmentVector2) {
        let ptsToRotate = [];
        ptsToRotate.push(ellipsePts[ellipsePts.length - 1], ellipsePts[ellipsePts.length - 2]);
        ptsToRotate.push(ellipsePts[0]);
        if (ellipseData.segmentHeight) ptsToRotate.push(ellipsePts[1]);

        for (let point of ptsToRotate) {
          let sidePoints = this.getSidePoints(point, rotationParams);
          linePairsForOpaque.push([point, sidePoints[0]]);

          for (let j = 0; j < sidePoints.length - 1; j++) linePairsForOpaque.push([sidePoints[j], sidePoints[j + 1]]);
        }
      }

      this.WireframeGenerator.getVolumeWireframeData(ellipseMesh, linePairsForOpaque, linePairs, ellipseData.objectType);
    }

    getSidePoints(point, rotationParams) {
      const DETAIL = 64;
      let {
        rotationPoint,
        rotationAxis,
        rotationAngle,
        rotationPitch
      } = rotationParams;
      let numOfSegments = DETAIL * Math.abs(rotationAngle) / (2 * Math.PI);
      let pitchVector = new this.THREE.Vector3();
      let sidePoints = [];

      for (let i = 0; i < numOfSegments; i++) {
        let rotatedPoint = new this.THREE.Vector3().copy(point).sub(rotationPoint).applyAxisAngle(rotationAxis, (i + 1) * rotationAngle / numOfSegments).add(rotationPoint).add(pitchVector.copy(rotationAxis).multiplyScalar((i + 1) * rotationPitch / numOfSegments / 2));
        sidePoints.push(rotatedPoint);
      }

      return sidePoints;
    }

    rotationAxisDefined(rotationAxis) {
      return rotationAxis && (rotationAxis.x || rotationAxis.y || rotationAxis.z);
    }

    generatesEllipsoid(rotationRadius, rotationAngle, rotationPitch) {
      return rotationRadius <= 0.01 && !rotationPitch && rotationAngle >= Math.PI;
    }

    getProjectedVectorLengthSquared(initialVec, targetVec, targetLen) {
      let projectedVectorLengthSquared = initialVec.clone().projectOnVector(targetVec).multiplyScalar(targetLen).lengthSq();
      return projectedVectorLengthSquared;
    }

    generateEllipsoidData(ellipseData, rotationAxis) {
      let dir1 = this.utils.initVector3(ellipseData.directionVector1),
          dir2 = this.utils.initVector3(ellipseData.directionVector2);
      let dir3 = new this.THREE.Vector3().crossVectors(dir1, dir2).normalize();
      let projOneLenSq = this.getProjectedVectorLengthSquared(rotationAxis, dir1, ellipseData.radius1);
      let projTwoLenSq = this.getProjectedVectorLengthSquared(rotationAxis, dir2, ellipseData.radius2);
      let radius3 = Math.sqrt(projOneLenSq + projTwoLenSq);
      let ellipsoidData = { ...ellipseData,
        radius3,
        directionVector3: dir3
      };
      return ellipsoidData;
    }

  }

  _exports.Ellipse = Ellipse;
});