define("m08-2020/lib/font_urls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FONT_URLS = void 0;
  const FONT_URLS = {
    Regular: {
      Arial: "assets/fonts/Arial_Regular.json",
      Calibri: "assets/fonts/Calibri_Regular.json",
      Courier: "assets/fonts/Courier New_Regular.json",
      TimesNewRoman: "assets/fonts/Times New Roman_Regular.json"
    },
    Italic: {
      Arial: "assets/fonts/Arial_Italic.json",
      Calibri: "assets/fonts/Calibri_Italic.json",
      Courier: "assets/fonts/Courier New_Italic.json",
      TimesNewRoman: "assets/fonts/Times New Roman_Italic.json"
    },
    Bold: {
      Arial: "assets/fonts/Arial_Bold.json",
      Calibri: "assets/fonts/Calibri_Bold.json",
      Courier: "assets/fonts/Courier New_Bold.json",
      TimesNewRoman: "assets/fonts/Times New Roman_Bold.json"
    },
    BoldItalic: {
      Arial: "assets/fonts/Arial_Bold Italic.json",
      Calibri: "assets/fonts/Calibri_Bold Italic.json",
      Courier: "assets/fonts/Courier New_Bold Italic.json",
      TimesNewRoman: "assets/fonts/Times New Roman_Bold Italic.json"
    }
  };
  _exports.FONT_URLS = FONT_URLS;
});