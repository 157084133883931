define("m08-2020/lib old/FigureLoaders/ArrowLoader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ArrowLoader = void 0;

  class ArrowLoader {
    constructor(THREE, fontUrlArray, GraphicsThree3D) {
      this.THREE = THREE;
      this.fontUrlArray = fontUrlArray;
      this.GraphicsThree3D = GraphicsThree3D;
      this.utils = GraphicsThree3D.utils;
      this.lineRenderOrder = -103; ///

      this.drawArrows = this.drawArrows.bind(this);
    }

    drawArrows(arrowData) {
      let meshArray = [];
      let materialProps = {
        lineProps: arrowData.properties.line,
        volumeProps: arrowData.properties.volume,
        textProps: arrowData.properties.text,
        areaProps: arrowData.properties.area
      };
      let insertionPoints = arrowData.insertionPoints;
      let forceArrows = arrowData.components.forceArrows;
      let momentArrows = arrowData.components.momentArrows;
      let grainDirectionArrows = arrowData.components.grainDirectionArrows;

      if (forceArrows) {
        forceArrows.forEach(forceArrow => meshArray.push(this.drawForceArrow(forceArrow, materialProps, insertionPoints, arrowData)));
      }

      if (momentArrows) {
        momentArrows.forEach(momentArrow => meshArray.push(this.drawMomentArrows(momentArrow, materialProps, insertionPoints, arrowData)));
      }

      if (grainDirectionArrows) {
        grainDirectionArrows.forEach(grainDirectionArrow => meshArray.push(this.drawGrainDirectionArrow(grainDirectionArrow, materialProps, insertionPoints, arrowData)));
      }

      meshArray.forEach(arrow => this.GraphicsThree3D.addObjectToVisControl(arrow, "arrow"));
      return meshArray;
    }

    addToDimChainCollisionDetection(obj) {
      obj.updateMatrix();
      obj.traverse(child => {
        if (child.type === "Mesh") {
          let intersectionObject = child.clone();
          intersectionObject.applyMatrix4(obj.matrix);
          let intersectionObjects = this.GraphicsThree3D.getThreeSideFaceObjects(intersectionObject);
          this.GraphicsThree3D.DimensionalChainIntersectionDetection.meshArr.push(intersectionObjects);
        }
      });
    }

    drawGrainDirectionArrow(grainDirectionArrowData, materialProps, insertionPoints, arrowData) {
      let insertionPoint = grainDirectionArrowData.insertionPoint;
      let grainDirectionArrows = new this.THREE.Group();

      if (grainDirectionArrowData.directionVector && grainDirectionArrowData.normalVector && (grainDirectionArrowData.directionVector.x || grainDirectionArrowData.directionVector.y || grainDirectionArrowData.directionVector.z) && (grainDirectionArrowData.normalVector.x || grainDirectionArrowData.normalVector.y || grainDirectionArrowData.normalVector.z)) {
        let directionVector = this.utils.initVector3(grainDirectionArrowData.directionVector);
        let normalVector = this.utils.initVector3(grainDirectionArrowData.normalVector);

        if (!directionVector.dot(normalVector) && grainDirectionArrowData.height > 0 && grainDirectionArrowData.length.value1 > 0 && grainDirectionArrowData.length.value2 > 0) {
          let mainLength = grainDirectionArrowData.length.value1;
          let miniLength = grainDirectionArrowData.length.value2;
          let miniHeight = grainDirectionArrowData.height / 2;
          let mainPointLeft = new this.THREE.Vector3(-mainLength / 2, 0, 0);
          let mainPointRight = new this.THREE.Vector3(mainLength / 2, 0, 0);
          let miniPointLeft = mainPointLeft.clone().add(new this.THREE.Vector3(miniLength, miniHeight, 0));
          let miniPointRight = mainPointRight.clone().add(new this.THREE.Vector3(-miniLength, -miniHeight, 0));
          insertionPoints.forEach(point => {
            let grainDirectionArrow = new this.THREE.Object3D();

            if (insertionPoint) {
              let arrowMesh = this.utils.drawConnectedLines([miniPointLeft, mainPointLeft, mainPointRight, miniPointRight], materialProps.lineProps);
              let newUpVector = new this.THREE.Vector3().crossVectors(normalVector, directionVector);
              let dotProduct = arrowMesh.up.dot(newUpVector);
              let rotationAngle = -Math.acos(dotProduct); // by default THREE.js rotates counterclockwise, so I switched sign (correct?)

              arrowMesh.rotateOnAxis(normalVector, rotationAngle);
              arrowMesh.up = newUpVector;
              arrowMesh.lookAt(arrowMesh.position.clone().add(normalVector));
              grainDirectionArrow.add(arrowMesh);
              grainDirectionArrow.position.set(insertionPoint.x, insertionPoint.y, insertionPoint.z);
            }

            if (materialProps.textProps) {
              this.addTextToArrow(materialProps.textProps, arrowData.components.texts, grainDirectionArrow, insertionPoint);
            }

            let insertionVector = point.insertionVector;
            let angle = point.rotationAngle * Math.PI / 180;
            let rotationAxis, rotationPoint;

            if (angle) {
              rotationAxis = this.utils.initVector3(point.rotationAxis);
              rotationPoint = this.utils.initVector3(point.rotationPoint);
            }

            let pivotPoint = new this.THREE.Object3D();

            if (angle) {
              pivotPoint.position.copy(rotationPoint);
              pivotPoint.attach(grainDirectionArrow);
              pivotPoint.rotateOnAxis(rotationAxis, angle);
            } else {
              pivotPoint.attach(grainDirectionArrow);
            }

            pivotPoint.position.x += insertionVector.x;
            pivotPoint.position.y += insertionVector.y;
            pivotPoint.position.z += insertionVector.z;
            this.addToDimChainCollisionDetection(grainDirectionArrow);
            grainDirectionArrows.add(pivotPoint);
          });
        }
      }

      return grainDirectionArrows;
    }

    drawMomentArrows(momentArrowData, materialProps, insertionPoints, arrowData) {
      let insertionPoint = momentArrowData.insertionPoint;
      let headRadius = momentArrowData.headSize.value2 / 2;
      let headHeight = momentArrowData.headSize.value1;
      let size = momentArrowData.size;
      let arcAngle = momentArrowData.arcAngle;
      let volumeMaterial = this.getMaterialFromColor(materialProps.volumeProps.color);
      let lineMaterial = this.getMaterialFromColor(materialProps.lineProps.color); // TODO: Move this for loop outside this function

      let momentArrows = new this.THREE.Group();
      insertionPoints.forEach(point => {
        let insertionVector = point.insertionVector;
        let rotationAngle = point.rotationAngle * Math.PI / 180;
        let rotationAxis, rotationPoint;

        if (rotationAngle) {
          rotationAxis = this.utils.initVector3(point.rotationAxis);
          rotationPoint = this.utils.initVector3(point.rotationPoint);
        }

        let momentArrow = new this.THREE.Object3D();

        if (insertionPoint) {
          if (!arcAngle && momentArrowData.directionVector1 && (momentArrowData.directionVector1.x || momentArrowData.directionVector1.y || momentArrowData.directionVector1.z)) {
            let directionVectorOne = this.utils.initVector3(momentArrowData.directionVector1);
            let pointer = this.makeArrow(headRadius, headHeight, volumeMaterial, lineMaterial, materialProps.lineProps);
            pointer.rotation.z = Math.PI;
            let pointerTwo = pointer.clone();
            pointer.position.y += headHeight;
            let arrow = new this.THREE.Object3D();
            let axisPointOne = new this.THREE.Vector3(0, 0, 0);
            let axisPointTwo = new this.THREE.Vector3(0, size, 0);
            let axisMesh = this.GraphicsThree3D.drawLine(axisPointOne, axisPointTwo, materialProps.lineProps);
            arrow.add(axisMesh);
            arrow.add(pointer);
            arrow.add(pointerTwo);
            arrow.rotation.x = -Math.PI / 2;
            let pivot = new this.THREE.Object3D();
            pivot.add(arrow);
            pivot.lookAt(directionVectorOne);
            momentArrow.add(pivot);
          } else if (arcAngle && momentArrowData.directionVector1 && momentArrowData.directionVector2 && (momentArrowData.directionVector1.x || momentArrowData.directionVector1.y || momentArrowData.directionVector1.z) && (momentArrowData.directionVector2.x || momentArrowData.directionVector2.y || momentArrowData.directionVector2.z)) {
            let directionVectorOne = this.utils.initVector3(momentArrowData.directionVector1);
            let directionVectorTwo = this.utils.initVector3(momentArrowData.directionVector2);

            if (!directionVectorOne.dot(directionVectorTwo)) {
              let pointer = this.makeArrow(headRadius, headHeight, volumeMaterial, lineMaterial, materialProps.lineProps, true);
              pointer.rotation.z = Math.PI;
              console.log(arcAngle);
              let correctedArcAngle = arcAngle;
              if (correctedArcAngle > 360 || correctedArcAngle < -360) materialProps.lineProps.thickness = 0;
              if (correctedArcAngle > 360) correctedArcAngle = 360;
              if (correctedArcAngle < -360) correctedArcAngle = -360;
              let curveObject = new this.THREE.Object3D();
              let curveAngle = correctedArcAngle * Math.PI / 180;
              let startAngle, endAngle;

              if (curveAngle > 0) {
                startAngle = 0;
                endAngle = curveAngle;
              } else {
                startAngle = curveAngle;
                endAngle = 0;
              }

              let curve = new this.THREE.EllipseCurve(0, 0, size / 2, size / 2, startAngle, endAngle, false, 0);
              let curvePoints = curve.getPoints(50).map(point => new this.THREE.Vector3(point.x, point.y, 0));
              let curveMesh = this.utils.drawConnectedLines(curvePoints, materialProps.lineProps);
              let pointDir = new this.THREE.Vector3();

              if (curveAngle > 0) {
                for (let i = curvePoints.length - 2; i >= 0; i--) {
                  let distance = Math.round(curvePoints[curvePoints.length - 1].distanceTo(curvePoints[i]));

                  if (distance == Math.round(headHeight)) {
                    pointDir.subVectors(curvePoints[curvePoints.length - 1], curvePoints[i]).normalize();
                    break;
                  }
                }

                pointer.rotation.x = -Math.PI / 2;
                let object = new this.THREE.Object3D();
                object.add(pointer);
                object.lookAt(pointDir);
                object.position.x += curvePoints[curvePoints.length - 1].x;
                object.position.y += curvePoints[curvePoints.length - 1].y;
                pointer = object;
              } else {
                for (let i = 0; i < curvePoints.length; i++) {
                  let distance = Math.round(curvePoints[0].distanceTo(curvePoints[i]));

                  if (distance == Math.round(headHeight)) {
                    pointDir.subVectors(curvePoints[0], curvePoints[i]).normalize();
                    break;
                  }
                }

                pointer.rotation.x = -Math.PI / 2;
                let object = new this.THREE.Object3D();
                object.add(pointer);
                object.lookAt(pointDir);
                object.position.x += curvePoints[0].x;
                object.position.y += curvePoints[0].y;
                pointer = object;
              }

              curveObject.add(curveMesh);
              curveObject.add(pointer);
              this.GraphicsThree3D.alignObjectWithVectors(curveObject, new this.THREE.Vector3(0, 0, 0), directionVectorOne, directionVectorTwo);
              momentArrow.add(curveObject);
            }
          }

          momentArrow.position.set(insertionPoint.x, insertionPoint.y, insertionPoint.z);
        }

        if (materialProps.textProps) {
          this.addTextToArrow(materialProps.textProps, arrowData.components.texts, momentArrow, insertionPoint);
        }

        let pivotPoint = new this.THREE.Object3D();

        if (rotationAngle) {
          pivotPoint.position.copy(rotationPoint);
          pivotPoint.attach(momentArrow);
          pivotPoint.rotateOnAxis(rotationAxis, rotationAngle);
        } else {
          pivotPoint.attach(momentArrow);
        }

        pivotPoint.position.x += insertionVector.x;
        pivotPoint.position.y += insertionVector.y;
        pivotPoint.position.z += insertionVector.z;
        this.addToDimChainCollisionDetection(momentArrow);
        momentArrows.add(pivotPoint);
      });
      return momentArrows;
    }

    drawForceArrow(forceArrowData, materialProps, insertionPoints, arrowData) {
      let forceArrows = new this.THREE.Group();
      let headSize = forceArrowData.headSize;
      let height = forceArrowData.height;
      let length = forceArrowData.length;
      let insertionProps = {
        insertionPoint: forceArrowData.insertionPoint,
        directionVectorOne: forceArrowData.directionVector1,
        directionVectorTwo: forceArrowData.directionVector2,
        directionVectorThree: forceArrowData.directionVector3
      };
      let division = forceArrowData.division;
      let tiltAngle = forceArrowData.tiltAngle;
      insertionPoints.forEach(point => {
        let insertionVector = this.utils.initVector3(point.insertionVector);
        let rotationAngle = point.rotationAngle * Math.PI / 180;
        let rotationAxis, rotationPoint;

        if (rotationAngle) {
          rotationAxis = this.utils.initVector3(point.rotationAxis);
          rotationPoint = this.utils.initVector3(point.rotationPoint);
        }

        let newArrow;
        if (!length.value1 && !length.value2) newArrow = this.drawPointForceArrow(forceArrowData, headSize, height, materialProps, insertionProps, arrowData);else if (length.value1 > 0 || length.value2 > 0) newArrow = this.drawLineForceArrow(forceArrowData, materialProps, insertionProps, division, tiltAngle, arrowData);
        let pivotPoint = new this.THREE.Object3D();

        if (rotationAngle) {
          pivotPoint.position.copy(rotationPoint);
          pivotPoint.attach(newArrow);
          pivotPoint.rotateOnAxis(rotationAxis, rotationAngle);
        } else {
          pivotPoint.attach(newArrow);
        }

        pivotPoint.position.add(insertionVector);
        forceArrows.add(pivotPoint);
      });
      return forceArrows;
    }

    drawLineForceArrow(forceArrowData, materialProps, insertionProps, division, tiltAngle, arrowData) {
      // TODO: Will need directionVectors
      let pointerRadius = forceArrowData.headSize.value2 / 2;
      let pointerHeight = forceArrowData.headSize.value1;
      let offsetAngle = tiltAngle * Math.PI / 180;
      let horizontalLength = forceArrowData.length.value1;
      let depthLength = forceArrowData.length.value2;
      let heightLeft = forceArrowData.height.value1;
      let heightRight = forceArrowData.height.value2;
      let lineProps = materialProps.lineProps;
      let volumeMaterial = this.getMaterialFromColor(materialProps.volumeProps.color);
      let borderMaterial = this.getMaterialFromColor(lineProps.color);
      let areaMaterial;
      if (materialProps.areaProps && materialProps.areaProps.color.alpha) areaMaterial = this.getMaterialFromColor(materialProps.areaProps.color);
      let lineForceArrow = new this.THREE.Object3D();
      let directionsDefined = forceArrowData.directionVector1 && forceArrowData.directionVector2 && forceArrowData.directionVector3 && (forceArrowData.directionVector1.x || forceArrowData.directionVector1.y || forceArrowData.directionVector1.z) && (forceArrowData.directionVector2.x || forceArrowData.directionVector2.y || forceArrowData.directionVector2.z) && (!(heightLeft && heightRight) || forceArrowData.directionVector3.x || forceArrowData.directionVector3.y || forceArrowData.directionVector3.z);
      let areOrthogonal = false;
      let dirVectorNormal, dirVectorHorizontal;

      if (directionsDefined) {
        if (depthLength) {
          dirVectorHorizontal = this.utils.initVector3(forceArrowData.directionVector2).normalize();
          dirVectorNormal = this.utils.initVector3(forceArrowData.directionVector3).normalize();
          let vectorOne = this.utils.initVector3(forceArrowData.directionVector1).normalize();
          if (!dirVectorHorizontal.dot(dirVectorNormal) && !vectorOne.dot(dirVectorHorizontal) && !vectorOne.dot(dirVectorNormal)) areOrthogonal = true;
        } else {
          dirVectorHorizontal = this.utils.initVector3(forceArrowData.directionVector2).normalize();
          dirVectorNormal = new this.THREE.Vector3().crossVectors(dirVectorHorizontal, this.utils.initVector3(forceArrowData.directionVector1).negate().normalize());
          if (!dirVectorHorizontal.dot(dirVectorNormal)) areOrthogonal = true;
        }

        if (areOrthogonal && insertionProps.insertionPoint && !(depthLength && !horizontalLength) && tiltAngle > -90 && tiltAngle < 90) {
          let botAxisPointOne = new this.THREE.Vector3(0, 0, 0);
          let botAxisPointTwo = new this.THREE.Vector3(horizontalLength * Math.cos(offsetAngle), horizontalLength * Math.sin(offsetAngle), 0);
          let topAxisPointOne = new this.THREE.Vector3(0, heightLeft, 0);
          let topAxisPointTwo = new this.THREE.Vector3(horizontalLength * Math.cos(offsetAngle), horizontalLength * Math.sin(offsetAngle) + heightRight, 0);
          let botLine = this.GraphicsThree3D.drawLine(botAxisPointOne, botAxisPointTwo, lineProps);
          let topLine = this.GraphicsThree3D.drawLine(topAxisPointOne, topAxisPointTwo, lineProps);
          botLine.renderOrder = this.lineRenderOrder;
          topLine.renderOrder = this.lineRenderOrder;
          lineForceArrow.add(botLine);
          lineForceArrow.add(topLine);

          if (depthLength > 0) {
            let depthBotAxisPointOne = botAxisPointOne.clone().add(new this.THREE.Vector3(0, 0, depthLength));
            let depthBotAxisPointTwo = botAxisPointTwo.clone().add(new this.THREE.Vector3(0, 0, depthLength));
            let depthTopAxisPointOne = topAxisPointOne.clone().add(new this.THREE.Vector3(0, 0, depthLength));
            let depthTopAxisPointTwo = topAxisPointTwo.clone().add(new this.THREE.Vector3(0, 0, depthLength));
            let depthBotLine = this.GraphicsThree3D.drawLine(depthBotAxisPointOne, depthBotAxisPointTwo, lineProps);
            let depthTopLine = this.GraphicsThree3D.drawLine(depthTopAxisPointOne, depthTopAxisPointTwo, lineProps);
            depthBotLine.renderOrder = this.lineRenderOrder;
            depthTopLine.renderOrder = this.lineRenderOrder;
            lineForceArrow.add(depthBotLine);
            lineForceArrow.add(depthTopLine);
            let pointContainer = [[botAxisPointOne, depthBotAxisPointOne], [botAxisPointTwo, depthBotAxisPointTwo], [topAxisPointOne, depthTopAxisPointOne], [topAxisPointTwo, depthTopAxisPointTwo]];

            if (areaMaterial) {
              let bottomPlaneGeo = this.getPlaneGeoFromPoints([botAxisPointOne, botAxisPointTwo, depthBotAxisPointTwo, depthBotAxisPointOne]);
              let bottomPlane = new this.THREE.Mesh(bottomPlaneGeo, areaMaterial);
              lineForceArrow.add(bottomPlane);
              bottomPlane.renderOrder = 102;

              bottomPlane.onBeforeRender = function (renderer) {
                renderer.clearDepth();
              };

              if (heightLeft && heightRight) {
                let topPlaneGeo = this.getPlaneGeoFromPoints([topAxisPointOne, topAxisPointTwo, depthTopAxisPointTwo, depthTopAxisPointOne]);
                let topPlane = new this.THREE.Mesh(topPlaneGeo, areaMaterial);
                lineForceArrow.add(topPlane);
                topPlane.renderOrder = 102;

                topPlane.onBeforeRender = function (renderer) {
                  renderer.clearDepth();
                };
              }
            }

            pointContainer.forEach(points => {
              let lineMesh = this.GraphicsThree3D.drawLine(points[0], points[1], lineProps);
              lineForceArrow.add(lineMesh);
            });
          }

          let horizontalDivisions = division.value1;
          let horizontalSegmentLength = horizontalLength / horizontalDivisions;
          let depthDivisions = division.value2;
          let depthSegmentLength = depthLength / depthDivisions;
          let pointer = this.makeArrow(pointerRadius, pointerHeight, volumeMaterial, borderMaterial, lineProps);
          pointer.rotation.z = Math.PI;

          for (let i = 1; i < depthDivisions; i++) {
            let bottomPointOne = new this.THREE.Vector3(0, 0, i * depthSegmentLength);
            let topPointOne = bottomPointOne.clone().add(new this.THREE.Vector3(0, heightLeft, 0));
            let bottomPointTwo = bottomPointOne.clone().add(new this.THREE.Vector3(horizontalLength * Math.cos(offsetAngle), horizontalLength * Math.sin(offsetAngle), 0));
            let topPointTwo = bottomPointTwo.clone().add(new this.THREE.Vector3(0, heightRight, 0));

            if (topPointOne.y.toFixed(2) != bottomPointOne.y.toFixed(2)) {
              let newAxis = this.GraphicsThree3D.drawLine(bottomPointOne, topPointOne, lineProps);
              newAxis.renderOrder = this.lineRenderOrder;
              let newPointer = pointer.clone();
              newPointer.position.add(bottomPointOne);
              lineForceArrow.add(newPointer);
              lineForceArrow.add(newAxis);
            }

            if (topPointTwo.y.toFixed(2) != bottomPointTwo.y.toFixed(2)) {
              let newAxis = this.GraphicsThree3D.drawLine(bottomPointTwo, topPointTwo, lineProps);
              newAxis.renderOrder = this.lineRenderOrder;
              let newPointer = pointer.clone();
              newPointer.position.add(bottomPointTwo);
              lineForceArrow.add(newPointer);
              lineForceArrow.add(newAxis);
            }
          }

          for (let i = 0; i < horizontalDivisions + 1; i++) {
            let bottomPoint = new this.THREE.Vector3(i * horizontalSegmentLength * Math.cos(offsetAngle), i * horizontalSegmentLength * Math.sin(offsetAngle), 0);
            let topPoint = bottomPoint.clone().add(new this.THREE.Vector3(0, heightLeft - i * (heightLeft - heightRight) / horizontalDivisions, 0));

            if (topPoint.y.toFixed(2) != bottomPoint.y.toFixed(2) && !(heightLeft && heightRight && (i == 0 || i == horizontalDivisions) && depthLength > 0 && depthDivisions == 0)) {
              let newAxis = this.GraphicsThree3D.drawLine(bottomPoint, topPoint, lineProps);
              newAxis.renderOrder = this.lineRenderOrder;
              let newPointer = pointer.clone();
              newPointer.position.add(bottomPoint);
              lineForceArrow.add(newPointer);
              lineForceArrow.add(newAxis);
            }

            if (depthLength > 0) {
              let depthBottomPoint = bottomPoint.clone().add(new this.THREE.Vector3(0, 0, depthLength));
              let depthTopPoint = topPoint.clone().add(new this.THREE.Vector3(0, 0, depthLength));

              if (depthTopPoint.y.toFixed(2) != depthBottomPoint.y.toFixed(2) && !(heightLeft && heightRight && (i == 0 || i == horizontalDivisions) && depthDivisions == 0)) {
                let newAxis = this.GraphicsThree3D.drawLine(depthBottomPoint, depthTopPoint, lineProps);
                newAxis.renderOrder = this.lineRenderOrder;
                let newPointer = pointer.clone();
                newPointer.position.add(depthBottomPoint);
                lineForceArrow.add(newPointer);
                lineForceArrow.add(newAxis);
              }
            }
          }
        }
      }

      if (materialProps.textProps) {
        this.addTextToArrow(materialProps.textProps, arrowData.components.texts, lineForceArrow, insertionProps.insertionPoint);
      }

      if (directionsDefined && areOrthogonal) {
        let insertionPoint = new this.THREE.Vector3(0, 0, 0);

        if (insertionProps.insertionPoint) {
          insertionPoint.set(insertionProps.insertionPoint.x, insertionProps.insertionPoint.y, insertionProps.insertionPoint.z);
        }

        if (directionsDefined && areOrthogonal) this.GraphicsThree3D.alignObjectWithVectors(lineForceArrow, insertionPoint, dirVectorNormal, dirVectorHorizontal);
      } else {
        let insertionPoint = new this.THREE.Vector3(0, 0, 0);

        if (insertionProps.insertionPoint) {
          insertionPoint.set(insertionProps.insertionPoint.x, insertionProps.insertionPoint.y, insertionProps.insertionPoint.z);
        }

        lineForceArrow.position.add(insertionPoint);
      }

      this.addToDimChainCollisionDetection(lineForceArrow);
      return lineForceArrow;
    }

    drawPointForceArrow(forceArrowData, headSize, height, materialProps, insertionProps, arrowData) {
      let pointForceArrow = new this.THREE.Object3D();

      if (forceArrowData.directionVector1 && (forceArrowData.directionVector1.x || forceArrowData.directionVector1.y || forceArrowData.directionVector1.z)) {
        let directionVector = this.utils.initVector3(forceArrowData.directionVector1);
        let pointerRadius = headSize.value2 / 2;
        let pointerHeight = headSize.value1;
        let volumeMaterial = this.getMaterialFromColor(materialProps.volumeProps.color);
        let borderMaterial = this.getMaterialFromColor(materialProps.lineProps.color);
        let pointer = this.makeArrow(pointerRadius, pointerHeight, volumeMaterial, borderMaterial, materialProps.lineProps);
        pointer.rotation.z = Math.PI;
        let axisHeight = height.value1;
        let axisPointOne = new this.THREE.Vector3(0, 0, 0);
        let axisPointTwo = new this.THREE.Vector3(0, axisHeight, 0);
        let axis = this.GraphicsThree3D.drawLine(axisPointOne, axisPointTwo, materialProps.lineProps);
        let arrow = new this.THREE.Object3D();
        let arrowParts = new this.THREE.Object3D();
        arrowParts.add(pointer);
        arrowParts.add(axis);
        arrow.add(arrowParts);
        arrowParts.rotation.x = -Math.PI / 2;
        arrow.lookAt(directionVector);
        pointForceArrow.add(arrow);
      }

      pointForceArrow.position.set(insertionProps.insertionPoint.x, insertionProps.insertionPoint.y, insertionProps.insertionPoint.z);

      if (materialProps.textProps) {
        this.addTextToArrow(materialProps.textProps, arrowData.components.texts, pointForceArrow, insertionProps.insertionPoint);
      }

      this.addToDimChainCollisionDetection(pointForceArrow);
      return pointForceArrow;
    }

    addTextToArrow(textProps, texts, object, objectInsertionPoint) {
      if (textProps.type && textProps.style && textProps.color.alpha) {
        let font = this.GraphicsThree3D.loadFont(textProps);

        if (texts) {
          texts.forEach(text => {
            if (text.insertionPoint && text.directionVector && text.normalVector && (text.directionVector.x || text.directionVector.y || text.directionVector.z) && (text.normalVector.x || text.normalVector.y || text.normalVector.z) && text.content) {
              let newText = this.GraphicsThree3D.drawText(text, textProps, font);

              if (objectInsertionPoint) {
                newText.position.sub(new this.THREE.Vector3(objectInsertionPoint.x, objectInsertionPoint.y, objectInsertionPoint.z));
              }

              object.add(newText);
            }
          });
        }
      }
    }

    getPlaneGeoFromPoints(points) {
      let bottomPlaneGeo = new this.THREE.Geometry();
      bottomPlaneGeo.vertices.push(...points);
      bottomPlaneGeo.faces.push(new this.THREE.Face3(0, 1, 2), new this.THREE.Face3(0, 2, 3));
      bottomPlaneGeo.faceVertexUvs[0].push([new this.THREE.Vector2(0, 0), new this.THREE.Vector2(1, 0), new this.THREE.Vector2(1, 1)], [new this.THREE.Vector2(0, 0), new this.THREE.Vector2(1, 1), new this.THREE.Vector2(0, 1)]);
      bottomPlaneGeo.uvsNeedUpdate = true;
      bottomPlaneGeo.computeFaceNormals();
      bottomPlaneGeo.computeVertexNormals();
      return bottomPlaneGeo;
    }

    getMaterialFromColor(color, isLine) {
      let materialOptions = {
        color: "rgb(" + color.red + ", " + color.green + ", " + color.blue + ")",
        polygonOffset: true,
        polygonOffsetFactor: 1,
        polygonOffsetUnits: 1,
        transparent: true,
        opacity: color.alpha / 255,
        side: this.THREE.DoubleSide
      };
      let material = isLine ? new this.THREE.LineBasicMaterial(materialOptions) : new this.THREE.MeshBasicMaterial(materialOptions);
      return material;
    }

    makeArrow(radius, height, areaMaterial, borderMaterial, lineProps, forArc = false) {
      let localAreaMaterial = areaMaterial.clone();
      localAreaMaterial.side = this.THREE.FrontSide;
      let coneMesh, coneGeo, edgePts;

      if (radius && height) {
        coneGeo = new this.THREE.ConeGeometry(radius, height, 32);
        coneGeo.translate(0, -height / 2, 0);
        coneMesh = new this.THREE.Mesh(coneGeo, localAreaMaterial);
        edgePts = coneGeo.vertices.slice(1, 33);
        let line = this.utils.drawConnectedLines(edgePts, lineProps, true);
        line.renderOrder = this.lineRenderOrder;
        coneMesh.add(line);
        let borderMesh;

        if (lineProps.thickness && lineProps.color.alpha) {
          let scale = 1 + lineProps.thickness / 4;
          borderMesh = new this.THREE.Mesh(coneGeo, borderMaterial);
          borderMesh.scale.multiplyScalar(scale);
          borderMesh.material.side = this.THREE.BackSide;
          borderMesh.position.y = (height - 1) * lineProps.thickness / 4;
          coneMesh.add(borderMesh);
          if (!forArc) coneMesh.position.y += 2 * lineProps.thickness;
        }

        let order = -99;
        coneMesh.renderOrder = order;
        if (borderMesh) borderMesh.renderOrder = order + 1;
      } else coneMesh = new this.THREE.Mesh();

      return coneMesh;
    }

  }

  _exports.ArrowLoader = ArrowLoader;
});