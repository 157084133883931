define("m08-2020/lib old/FigureLoaders/DrawingObjectComponents/Polyline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Polyline = void 0;

  class Polyline {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.THREE = GraphicsThree3D.THREE;
      this.utils = GraphicsThree3D.utils;
      this.CSG = GraphicsThree3D.CSG;
      this.WireframeGenerator = GraphicsThree3D.WireframeGenerator;
    }

    drawPolyline(lineData, materialProps) {
      let lineProps = materialProps.line;
      let lineType = lineData.objectGeneration.type;
      let lineMesh;

      if (lineType == 1) {
        lineMesh = this.drawPolylinePlanar(lineData, lineProps);
        lineMesh.generationType = "line";
      } else if (lineType == 2) {
        lineMesh = this.drawPolylineExtruded(lineData);
        lineMesh.generationType = "area";
      } else if (lineType == 3) {
        lineMesh = this.drawPolylineRotated(lineData);
        lineMesh.generationType = "area";
      }

      return lineMesh;
    }

    drawPolylinePlanar(lineData, lineProps) {
      let linePoints = lineData.points;
      let lineVerticies = [];
      linePoints.forEach(point => lineVerticies.push(this.utils.initVector3(point)));
      let lineMesh = this.utils.drawConnectedLines(lineVerticies, lineProps);
      return lineMesh;
    }

    drawPolylineExtruded(lineData) {
      let polylineDepth = 0.01;
      let depthExtrusion = new this.THREE.Vector3(0, 0, polylineDepth);
      let extrusionNormalized = depthExtrusion.clone().normalize();
      let linePoints = lineData.points;
      let extrusionVector = lineData.objectGeneration.extrusionVector;
      let basePoints = [];
      let extrudePoints = [];
      linePoints.forEach(point => {
        let originVector = new this.THREE.Vector3(point.x, point.y, point.z);
        let extrudedVector = new this.THREE.Vector3(point.x + extrusionVector.x, point.y + extrusionVector.y, point.z + extrusionVector.z);
        basePoints.push(originVector);
        extrudePoints.push(extrudedVector);
      });
      extrudePoints = extrudePoints.reverse();
      let points = basePoints.concat(extrudePoints); // this.WireframeGenerator.getPlanarPoints(points.map(point => point.clone()), lineData.objectType);

      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(points, extrusionNormalized);
      points.forEach(point => {
        // this.GraphicsThree3D.wireframePoints.push(point.clone());
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      let shapePts = points.map(point => new this.THREE.Vector2(point.x, point.y));
      let shape = new this.THREE.Shape(shapePts);
      depthExtrusion.applyEuler(eulerOne);
      extrusionNormalized.applyEuler(eulerOne);
      let extrudeSettings = {
        depth: depthExtrusion.z,
        bevelEnabled: false
      };
      let geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
      let areaMesh = new this.THREE.Mesh(geometry);
      areaMesh.position.add(midVector);
      areaMesh.updateMatrix();
      areaMesh.setRotationFromEuler(eulerTwo);
      return areaMesh;
    }

    drawPolylineRotated(lineData) {
      let linePoints = lineData.points;
      let rotationPoint = lineData.objectGeneration.rotationPoint;
      let rotationAxis = this.utils.initVector3(lineData.objectGeneration.rotationAxis);
      let rotationAngle = lineData.objectGeneration.rotationAngle * Math.PI / 180;
      let botPoints = [];
      let topPoints = [];
      let startPoints = [];
      let endPoints = [];
      let dummyVector = new this.THREE.Vector3();
      linePoints.forEach((point, index) => {
        botPoints.push(this.utils.initVector3(point));

        if (index > 0 && index < linePoints.length - 1) {
          let topObject = new this.THREE.Object3D();
          topObject.position.set(point.x, point.y, point.z);
          let pivotPoint = new this.THREE.Object3D();
          pivotPoint.position.set(rotationPoint.x, rotationPoint.y, rotationPoint.z);
          pivotPoint.attach(topObject);
          pivotPoint.rotateOnAxis(rotationAxis, rotationAngle);
          pivotPoint.updateMatrixWorld();
          let newTopPoint = topObject.getWorldPosition(dummyVector).clone();
          topPoints.push(newTopPoint);
        }
      });
      let startObject = new this.THREE.Object3D();
      startObject.position.set(linePoints[0].x, linePoints[0].y, linePoints[0].z);
      let endObject = new this.THREE.Object3D();
      endObject.position.set(linePoints[linePoints.length - 1].x, linePoints[linePoints.length - 1].y, linePoints[linePoints.length - 1].z);
      let pivotPoint = new this.THREE.Object3D();
      pivotPoint.position.set(rotationPoint.x, rotationPoint.y, rotationPoint.z);
      pivotPoint.attach(startObject);
      pivotPoint.attach(endObject);
      let numOfSegments = Math.abs(64 * rotationAngle / (Math.PI * 2));

      for (let i = 0; i < numOfSegments; i++) {
        pivotPoint.rotateOnAxis(rotationAxis, rotationAngle / numOfSegments);
        pivotPoint.updateMatrixWorld();
        let newStartPoint = startObject.getWorldPosition(dummyVector).clone();
        startPoints.push(newStartPoint);
        let newEndPoint = endObject.getWorldPosition(dummyVector).clone();
        endPoints.push(newEndPoint);
      }

      topPoints.reverse();
      startPoints.reverse();
      let polylineDepth = 0.01;
      let extrusionVector = new this.THREE.Vector3(0, 0, polylineDepth);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let pts = botPoints.concat(endPoints).concat(topPoints).concat(startPoints); // this.WireframeGenerator.getPlanarPoints(pts.map(point => point.clone()), lineData.objectType);

      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(pts, extrusionNormalized);
      pts.forEach(point => {
        // this.GraphicsThree3D.wireframePoints.push(point.clone());
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      let shapePts = pts.map(point => new this.THREE.Vector2(point.x, point.y));
      let shape = new this.THREE.Shape(shapePts);
      extrusionVector.applyEuler(eulerOne);
      extrusionNormalized.applyEuler(eulerOne);
      let extrudeSettings = {
        depth: extrusionVector.z,
        bevelEnabled: false
      };
      let geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings);
      let areaMesh = new this.THREE.Mesh(geometry);
      areaMesh.position.add(midVector);
      areaMesh.updateMatrix();
      areaMesh.setRotationFromEuler(eulerTwo);
      return areaMesh;
    }

  }

  _exports.Polyline = Polyline;
});