define("m08-2020/lib/FigureLoaders/DrawingObjectComponents/Polygon", ["exports", "m08-2020/lib/ThreeExtensions/RotatedGeometry", "m08-2020/lib/ThreeExtensions/PlanarRotationPointsGenerator", "m08-2020/lib/ThreeExtensions/PlanarExtrusionPointsGenerator"], function (_exports, _RotatedGeometry, _PlanarRotationPointsGenerator, _PlanarExtrusionPointsGenerator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Polygon = void 0;

  class Polygon {
    constructor(GraphicsThree3D) {
      this.GraphicsThree3D = GraphicsThree3D;
      this.THREE = GraphicsThree3D.THREE;
      this.utils = GraphicsThree3D.utils;
      this.CSG = GraphicsThree3D.CSG;
      this.WireframeGenerator = GraphicsThree3D.WireframeGenerator;
    }

    drawPolygon(polygonData) {
      let polygon;
      let isPlanar = this.polygonIsPlanar(polygonData);

      if (polygonData.objectGeneration.type === 1) {
        polygon = this.drawPolygonPlanar(polygonData);
        polygon.generationType = "area";
      } else if (polygonData.objectGeneration.type === 2) {
        if (!isPlanar) {
          polygon = this.drawPolygonExtruded(polygonData);
          polygon.generationType = "volume";
        } else {
          polygon = this.drawPolygonExtrudedPlanar(polygonData);
          polygon.generationType = "area";
        }
      } else {
        if (isPlanar) {
          polygon = this.drawPolygonRotatedPlanar(polygonData);
          polygon.generationType = "area";
        } else {
          polygon = this.drawPolygonRotated(polygonData);
          polygon.generationType = "volume";
        }
      }

      return polygon;
    }

    drawPolygonPlanar(polygonData) {
      let polygonDepth = 0.01;
      let extrusionVector = new this.THREE.Vector3(0, 0, polygonDepth);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let pts = this.getPolygonPlanarPoints(polygonData);
      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(pts, extrusionNormalized);
      pts.forEach(point => {
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      let shapePts = pts.map(point => new this.THREE.Vector2(point.x, point.y));
      let shape = new this.THREE.Shape(shapePts);
      extrusionVector.applyEuler(eulerOne);
      extrusionNormalized.applyEuler(eulerOne); // extrusionVector.z *= -1;

      let extrudeSettings = {
        depth: extrusionVector.z,
        bevelEnabled: false
      };
      let geometry = new this.THREE.ExtrudeGeometry(shape, extrudeSettings); // geometry.translate(0, 0, -extrusionVector.z);

      let polygon = new this.THREE.Mesh(geometry);
      polygon.position.add(midVector);
      polygon.updateMatrix();
      polygon.setRotationFromEuler(eulerTwo);
      return polygon;
    }

    drawPolygonExtruded(polygonData) {
      let extrusionVector = this.utils.initVector3(polygonData.objectGeneration.extrusionVector);
      let extrusionNormalized = extrusionVector.clone().normalize();
      let pts = this.getPolygonPlanarPoints(polygonData);
      let [midVector, eulerOne, eulerTwo, crossVector] = this.GraphicsThree3D.getRotationParameters(pts, extrusionNormalized);
      pts.forEach(point => {
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      extrusionVector.applyEuler(eulerOne); // TODO: I am not sure this logic is correct

      crossVector.applyEuler(eulerOne);
      let skewParams = this.utils.getSkewParams(extrusionVector);
      let polygonDepth = extrusionVector.z;
      let shapePts = pts.map(point => new this.THREE.Vector2(point.x, point.y));
      let geometry = this.GraphicsThree3D.makeExtrudeGeoRaw(shapePts, polygonDepth); // TODO: I am not sure this logic is correct

      if (crossVector.dot(new this.THREE.Vector3(0, 0, -1)) > 0) {
        geometry.translate(0, 0, -polygonDepth);
      }

      this.utils.skewExtrudedGeometry(geometry, skewParams);
      let polygon = new this.THREE.Mesh(geometry);
      polygon.position.add(midVector);
      polygon.setRotationFromEuler(eulerTwo);
      polygon.updateMatrix();
      return polygon;
    }

    drawPolygonExtrudedPlanar(polygonData) {
      let zAxis = new this.THREE.Vector3(0, 0, 1);
      let polygonPoints = this.getPolygonPlanarPoints(polygonData);
      let [midVector, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(polygonPoints, zAxis);
      polygonPoints.forEach(point => {
        point.sub(midVector);
        point.applyEuler(eulerOne);
      });
      let extrusionVector = this.utils.initVector3(polygonData.objectGeneration.extrusionVector).applyEuler(eulerOne);
      let shapePts = new _PlanarExtrusionPointsGenerator.PlanarExtrusionPointsGenerator(polygonPoints, extrusionVector).extrudedPoints;
      let shape = new this.THREE.Shape(shapePts);
      let geometry = new this.THREE.ExtrudeGeometry(shape, {
        depth: 0.01,
        bevelEnabled: false
      });
      geometry.translate(midVector.x, midVector.y, midVector.z);
      let polygon = new this.THREE.Mesh(geometry);
      polygon.setRotationFromEuler(eulerTwo);
      polygon.updateMatrix();
      return polygon;
    }

    drawPolygonRotated(polygonData) {
      let pts = this.getPolygonPlanarPoints(polygonData);
      let rotatesPlanar = this.GraphicsThree3D.objectRotatesPlanar(polygonData);
      let rotatedGeometry = new _RotatedGeometry.RotatedGeometry(pts.map(pt => pt.clone()), {
        rotationPoint: this.utils.initVector3(polygonData.objectGeneration.rotationPoint),
        rotationAxis: this.utils.initVector3(polygonData.objectGeneration.rotationAxis).normalize(),
        rotationAngle: polygonData.objectGeneration.rotationAngle * Math.PI / 180,
        rotationPitch: polygonData.objectGeneration.rotationPitch
      }, rotatesPlanar);
      let polygon = new this.THREE.Mesh(rotatedGeometry); ///

      let linePairs = [];
      this.WireframeGenerator.getRegularWireframePointPairs(polygon, Math.PI / 6).forEach(pair => linePairs.push([pair[0].clone().applyMatrix4(polygon.matrix), pair[1].clone().applyMatrix4(polygon.matrix)]));
      this.WireframeGenerator.getVolumeWireframeData(polygon, linePairs, linePairs, polygonData.objectType); ///

      return polygon;
    }

    drawPolygonRotatedPlanar(polygonData) {
      let rotationPoint = this.utils.initVector3(polygonData.objectGeneration.rotationPoint);
      let rotationAxis = this.utils.initVector3(polygonData.objectGeneration.rotationAxis).normalize();
      let rotationAngle = polygonData.objectGeneration.rotationAngle * Math.PI / 180;
      let rotationPitch = polygonData.objectGeneration.rotationPitch;
      let polygonPoints = this.getPolygonPlanarPoints(polygonData);
      let zAxis = new this.THREE.Vector3(0, 0, 1);
      let [_, eulerOne, eulerTwo] = this.GraphicsThree3D.getRotationParameters(polygonPoints, zAxis);
      polygonPoints.forEach(point => {
        point.sub(rotationPoint);
        point.applyEuler(eulerOne);
      });
      let zValue = polygonPoints[0].z;
      if (rotationAxis.applyEuler(eulerOne).dot(zAxis) < 0) rotationAngle *= -1;
      let rotationPtsGen = new _PlanarRotationPointsGenerator.PlanarRotationPointsGenerator(polygonPoints, {
        rotationAngle,
        rotationPitch
      });
      polygonPoints = rotationPtsGen.rotatedPoints;
      let holePtsArr = rotationPtsGen.holePoints;
      let shapePts = polygonPoints.map(point => new this.THREE.Vector3(point.x, point.y, 0));
      let shape = new this.THREE.Shape(shapePts);
      holePtsArr.forEach(holePts => {
        if (holePts.length > 2) shape.holes.push(new this.THREE.Path(holePts));
      });
      let geometry = new this.THREE.ExtrudeGeometry(shape, {
        depth: -0.01,
        bevelEnabled: false
      });
      geometry.translate(rotationPoint.x, rotationPoint.y, rotationPoint.z + zValue);
      let polygon = new this.THREE.Mesh(geometry);
      polygon.setRotationFromEuler(eulerTwo);
      polygon.updateMatrix();
      return polygon;
    }

    getPolygonPlanarPoints(polygonData) {
      let points = [];

      for (let c = 0; c < polygonData.points.length; c++) {
        let x = polygonData.points[c].x;
        let y = polygonData.points[c].y;
        let z = polygonData.points[c].z;
        points.push(new this.THREE.Vector3(x, y, z));
      }

      return points;
    }

    polygonIsPlanar(polygonData) {
      return polygonData.objectGeneration.type === 1 || polygonData.objectGeneration.type === 2 && this.utils.vectorIsZero(polygonData.objectGeneration.extrusionVector) || polygonData.objectGeneration.type === 2 && this.GraphicsThree3D.objectExtrudesPlanar(polygonData) || polygonData.objectGeneration.type === 3 && this.GraphicsThree3D.objectRotatesPlanar(polygonData) && !polygonData.objectGeneration.rotationPitch;
    }

  }

  _exports.Polygon = Polygon;
});